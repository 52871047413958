import React from "react";
import ExperienceHome from "./ExperienceHome";
import ExperienceFooter from "../ExperienceFooter";
import PhysicalExperience from "./PhysicalExperience";
import InteractiveSuite from "./InteractiveSuite";
import GetInTouchButton from "../GetInTouchButton";
import { useSelectedTitleContext } from "../SelectedTitleContext";
import LogoToggle from "../LogoToggle";
import NavbarComponent from "../NavbarComponent";

const Experience = () => {
  const { isNavOpen, toggleNav, selectedId, handleTitleClickk } =
    useSelectedTitleContext();
  return (
    <div>
      <LogoToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />
      {isNavOpen && <NavbarComponent />}
      {!isNavOpen && (
        <>
          <InteractiveSuite />
          <PhysicalExperience />
          <GetInTouchButton />
          <ExperienceFooter />
        </>
      )}
    </div>
  );
};

export default Experience;
