import React from "react";
import { useState } from "react";
import { useEffect } from "react";

const Logo = ({ className }) => {
  const [logoUrl, setLogoUrl] = useState("");
  useEffect(() => {
    const url = window.location.hostname;
    switch (url) {
      case "www.bluetickvr.com":
      case "bluetickvr.com":
        setLogoUrl("bluetickvr-logo");
        break;
      case "www.convrse.in":
      case "convrse.in":
        setLogoUrl("spaceslogo");
        break;
      case "localhost":
        setLogoUrl("bluetickvr-logo");
        break;
      // Add more cases for other URLs if needed

      default:
        setLogoUrl("spaceslogo");
    }
  }, []);
  return (
    <img
      src={`${process.env.PUBLIC_URL}/${logoUrl}.png`}
      alt="logo"
      className={className}
    />
  );
};

export default Logo;
