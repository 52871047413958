import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import "./Offers.css";
import { ScrollDirection, useScrollDirection } from "./ScrollDirection";
import Logo from "./Logo";

const Styl = styled.div`
  .shadow {
    opacity: 0.2;
  }

  @keyframes fadeIn {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes sequentialFadeIn {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Legacy = () => {
  const [firstRowVisible, setFirstRowVisible] = useState(false);
  const [secondRowVisible, setSecondRowVisible] = useState(false);
  const scrollDirection = useScrollDirection();
  const firstRowRef = useRef(null);
  const secondRowRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0, // Fully visible in viewport
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === firstRowRef.current) {
          if (entry.isIntersecting && entry.intersectionRatio >= 1) {
            setFirstRowVisible(true);
          } else {
            setFirstRowVisible(false);
          }
        } else if (entry.target === secondRowRef.current) {
          if (entry.isIntersecting && entry.intersectionRatio >= 1) {
            setSecondRowVisible(true);
          } else {
            setSecondRowVisible(false);
          }
        }
      });
    }, options);

    if (firstRowRef.current) {
      observer.observe(firstRowRef.current);
    }
    if (secondRowRef.current) {
      observer.observe(secondRowRef.current);
    }

    return () => {
      if (firstRowRef.current) {
        observer.unobserve(firstRowRef.current);
      }
      if (secondRowRef.current) {
        observer.unobserve(secondRowRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (firstRowVisible && !secondRowVisible) {
      const firstRowShadows = firstRowRef.current.querySelectorAll(".shadow");
      firstRowShadows.forEach((shadow, index) => {
        shadow.style.animation = "fadeIn 1s ease-in-out forwards";
      });
    }
    if (secondRowVisible) {
      const secondRowShadows = secondRowRef.current.querySelectorAll(".shadow");
      secondRowShadows.forEach((shadow, index) => {
        shadow.style.animation = "fadeIn 1s ease-in-out forwards";
      });
    }
  }, [firstRowVisible, secondRowVisible]);

  useEffect(() => {
    if (scrollDirection === ScrollDirection.UP && !secondRowVisible) {
      setFirstRowVisible(true);
    }
  }, [scrollDirection, secondRowVisible]);

  return (
    <Style>
      <Styl>
        <div className="container">
          <div className="heading">
            <h1>Our Legacy</h1>
          </div>
          <div ref={firstRowRef} className="row">
            <div className="shadow">
              <h2>300M+</h2>
              <p>Virtual spaces developed</p>
            </div>
            <div className="shadow">
              <h2>450+</h2>
              <p>Projects Completed</p>
            </div>
            <div
              className="shadow"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <img src="/spaceslogo.png" alt="logo" /> */}
              <Logo />
            </div>
          </div>
          <div ref={secondRowRef} className="row">
            <div className="shadow">
              <h2>40+</h2>
              <p>Years of experience in spatial design</p>
            </div>
            <div className="shadow">
              <h2>10M+</h2>
              <p>Happy End Customer</p>
            </div>
          </div>
        </div>
      </Styl>
    </Style>
  );
};

const Style = styled.div`
  width: 98.5vw;
  display: flex;
  height: auto;
  scroll-behavior: smooth;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  justify-content: center;
  margin-top: 6%;
  .container {
    width: 95%;
    margin-left: 0.8%;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 20px;
    padding: 20px;

    h1 {
      text-align: start;
      font-weight: 400;
      font-size: 3.1rem;
      letter-spacing: 2%;
      font-family: "Bebas Neue", sans-serif;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .row {
    display: flex;
    width: 91.5vw;
    margin-top: 0.4rem;
    justify-content: space-between;
    gap: 20px;
    text-align: center;
    .shadow:nth-of-type(2),
    .shadow:nth-of-type(3) {
      width: 56%;
    }
  }

  .row:last-child {
    justify-content: space-around;
    .shadow:nth-of-type(2) {
      width: 85%;
    }
  }

  .shadow {
    border-radius: 0.7rem;
    text-align: start;
    opacity: 0.2;
    width: 70%;
    padding-left: 1rem;
    box-shadow: 10px 0 15px rgba(106, 89, 255, 0.12),
      /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.12),
      /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0);
    h2 {
      margin-left: 1rem;
      font-size: 3.9rem;
      font-weight: 400;
      margin-bottom: 0;
      margin-top: 0.7rem;
      font-family: "Bebas Neue", sans-serif;
    }

    p {
      margin-left: 1rem;
      margin-top: -0.7rem;
      padding-bottom: 0.5rem;
      font-weight: 600;
      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
    }
  }

  h2 {
    /* background: linear-gradient(to right, rgba(106, 89, 255, 1), rgba(64, 53, 153, 1));*/
    color: rgba(106, 89, 255, 1);

    /* -webkit-background-clip: text;*/
    /* background-clip: text;*/
    /* color: transparent; /* Text ka color transparent kiya gaya hai */
  }
  P {
    /*  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(61, 53, 255, 1));*/

    font-size: 1.3rem;
    /* -webkit-background-clip: text;*/
    /*  background-clip: text;*/
    /* color: transparent; /* Text ka color transparent kiya gaya hai */

    color: rgba(0, 0, 0, 1);
  }
  img {
    width: 54%;
    margin-left: -2rem;
    align-item: center;
    justify-content: center;
    text-align: center;
  }

  @media only screen and (max-width: 767px) {
    width: 140vw;
    height: auto;
    margin-top: -3rem;
    overflow: hidden;
    .container {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 20px;
      .heading {
        width: 95%;
        display: flex;
        align-items: start;
        justify-content: start;
        text-align: start;
        h1 {
          text-align: start;
          text-transform: uppercase;
        }
      }
    }

    .row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
    }

    .row:last-child {
      justify-content: space-around;
    }

    .shadow {
      border-radius: 1rem;
      text-align: start;
      width: 50%;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      h2 {
        margin-left: 1rem;
        font-size: 3.7rem;
      }
      p {
        margin-left: 1rem;
        font-size: 0.7rem;
      }
    }

    h2 {
      color: rgba(106, 89, 255, 1);
    }
    img {
      align-item: center;
      justify-content: center;
      text-align: center;
    }
  }
`;

export default Legacy;
