import React, { useState } from "react";
import styled from "styled-components";
import { TeemData } from "../data";
import { Link as ScrollLink } from "react-scroll";

const MeetTeam = () => {
  const [selectedTitleId, setSelectedTitleId] = useState(1);

  const handleTitleClick = (id) => {
    setSelectedTitleId(id);
  };

  return (
    <Style>
      <header className="header">
        <div className="heading">
          <h1>meet the team</h1>
          <p>The Passionate Creators </p>
        </div>
      </header>
      <main className="main">
        <div className="row">
          {TeemData.map((solution) => {
            return (
              <section key={solution.id} className="section section-phygital">
                <div className="img-container">
                  <img src={`/teem/${solution.src}`} alt={solution.title} />
                  <div className="overlay">
                    <h2 className="title">{solution.title}</h2>
                    <p className="subhead">{solution.post}</p>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      </main>
    </Style>
  );
};

const Style = styled.div`
  margin-top: 5%;
  padding: 0.6rem;
  .header {
    display: flex;
    flex-direction: row;
    .img {
      width: 30%;
      justify-content: center;
      align-item: center;
      img {
        align-item: start;
        justify-content: start;
        margin-top: 2rem;
        margin-right: 5rem;
        width: 40%;
      }
    }
    .heading {
      width: 100%;
      h1 {
        text-transform: uppercase;
        font-size: 2.7rem;
        font-weight: 400;
        margin-bottom: 0;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        text-align: center;
        margin-top: 0;
        font-weight: 400;
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  .header h1 {
    color: black;
    text-align: center;
  }

  .main {
    display: flex;
    margin-top: -2.5%;
    align-item: center;
    padding: 1rem;
    justify-content: center;
    cursor: pointer;
    .row {
      width: 89%;
      display: flex;
      flex-wrap: wrap;

      justify-content: center;
      align-items: center;
      margin: 0 15px;
      margin-top: 1rem;
    }

    .section {
      width: calc(28.333% - 30px); /* Adjust as needed */
      margin: 0 5px; /* Adjust as needed */
    }
  }

  .section {
    margin: 1rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    position: relative;
  }

  .section h2 {
    margin-top: 0;
    font-size: 1.7rem;
    font-weight: 400;
    font-family: "Bebas Neue", sans-serif;
  }

  .section p {
    margin-bottom: 0;
    text-align: left;
    font-size: 1.1rem;
    font-family: "Poppins", sans-serif;
  }

  .img-container {
    padding: 0.3rem;
    position: relative;
    display: flex;
    -webkit-filter: drop-shadow(10px 0 15px rgba(106, 89, 255, 0.1))
      drop-shadow(0 10px 15px rgba(106, 89, 255, 0.2));
    filter: drop-shadow(10px 0 15px rgba(106, 89, 255, 0.1))
      drop-shadow(0 10px 15px rgba(106, 89, 255, 0.2));
    transition: transform 0.3s ease;
    justify-content: center;
    border-bottom-left-radius: 10px;
    .title {
      font-size: 2.1rem;
      width: 110%;
      margin-top: -1.9rem;
      text-transform: uppercase;
      letter-spacing: 0.02em;
      margin-bottom: 0;
      color: white;
    }
    .subhead {
      margin-top: 0;
      color: white;
    }
    .svg-element {
      transition: transform 0.5s;
    }

    .svg-element:hover {
      transform: rotate(360deg);
    }
  }
  .img-container:hover {
    transform: translateY(-10px); /* Move the img-container upwards on hover */
  }
  .img-container img {
    width: 36rem;
    height: 50vh;
    padding: 0.5rem;
  }

  .overlay {
    position: absolute;
    bottom: -10%;
    left: 35%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0);
    color: black;
    padding: 1rem;
    border-radius: 8px;
    text-align: start;
    max-width: 80%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .header {
      padding: 1rem;
    }

    .header h1 {
      color: black;
      text-align: center;
    }

    .main {
      display: flex;
      padding: 1rem;
      justify-content: center;

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -15px;
      }

      .section {
        width: calc(45.333% - 30px); /* Adjust as needed */
        margin: 0 15px; /* Adjust as needed */
        /* Other styles */
      }
    }

    .section {
      margin: 1rem;
      padding: 1rem;
      border-radius: 4px;
      background-color: white;
      text-align: center;
      position: relative;
    }

    .section h2 {
      margin-top: 0;
    }

    .section p {
      margin-bottom: 0;
    }

    .img-container {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .img-container img {
      max-width: 100%;
      height: auto;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: white;
      padding: 1rem;
      border-radius: 8px;
      text-align: center;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-top: 2%;
    width: 130vw;
    .header {
      padding: 0rem;
      display: flex;
      flex-direction: column;
      .img {
        width: 30%;
        justify-content: center;
        align-item: start;
        img {
          align-item: start;
          justify-content: start;
          margin-top: 0.5rem;
          margin-left: 2rem;
          width: 40%;
        }
      }
      .heading {
        display: flex;
        align-item: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        h1 {
          text-transform: uppercase;
          font-size: 3rem;
          font-weight: 400;
        }
        p {
          display: flex;
          align-item: center;
          justify-content: center;
          font-size: 0.9rem;
        }
      }
    }

    .header h1 {
      color: black;
      font-size: 1.5rem;
      text-align: center;
    }

    .main {
      display: flex;
      padding: 0rem;
      margin-right: -1rem;
      justify-content: center;

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -15px;
      }

      .section {
        width: calc(98.333% - 30px); /* Adjust as needed */
        margin: 0 15px; /* Adjust as needed */
        /* Other styles */
      }
    }

    .section {
      margin: 1rem;
      padding: 1rem;
      border-radius: 4px;
      background-color: white;
      text-align: center;
      position: relative;
    }

    .section h2 {
      margin-top: 0;
    }

    .section p {
      margin-bottom: 0;
    }

    .img-container {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .img-container img {
      max-width: 100%;
      height: auto;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: black;
      padding: 1rem;
      border-radius: 8px;
      text-align: center;
      max-width: 80%;
      h2 {
        font-size: 0.5rem;
      }
    }
  }
`;

export default MeetTeam;
