import React, { createContext, useContext, useState } from "react";

const SelectedTitleContext = createContext();

export const useSelectedTitleContext = () => {
  return useContext(SelectedTitleContext);
};

export const SelectedTitleProvider = ({ children }) => {
  const [selectedTitleId, setSelectedTitleId] = useState(1);
  const [selectedId, setSelectedId] = useState(1);
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState(null);
  const [hostName, setHostName] = useState("");

  const handleNavItemChange = (item) => {
    setActiveNavItem(item);
  };

  const handleTitleClick = (id) => {
    setSelectedTitleId(id);
  };
  const handleTitleClickk = (id) => {
    setSelectedId(id);
  };
  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <SelectedTitleContext.Provider
      value={{
        isNavOpen,
        setIsNavOpen,
        selectedTitleId,
        handleTitleClick,
        setSelectedTitleId,
        selectedId,
        setSelectedId,
        toggleNav,
        handleTitleClickk,
        hostName,
        setHostName,
      }}
    >
      {children}
    </SelectedTitleContext.Provider>
  );
};

export default SelectedTitleContext;
