import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import "./Offers.css";
import { ScrollDirection, useScrollDirection } from "./ScrollDirection";
const Styl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image-content {
    opacity: 0.2;
  }

  @keyframes fadeIn {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes sequentialFadeIn {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;

const Value = () => {
  const [firstRowVisible, setFirstRowVisible] = useState(false);
  const [secondRowVisible, setSecondRowVisible] = useState(false);
  const scrollDirection = useScrollDirection();
  const firstRowRef = useRef(null);
  const secondRowRef = useRef(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Fully visible in viewport

      
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === firstRowRef.current) {
          if (entry.isIntersecting) {
            setFirstRowVisible(true);
          }
        } else if (entry.target === secondRowRef.current) {
          if (entry.isIntersecting) {
            setSecondRowVisible(true);
            setFirstRowVisible(true);
          }
        }
      });
    }, options);

    if (firstRowRef.current) {
      observer.observe(firstRowRef.current);
    }
    if (secondRowRef.current) {
      observer.observe(secondRowRef.current);
    }

    return () => {
      if (firstRowRef.current) {
        observer.unobserve(firstRowRef.current);
      }
      if (secondRowRef.current) {
        observer.unobserve(secondRowRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (firstRowVisible && !secondRowVisible) {
      const firstRowShadows =
        firstRowRef.current.querySelectorAll(".image-content");
      firstRowShadows.forEach((shadow, index) => {
        shadow.style.animation = "fadeIn 1s ease-in-out forwards";
      });
    }
    if (secondRowVisible) {
      const secondRowShadows =
        secondRowRef.current.querySelectorAll(".image-content");
      secondRowShadows.forEach((shadow, index) => {
        shadow.style.animation = "fadeIn 1s ease-in-out forwards";
      });
    }
  }, [firstRowVisible, secondRowVisible]);
  useEffect(() => {
    if (scrollDirection === ScrollDirection.UP && !secondRowVisible) {
      setFirstRowVisible(true);
    }
  }, [scrollDirection, secondRowVisible]);

  return (
    <Style>
      <div className="container">
        <Styl>
          <div className="inner-containerr">
            <div className="img1" ref={firstRowRef}>
              <div className="image-content">
                <div className="fontimg">
                <img src="/unspla1.webp" alt="image" />
                </div>
                <div className="overlay">
                  <h2
                    id="dif"
                    style={{ textTransform: "uppercase", fontWeight: "400" }}
                  >
                   our
Projects
                  </h2>
                </div>
              </div>
              <div className="image-content">
              <div className="fontimg">
                <img src="/unspla.webp" alt="image" />
                </div>
                <div className="overlay" >
                  <div className="headder" id="imagecontentfirst">
                    <h2 id="diff">Adani Ambrosia - Virtual Tour:</h2>
                    <p>
                    Step into luxury with Adani Ambrosia's Virtual Tour, where you're invited to explore its opulent lifestyle and state-of-the-art amenities, providing a glimpse into a world of unparalleled elegance.
                    </p>
                  </div>
                </div>
                <div className="learnmoree">
                  <div>
                    <svg
                      width="59"
                      height="24"
                      viewBox="0 0 89 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                    <a href="https://d1y894ed9p1p3a.cloudfront.net/" target="_blank"> <p>learn more</p> </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="img2" ref={secondRowRef}>
              <div className="imgfirstdiv">
              <div className="image-content">
              <div className="fontimg">
                <img src="/value2.webp" alt="image" />
                </div>
                <div className="overlay">
                  <div id="imagecontentsec" className="headder">
                    <h2>Smartworld One DXP - Flat Selector Tool + VR Tour:</h2>
                    <p>
                    Seamlessly select your ideal flat at Smartworld One DXP with our intuitive Flat Selector Tool, then immerse yourself in a captivating VR Tour to experience every detail firsthand, revolutionizing your property exploration.
                    </p>
                  </div>
                </div>
                <div className="learnm">
                  <div>
                    <svg
                      width="59"
                      height="24"
                      viewBox="0 0 89 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                  <a href="https://new-tower-renders.d22h0q7uf4giy9.amplifyapp.com/smart-world" target="_blank"> <p>learn more</p> </a> 
                  </div>
                </div>
              </div>
              <div className="image-content">
              <div className="fontimg">
                <img src="/value1.webp" alt="image" />
                </div>
                <div className="overlay">
                  <div id="imagecontentthird" className="headder">
                    <h2>Arvind Smartspaces Plot Selector Tool - Plot Selector Tool: </h2>
                    <p>
                    Unlock the potential of Arvind Smartspaces with our intuitive Plot Selector Tool, facilitating your search for the ideal plot for your residential, commercial, or mixed-use aspirations, streamlining your property selection process.
                    </p>
                  </div>
                </div>
                <div className="learnm">
                  <div>
                    <svg
                      width="59"
                      height="24"
                      viewBox="0 0 89 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                 <a href="https://master.d1yzrmwpkwpalz.amplifyapp.com/" target="_blank">   <p>learn more</p> </a> 
                  </div>
                </div>
              </div>
            
              </div>
              <div className="imgsecdiv">
              <div className="image-content">
              <div className="fontimg">
                <img src="/value3.webp" alt="image" />
                </div>
                <div className="overlay">
                  <div id="imagecontentfour" className="headder">
                    <h2>M3M City of Dreams - Plot Selector Tool:</h2>
                    <p>
                    Find your perfect plot effortlessly with our tailored Plot Selector Tool for M3M City of Dreams, empowering you to discover the ideal canvas for your dream home or investment project, simplifying your search process.
                    </p>
                  </div>
                </div>
                <div className="learnmore">
                  <div>
                    <svg
                      width="59"
                      height="24"
                      viewBox="0 0 89 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                  <a href="https://d2u9hjviq82t82.cloudfront.net/" target="_blank">
                    <p>learn more</p>   </a>
                  </div>
                </div>
              </div>
              <div className="image-content">
              <div className="fontimg">
                <img src="/value4.webp" alt="image" />
                </div>
                <div className="overlay">
                  <div id="imagecontentfive" className="headder">
                    <h2>Adani Realty - Virtual Tour:</h2>
                    <p>
                    Embark on a virtual journey through Adani Realty's prestigious developments, where every corner is meticulously showcased in our immersive Virtual Tour, offering a glimpse of the luxurious lifestyle that awaits.
                    </p>
                  </div>
                </div>
                <div className="learnmore">
                  <div>
                    <svg
                      width="59"
                      height="24"
                      viewBox="0 0 89 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div>
                 <a href="https://main.d20ul68ec3kojo.amplifyapp.com/" target="_blank">  <p>learn more</p> </a> 
                  </div>
                </div>
              </div>
              </div>
             
            </div>
          </div>
        </Styl>
      </div>
    </Style>
  );
};
const Style = styled.div`
  scroll-behavior: smooth;
  width: 98.5;
  height: auto;
  overflow: hidden;
  margin-top: 6%;
  padding-bottom: 1.2rem;
padding-top: 1rem;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .image-container {
    position: relative;
    width: 100%;

    margin-bottom: 40px;
  }

  .image-container img {
    width: 100%;
    height: auto;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15%;
    left: 10%;

    background-color: rgba(255, 255, 255, 0.1);

    text-align: start;
    border-radius: 5px;
    #dif {
      margin-top: -3.9rem;
      font-size: 2rem;
      font-family: "Bebas Neue", sans-serif;
    }
  
    #imagecontentsec{
      margin-top: 5rem;
      h2{
        width: 160%;
        font-size: 1.5rem;
      }
      p{
        width: 150%;
        font-size: 0.8rem;
      }
    }
    #imagecontentfive{
      margin-top: 2.6rem;
      width: 100%;
      h2{
        width: 150%;
      }
    }
    #imagecontentfour{
      margin-top: 6rem;
      width: 100%;
      h2{
        width: 150%;
      }
    }
   #imagecontentthird{
    margin-top: 7.5rem;
    h2{
      width: 160%;
      font-size: 1.5rem;
    }
    p{
      width: 150%;
      font-size: 0.8rem;
    }
   }
   #imagecontentfirst {
    margin-top: 8rem;
  }
    .headder {
      h2 {
        text-transform: uppercase;
        font-weight: 400;
        margin-bottom: 1rem;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        margin-top: 1rem;
        text-transform: capitalize;
        font-family: "Poppins", sans-serif;
      }
    }
  }
  .learnmore {
    position: absolute;
    top: 88%;
    display: flex;
    margin-left: 10%;
    text-align: center;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      color: white;
      font-size: 1rem;
    }
  }
  .learnm {
    position: absolute;
    top: 75%;
    left: 45%;
    display: flex;
    margin-left: 10%;
    text-align: center;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    gap: 10%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      color: white;
      font-size: 1rem;
    }
  }
  .learnmoree {
    position: absolute;
    top: 74%;
    display: flex;
    margin-left: 10%;
    text-align: center;
    flex-direction: row;
    cursor: pointer;
    align-items: center;
    gap: 10%;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 600;
      color: white;
      font-size: 1rem;
    }
  }

  .overlay h2,
  .overlay p {
    margin: 0;
  }

  .content-container {
    text-align: center;
    margin-bottom: 40px;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
  }
  .inner-containerr {
    width: 92%;
    align-item: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  .inven {
    display: flex;
    flex-direction: row;
    .image-content {
      position: relative;
    }
  }
  .img2 {
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    gap: 1.4%;
    .imgsecdiv{
display: flex;
flex-direction: row;

gap: 4%;
    .image-content {
      position: relative;
      
    }
  }
  .imgfirstdiv{
    display: flex;
    flex-direction: column;
        gap: 2%;
        .image-content {
     width: 96.8%;
          position: relative;
          
        }
      }
  }

  .img1 {
    display: flex;
    flex-direction: row;
    gap: 2%;
    .image-content {
      position: relative;
    }
  }

  .image-content img {
    width: 100%;
  height: 100%;
  object-fit: cover;

    border-radius: 0.7rem;
    object-fit: cover;
       transition: transform 0.3s ease;
    }
    
    .fontimg {
      background-color: trans;
      border-radius: 1rem;
      width: 100%;
      height: 100%;
      overflow: hidden;
      box-shadow: 12px 0 15px rgba(106, 89, 255, 0.12),
      /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.25),
      /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
   
    }
    .image-content img:hover {
      transform: scale(1.1);
    }
  .image-content .overlay {
    position: absolute;
    top: 34%;
    left: 37%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding: 20px;
    text-align: center;
    border-radius: 5px;
    .headder {
      margin-bottom: 20%;
      h2 {
        text-transform: uppercase;
        text-align: start;
        margin-bottom: 1rem;
        font-size: 2rem;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        text-align: start;
        margin-top: 1.5rem;
        font-weight: 400;
      }
    }

    .learnmore {
      margin-bottom: -20%;
      display: flex;
      align-items: flex-end;
      text-align: start;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      gap: 10%;
      p {
        font-size: 1rem;
      }
    }
  }

  .image-content .overlay h2,
  .image-content .overlay p {
    margin: 0;
  }

  .image-content .overlay a {
    display: block;
    margin-top: 10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5;
    height: auto;

    overflow: hidden;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .image-container {
      position: relative;
      margin-bottom: 40px;
    }

    .image-container img {
      width: 100%;
      height: auto;
    }

    .overlay {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 10%;
      left: 10%;

      background-color: rgba(255, 255, 255, 0.7);

      text-align: start;
      border-radius: 5px;
      .headder {
        h2 {
          text-transform: uppercase;
        }
      }

      .learnmore {
        margin-top: 1%;
        display: flex;
        margin-left: 10%;
        text-align: center;
        flex-direction: row;
        align-items: center;
        gap: 5%;
        p {
          font-size: 1rem;
        }
      }
    }

    .overlay h2,
    .overlay p {
      margin: 0;
    }

    .content-container {
      text-align: center;
      margin-bottom: 10px;
    }

    .inner-container {
      display: flex;
      flex-direction: row;
    }
    .inner-containerr {
      display: flex;

      flex-wrap: wrap;
    }
    .inven {
      display: flex;
      flex-direction: row;
      .image-content {
        position: relative;
      }
    }
    .img2 {
      display: flex;
      flex-direction: row;
      .imgsecdiv{
        display: flex;
        flex-direction: row;
        gap: 4%;
            .image-content {
              position: relative;
              
            }
          }
          .imgfirstdiv{
            display: flex;
            flex-direction: column;
            gap: 2%;
            .image-content {
         width: 97%;
              position: relative;
              
            }
              }
    }

    .img1 {
      display: flex;
      flex-direction: row;
      .image-content {
        position: relative;
      }
    }

    .image-content img {
      width: 100%;
      height: auto;
    }

    .image-content .overlay {
      position: absolute;
      top: 20%;
      left: 37%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: white;
      padding: 20px;
      text-align: center;
      border-radius: 5px;
    
      #dif {
        margin-top: 1rem;
        font-size: 1.6rem;
      }
      #imagecontentsec{
        margin-top: 6rem;
        h2{
          width: 140%;
          font-size: 1.2rem;
        }
        p{
          width: 140%;
          font-size: 0.5rem;
        }
      }
      #imagecontentfive{
        margin-top: 8.6rem;
        width: 100%;
        h2{
          width: 150%;
        }
      }
      #imagecontentfour{
        margin-top: 10rem;
        width: 100%;
        h2{
          width: 150%;
        }
      }
     #imagecontentthird{
      margin-top: 6rem;
      h2{
        width: 140%;
        font-size: 1.2rem;
        margin-bottom: 0;
      }
      p{
        margin-top: 0;
        width: 140%;
        font-size: 0.5rem;
      }
     }
     #imagecontentfirst {
      margin-top: 6rem;
    }
      .headder {
       
        margin-bottom: 0%;
        h2 {
          text-transform: uppercase;
          text-align: start;
          font-size: 1.6rem;
        }
        p {
          text-align: start;
          font-size: 0.7rem;
        }
      }
      .learnm {
        position: absolute;
        top: 70.5%;
        left: 20%;
        display: flex;
        margin-left: 10%;
        text-align: center;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        gap: 5%;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 600;
          color: white;
          font-size: 0.5rem;
        }
        svg {
          width: 70%;
        }
      }
      .learnmore {
        margin-bottom: -24%;
        display: flex;
        align-items: flex-end;
        text-align: start;
        flex-direction: row;
        align-items: center;
        gap: 18%;
        p {
          font-size: 1rem;
        }
      }
    }

    .image-content .overlay h2,
    .image-content .overlay p {
      margin: 0;
    }

    .image-content .overlay a {
      display: block;
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    scroll-behavior: smooth;
    width: 133vw;
    height: auto;
    overflow: hidden;
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .image-container {
      position: relative;
      margin-bottom: 40px;
    }

    .image-container img {
      width: 100%;
      height: auto;
    }

    .overlay {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 15%;
      left: 8%;
      background-color: rgba(255, 255, 255, 0.1);
      text-align: start;
      border-radius: 5px;
     
      #dif {
        margin-top: 1rem;
        text-align: left;
          font-size: 1rem;
        
      }
      #imagecontentsec{
        margin-top: 1rem;
        h2{
          width: 190%;
          font-size: 0.7rem;
        }
        p{
          width: 210%;
          font-size: 0.3rem;
        }
      }
      #imagecontentfive{
        margin-top: 1.6rem;
        width: 100%;
        h2{
          width: 150%;
        }
      }
      #imagecontentfour{
        margin-top: 3rem;
        width: 100%;
        h2{
          width: 150%;
        }
      }
     #imagecontentthird{
      margin-top: 0.5rem;
      h2{
        width: 190%;
        font-size: 0.7rem;
        margin-bottom: 0;
      }
      p{
        margin-top: 0;
        width: 190%;
        font-size: 0.2rem;
      }
     }
     #imagecontentfirst {
      margin-top: 3rem;
    }
   
      .headder {
       

        h2 {
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 1rem;
        }
        p {
          font-size: 0.5rem;
          width: 110%;
          margin-top: 0.5rem;
        }
      }
    }
    .learnm {
      position: absolute;
      top: 70.5%;
      left: 20%;
      display: flex;
      margin-left: 10%;
      text-align: center;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      gap: 5%;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        color: white;
        font-size: 0.5rem;
      }
      svg {
        width: 70%;
      }
    }
    .learnmore {
      position: absolute;
      top: 82%;
      display: flex;
      margin-left: 10%;
      text-align: center;
      flex-direction: row;
      align-items: center;
      gap: 5%;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        color: white;
        font-size: 0.5rem;
      }
      svg {
        width: 70%;
      }
    }

    .learnmoree {
      position: absolute;
      top: 74%;
      display: flex;
      right: 8%;
      margin-left: 10%;
      text-align: center;
      flex-direction: row;
      align-items: center;
      gap: 5%;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
        color: white;
        font-size: 0.7rem;
      }
      svg {
        width: 70%;
      }
    }

    .overlay h2,
    .overlay p {
      margin: 0;
    }

    .content-container {
      text-align: center;
      margin-bottom: 40px;
    }

    .inner-container {
      display: flex;
      flex-direction: row;
    }
    .inner-containerr {
      display: flex;

      flex-wrap: wrap;
    }
    .inven {
      display: flex;
      flex-direction: row;
      .image-content {
        position: relative;
      }
    }
    .img2 {
      display: flex;
      flex-direction: row;
      .imgsecdiv{
        display: flex;
        flex-direction: row;
        gap: 4%;
            .image-content {
              position: relative;
              
            }
          }
          .imgfirstdiv{
            display: flex;
            flex-direction: column;
                gap: 4%;
                .image-content {
                  width: 9.6rem;
                  position: relative;
                  
                }
              }
    }

    .img1 {
      display: flex;
      flex-direction: row;
      .image-content {
        position: relative;
      }
    }

    .image-content img {
      width: 100%;
      height: auto;
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.1),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.1),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
    }

    .image-content .overlay {
      position: absolute;
      top: 34%;
      left: 37%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: white;
      padding: 20px;
      text-align: center;
      border-radius: 5px;
      .headder {
        margin-bottom: 20%;
        h2 {
          text-transform: uppercase;
          text-align: start;
          margin-bottom: 1rem;
          margin-top: 1.3rem;
          font-size: 1rem;
        }
        p {
          text-align: start;
          width: 130%;
          margin-top: 0.5rem;
          font-size: 0.4rem;
        }
      }

      .learnmore {
        margin-bottom: -20%;
        display: flex;
        align-items: flex-end;
        text-align: start;
        flex-direction: row;
        align-items: center;
        gap: 10%;
        p {
          font-size: 0.4rem;
        }
      }
    }

    .image-content .overlay h2,
    .image-content .overlay p {
      margin: 0;
    }

    .image-content .overlay a {
      display: block;
      margin-top: 10px;
    }
  }
`;

export default Value;
