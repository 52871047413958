import React from "react";
import styled from "styled-components";
import Logo from "./Logo";

const Customizer = () => {
  return (
    <Style>
      <div className="logo">
        {/* <img src="" alt="logo" /> */}
        <Logo className={"logo-img"} />
      </div>
      <iframe
        src="https://showcase.shapespark.com/musterappartement_by_buildav/#autoplay&help"
        style={{ height: "100vh", width: "100%", overflow: "hidden" }}
        width={100}
        height={100}
      ></iframe>
    </Style>
  );
};

export default Customizer;
const Style = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
  .logo {
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    border-radius: 10px;
    margin: 10px;
    width: 130px;
    min-height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo-img {
      width: 120px;
    }
  }
`;
