import React, { useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { useSelectedTitleContext } from "./SelectedTitleContext";

// Define keyframes for fadeInRightBig animation
const fadeInRightBig = keyframes`
  from {
    opacity: 0;
    transform: translateX(200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Define keyframes for fadeInLeftBig animation
const fadeInLeftBig = keyframes`
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const Offer = () => {
  const firstDivRef = useRef(null);
  const secondDivRef = useRef(null);
  const [animateFirstDiv, setAnimateFirstDiv] = useState(false);
  const [animateSecondDiv, setAnimateSecondDiv] = useState(false);
  const { hostName } = useSelectedTitleContext();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // Adjust this threshold as needed
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === firstDivRef.current) {
            setAnimateFirstDiv(true);
          } else if (entry.target === secondDivRef.current) {
            setAnimateSecondDiv(true);
          }
        }
      });
    }, options);

    if (firstDivRef.current) {
      observer.observe(firstDivRef.current);
    }
    if (secondDivRef.current) {
      observer.observe(secondDivRef.current);
    }

    // Clean up the observer
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
      <Style>
        <div className="main">
          <div
            ref={firstDivRef}
            className={`firstdiv ${animateFirstDiv ? "animate" : ""}`}
          >
            <div className="subdivfirst">
              <h2>{hostName} offerings</h2>
            </div>
            <div className="subdivsecond">
              <h2>{hostName} offerings</h2>
            </div>
          </div>
          <div
            ref={secondDivRef}
            className={`seconddiv ${animateSecondDiv ? "animate" : ""}`}
          >
            <div className="newline">
              <div className="subdivfirst">
                <h2>{hostName} offerings</h2>
              </div>
              <div className="subdivsecond">
                <h2>{hostName} offerings</h2>
              </div>
            </div>
            <br />
          </div>
        </div>
      </Style>
    </>
  );
};

const Style = styled.div`
  width: 98.4vw;
  overflow: hidden;
  .main {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: right;
    align-items: flex-end;
    gap: 1%;
  }

  .firstdiv {
    text-transform: uppercase;
    display: flex;
    width: 100%;
    gap: 3%;
    flex-direction: row;
    margin-bottom: 0;
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 0.5s ease, transform 0.5s ease;

    &.animate {
      opacity: 1;
      transform: translateX(0);
    }

    .subdivfirst {
      color: rgba(193, 193, 193, 1);
      h2 {
        font-size: 3.9rem;
        font-weight: 400;
        font-family: "Bebas Neue", sans-serif;
      }
    }
    .subdivsecond {
      h2 {
        font-size: 3.9rem;
        font-weight: 400;
        font-family: "Bebas Neue", sans-serif;
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(170, 160, 255, 1);
      }
    }
  }

  .seconddiv {
    text-transform: uppercase;
    width: 82.5%;
    text-align: right;
    align-items: flex-end;
    margin-top: -7rem;
    opacity: 0;
    transform: translateX(-200px);
    transition: opacity 0.5s ease, transform 0.5s ease;

    &.animate {
      opacity: 1;
      transform: translateX(0);
    }

    .newline {
      width: 100%;
      display: flex;
      gap: 3%;
      text-align: right;
      align-items: flex-end;
      flex-direction: row;
      .subdivfirst {
        color: rgba(170, 160, 255, 1);
        text-align: right;
        h2 {
          font-weight: 400;
          font-size: 3.9rem;
          font-family: "Bebas Neue", sans-serif;
        }
      }
      .subdivsecond {
        h2 {
          font-size: 3.9rem;
          color: white;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: rgba(193, 193, 193, 1);
          font-weight: 400;
          font-family: "Bebas Neue", sans-serif;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.4vw;
    overflow: hidden;
    .main {
      display: flex;
      width: 100%;
      flex-direction: column;
      text-align: right;
      align-items: flex-end;
      gap: 1%;
    }

    .firstdiv {
      text-transform: uppercase;
      display: flex;
      width: 100%;
      gap: 3%;
      flex-direction: row;
      margin-bottom: 0;
      opacity: 0;
      transform: translateX(200px);
      transition: opacity 0.5s ease, transform 0.5s ease;

      &.animate {
        opacity: 1;
        transform: translateX(0);
      }

      .subdivfirst {
        color: rgba(193, 193, 193, 1);
        h2 {
          font-size: 2.9rem;
          font-weight: 400;
          font-family: "Bebas Neue", sans-serif;
        }
      }
      .subdivsecond {
        h2 {
          font-size: 2.9rem;
          font-weight: 400;
          font-family: "Bebas Neue", sans-serif;
          color: white;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: rgba(170, 160, 255, 1);
        }
      }
    }

    .seconddiv {
      text-transform: uppercase;
      width: 87.5%;
      text-align: right;
      align-items: flex-end;
      margin-top: -4rem;
      opacity: 0;
      transform: translateX(-200px);
      transition: opacity 0.5s ease, transform 0.5s ease;

      &.animate {
        opacity: 1;
        transform: translateX(0);
      }

      .newline {
        width: 100%;
        display: flex;
        gap: 3%;
        text-align: right;
        align-items: flex-end;
        flex-direction: row;
        .subdivfirst {
          color: rgba(170, 160, 255, 1);
          text-align: right;
          h2 {
            font-weight: 400;
            font-size: 2.9rem;
            font-family: "Bebas Neue", sans-serif;
          }
        }
        .subdivsecond {
          h2 {
            font-size: 2.9rem;
            color: white;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: rgba(193, 193, 193, 1);
            font-weight: 400;
            font-family: "Bebas Neue", sans-serif;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 140vw;
    overflow: hidden;
    .main {
      display: flex;
      width: 100%;
      flex-direction: column;
      text-align: right;
      align-items: flex-end;
      gap: 1%;
    }

    .firstdiv {
      text-transform: uppercase;
      display: flex;
      width: 100%;
      gap: 3%;
      flex-direction: row;
      margin-bottom: 0;
      opacity: 0;
      transform: translateX(200px);
      transition: opacity 0.5s ease, transform 0.5s ease;

      &.animate {
        opacity: 1;
        transform: translateX(0);
      }

      .subdivfirst {
        color: rgba(193, 193, 193, 1);
        h2 {
          font-size: 1.7rem;
          font-weight: 400;
          font-family: "Bebas Neue", sans-serif;
        }
      }
      .subdivsecond {
        h2 {
          font-size: 1.7rem;
          font-weight: 400;
          font-family: "Bebas Neue", sans-serif;
          color: white;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: rgba(170, 160, 255, 1);
        }
      }
    }

    .seconddiv {
      text-transform: uppercase;
      width: 87.5%;
      text-align: right;
      align-items: flex-end;
      margin-top: -2rem;
      opacity: 0;
      transform: translateX(-200px);
      transition: opacity 0.5s ease, transform 0.5s ease;

      &.animate {
        opacity: 1;
        transform: translateX(0);
      }

      .newline {
        width: 100%;
        display: flex;
        gap: 3%;
        text-align: right;
        align-items: flex-end;
        flex-direction: row;
        .subdivfirst {
          color: rgba(170, 160, 255, 1);
          text-align: right;
          h2 {
            font-weight: 400;
            font-size: 1.7rem;
            font-family: "Bebas Neue", sans-serif;
          }
        }
        .subdivsecond {
          h2 {
            font-size: 1.7rem;
            color: white;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: rgba(193, 193, 193, 1);
            font-weight: 400;
            font-family: "Bebas Neue", sans-serif;
          }
        }
      }
    }
  }
`;

export default Offer;
