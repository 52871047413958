const solutionsData = [
  {
    id: 1,
    cx: "107.5",
    thumbsrc: "walkthroug.webp",
    title: "Video Walkthroughs",
    src: "videowalkthrough.webp",
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/video-walkthroughs.mp4",
    description:
      "Give your listings, product demonstrations, and customer interaction a new lease of life. We provide best-in-quality drone+ 3D tracking, expert storyboarding with the fastest turn-around times for the best representation of your property.",
   descriptions:"Struggling to grab attention with traditional listings and product demos?  We can help!  Our video walkthroughs take your presentations to the next level, using stunning visuals and captivating storytelling.",
      benefits: [
      "Increased product knowledge",
      "Remote sales and client engagement",
      "Increased perceived value",
    ],
    subheading:[
   `Here's what makes us different:`,
    `Immersive & Interactive: We use top-of-the-line drones and 3D tracking to create dynamic videos that showcase every detail of your property or product from every angle. Imagine giving your audience a virtual tour they can control!`,
    `Compelling Stories: Our expert storyboarding artists craft engaging narratives that resonate with your viewers. Each frame is carefully planned to draw them in and keep them wanting more.`,
    `Fast & Efficient: We know time is valuable. That's why we prioritize quick turnarounds without sacrificing quality. You'll have your professional video content in no time.`,
    ],
    subhead:[  `The Benefits for You:`,
    `Boost Knowledge & Engagement: Our immersive videos educate potential buyers or customers. They can explore every feature in detail, fostering a deeper understanding and connection with your product.`,
    `Close Deals Remotely: Connect with clients anywhere in the world! Our captivating virtual tours and presentations are perfect for the digital age, allowing you to close deals remotely.`,
    `Increase Perceived Value: Showcase your property or product in the best light possible. Our high-quality videos make it more appealing and desirable, leading to higher sales or rental potential.`,
    ],
    connect:`Ready to take your presentations to new heights?  Let our expertise and technology transform your listings, demos, and interactions.  Contact us today to experience the difference!
    `,
   
    subhea:[""],
    brand: "Brand Name",
  },
  {
    id: 2,
    cx: "279.5",
    thumbsrc: "vrtoure.webp",
    title: "VR Tours",
    videosrc: "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/vr-tours.mp4",
    src: "virtualtours.webp",
    connect:`Don't just talk about it; let them experience it!
    VR tours create memorable moments that drive results.  Become a leader in your industry by taking your sales and marketing to new heights with this innovative technology. Contact us today to learn more!
    `,
    description:
      "We help you build 360-degree realistic and immersive VR tours that customers can take from the comfort of their home, at their own pace. Accessible on any device, on the web virtually or as an in-office experience. Show customers what it will be like to move around their dream home, look in different directions, and interact with objects or elements within the virtual space.",
      descriptions: "Imagine stepping into your dream home or experiencing a property first hand, all from the comfort of your couch.  Our state-of-the-art VR tours make it possible!",
      benefits: [
      "More engaged sales and marketing teams",
      "Move more customers closer to conversion",
      "Stand out from other players in the market",
    ],
    brand: "Brand Name",
    subheading:[
      `Unforgettable Virtual Experiences:`,
      `Our VR tours are unlike anything you've seen before. We create stunningly realistic, 360-degree experiences that transport you directly into your dream space. Explore every corner, get a feel for the layout, and immerse yourself in the details – all with seamless navigation.`,
      
    ],
    subhead:[
      `VR, Wherever You Are:`,
      `The best part? You don't need a fancy headset!  Our VR tours are accessible on any device, from desktops and laptops to mobile phones. This allows potential customers to explore your offerings at their convenience, anytime and anywhere.`,
       
    ],
    subhea:[ `Empower Your Sales Team:`,
    `Equip your sales and marketing teams with a powerful tool to close more leads. VR tours let them deliver personalized, interactive experiences that captivate customers and drive engagement.  Imagine showcasing property features or product functionalities in a way that leaves a lasting impression.`,
    
  ],
  },
  {
    id: 3,
    cx: "438.5",
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/inventory-selector-platform.mp4",
    title: "Inventory Selector Platform",
    thumbsrc: "INVENTORY.webp",
    src: "inventoryselector.webp",
    connect:`Stop wasting time and take control!
    Our Inventory Selector Module revolutionizes how you manage inventory, streamlines operations, and drives business growth.  Experience increased leads, faster deal closings, and complete control – all in one powerful platform.
    Contact us today to learn more!
    `,
    description:
      "Stop the hassle of manual follow-ups and managing multiple booking details and get an end-to-end backend platform where you can see inventory details on a real-time basis. Accessible on the web.",
      descriptions:"Is managing your inventory a constant struggle?  Do you waste time with outdated spreadsheets and endless follow-up emails? Our Inventory Selector Module is here to change that.",
      benefits: [
      "Increased total leads",
      "Reduced deal closing time",
      "Full control and visibility over customer actions on digital properties",
      "A single dashboard for all VR tours",
    ],
    brand: "Brand Name",
    subheading:[
      `Here's how it benefits your business:`,
`Effortless Inventory Management: Say goodbye to manual updates and endless calls. Our platform shows you real-time availability, pricing, and key details – all in one place.`,
`More Leads & Inquiries: Customers can easily explore your offerings with real-time inventory access, leading to increased interest and more leads for your business.`,
`Faster Deal Closings: No more delays! Our platform gives you everything you need to make informed decisions and close deals quickly and efficiently.`,
`Complete Visibility: Track customer interactions and monitor engagement across your digital properties with a single, centralized dashboard. Use this real-time data to optimize your strategies and maximize success.`,
`Simplified VR Tour Management: Manage all your VR tours from one place! Access tours, track performance, and make data-driven decisions to improve results and get the most out of your VR investment.`,

    ],
    subhead:[""],
    subhea:[""],
    },
  {
    id: 4,
    cx: "782.5",
    videosrc: "",
    title: "Gamified Metaverse",
    connect:`The possibilities are limitless!  Whether you're showcasing luxury estates or commercial properties, our gamified metaverse creates experiences that leave a lasting impression and drive actual results.
    Join us and redefine the future of property discovery! Contact us today to learn more.
    `,
    src: "gamifiedmetaverse1.webp",
    description:
      "Take customer engagement to the next level with a gamified metaverse of your project where customers explore property as avatars. Enable cross-platform support across web, desktop and VR headsets and build immersive and gamified VR, AR experiences that make property discovery a fun, memorable and shareable experience.",
      descriptions:"Looking for innovative ways to engage potential buyers and elevate your brand? Our gamified metaverse platform transforms property discovery into an unforgettable adventure!",
      
      benefits: [
      "Build trust with luxury customers by reducing possession anxiety",
      "Word-of-mouth marketing with a wow factor",
      "Improved retention and long term brand building",
    ],
    brand: "Brand Name",

    subheading:[
      `Here's what sets us apart:`,
     ` Immerse & Entertain: Imagine exploring properties as avatars in a virtual world! We use VR and AR technologies to create engaging experiences that are both fun and informative. Think virtual treasure hunts and interactive challenges – a world away from traditional property tours.`,
`Accessible Anywhere: Reach a wider audience with our cross-platform support. Our platform works seamlessly across web browsers, desktops, and VR headsets, allowing potential buyers to explore your properties on their terms, no matter the device.`,
`Build Trust with Discerning Buyers: Luxury clients deserve exceptional experiences. Our gamified metaverse reduces anxieties by providing realistic virtual tours, fostering trust and confidence in your brand, and building stronger, long-term relationships.`,
`Go Viral & Generate Buzz: Create shareable experiences that get people talking! Our platform ignites word-of-mouth marketing, generating excitement and anticipation among potential buyers taking your brand to new heights.`,
`Boost Retention & Brand Loyalty: Keep customers engaged and coming back for more. Our unique and memorable experiences cultivate brand loyalty and build a strong foundation for long-term growth.`,

    ],
    subhead:[""],
    subhea:[""],
  },
  {
    id: 5,
    cx: "1014.5",
    videosrc: "",
    title: "Isometric Renders and AR View",
    src: "ar.webp",
    connect:`The possibilities are limitless!  Whether you're showcasing luxury estates or commercial properties, our gamified metaverse creates experiences that leave a lasting impression and drive actual results.
    Join us and redefine the future of property discovery! Contact us today to learn more.
    `,
    description:
      "Ditch the static photos. Isometric renders and AR views offer a dynamic and interactive way to showcase your properties. Imagine presenting detailed 3D models that potential buyers can rotate and explore at their own pace.",
      descriptions:"Ready to ditch boring photos and showcase your properties in a whole new way? Imagine potential buyers being able to explore detailed 3D models of your properties – rotating, zooming, and getting a true feel for the space – all from their phone or computer!  Our isometric renders and AR views make this a reality. " ,    
       benefits: [
      "Enhanced Understanding with a clear layout of the entire property",
      "Interactive exploration with superimposing the property onto their real-world surroundings",
      "Easy web-based accessibility for customer convenience",
    ],
    brand: "Brand Name",
    subheading:[
      `Here's what sets us apart:`,
`Clearer Understanding: Detailed 3D models provide a crystal-clear layout, allowing buyers to visualize the entire space with precision. No more confusion about room sizes or layouts!`,
`Interactive Exploration: With AR technology, buyers can superimpose the property onto their real world! They can "walk through" rooms, inspect details, and truly see themselves living in the space – all with a few taps on their device.`,

`Easy Access, No Hassle: Forget downloads and installations! Our web-based platform ensures easy access to isometric renders and AR views from any computer, tablet, or smartphone. Convenience is key!`,

    ],
    subhead:[""],
    subhea:[""],
  },
  {
    id: 6,
    cx: "1295.5",
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/location-shooting.mp4",
    thumbsrc: "Location.webp",
    title: "Location Highlighting Shoot",
    src: "gamifiedmetaverse.webp",
    connect:`Go beyond selling properties; start selling lifestyles!  Showcase the unique charm of the surrounding area and attract the right buyers who envision themselves living there.
    Let us help you tell the story of your neighbourhood and unlock the full potential of your properties.  Contact us today!
    `,
    description:
      "Go beyond the property walls. Location highlighting shoots capture the surrounding area's essence, showcasing nearby amenities, transportation options, and the overall neighborhood vibe.",
      descriptions: "Looking to attract buyers who crave a specific lifestyle? Our location-highlighting shoots go beyond property walls to showcase the vibrant essence of the surrounding area.  Imagine showcasing nearby cafes, parks, schools, and the overall neighbourhood vibe – all designed to paint a picture of the dream lifestyle your property offers.",
      benefits: [
      "Lifestyle marketing to tell a story about the lifestyle that comes with the property",
      "Targeted audience appeal to specific buyer demographics",
      "Reduced workload of sales teams by a deeper understanding of surroundings",
    ],
    subheading:[
      `Here's how we help you connect with the right buyers:`,
`Lifestyle Marketing: We don't just sell properties; we sell the dream! We tell a captivating story about the lifestyle your property unlocks. Whether it's bustling city energy or serene suburban tranquillity, we capture the essence that resonates with your target audience.`,
`Targeted Appeal: One size doesn't fit all. Our shoots are tailored to specific buyer demographics. Are you targeting young professionals seeking nightlife or families desiring parks and schools? We highlight the features that matter most to them.`,
`Empowered Sales Teams: With in-depth neighbourhood knowledge from our shoots, your sales team can answer client questions and close deals faster. Our resources empower them to become lifestyle experts, not just property salespeople.`,

    ],
    subhead:[""],
    subhea:[""],
    brand: "Brand Name",
  },
];

export default solutionsData;
export const TablesData = [
  {
    id: 1,
    cx: "107.74",
    title: "Phygital Tables",
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/phygital-tables.mp4",
    thumbsrc: "Phygital.webp",
    src: "phygitaltable.webp",
    description:
      "Phygital tables bring together digital elements like virtual tours, and interactive floor plans into the physical world.",
   descriptions:"Imagine a table that brings properties to life!  Our Phygital tables blend virtual tours, interactive floor plans, and personalized experiences for a whole new way to showcase your listings.",
      features: [
      "Improved sales presentations.",
      "Increased lead generation.",
      "Personalized customer experiences.",
    ],
    brands: ["Brand D", "Brand E", "Brand F"],
    subheading:[
      `Here's how Phygital tables revolutionize property presentations:`,
`Captivate Buyers: Ditch static brochures and boring presentations. With virtual tours, customisable floor plans, and touch-screen interactivity, Phygital tables create captivating experiences that keep buyers engaged.`,
`Drive More Leads: These innovative tables stand out, sparking buyer interest and generating leads. Unique features and amenities come alive, prompting further inquiries and propelling buyers toward purchase decisions.`,
`Personalize the Journey: Not everyone wants the same information. Phygital tables cater to individual preferences by offering customisable content and self-paced exploration. Buyers can tailor their experience to their needs, fostering deeper connections and boosting engagement.`,
    ],
    subhead:"",
    subhea:"",
    connect:[
      `Phygital tables are the future of real estate marketing.  Combining the best of digital and physical, they transform property presentations, improve sales effectiveness, and personalize the customer journey.`,
      `Unlock the full potential of your listings!  Contact us today to learn more about Phygital tables.`,
      
    ],
  },
  {
    id: 2,
    cx: "279.74",
    title: "Smart Screens",
    src: "smartscreens.webp",
    videosrc: "",
    description:
      "Smart screens are integrated with touchscreen tech, gesture recognition, and AR/VR capabilities.",
   descriptions:"Tired of flat photos and boring brochures?  Take property presentation to the next level with captivating scale models!  Let potential buyers explore a tangible representation of the space, fostering deeper engagement and lasting impressions.   ",
      features: [
      "Immersive multimedia interaction.",
      "Efficient content management remotely.",
      "Insightful user interaction analytics.",
    ],
    brands: ["Brand A", "Brand B", "Brand C"],
    subheading:[
      `Here's how scale models elevate your marketing:`,
  `Engaging & Interactive: Unlike static photos, scale models offer a hands-on experience. Buyers can explore layouts, visualise details, and truly connect with the space in a way brochures simply can't.`,
  `Memorable & Effective: Ditch outdated brochures that get tossed aside. Scale models capture attention and create lasting impressions, making your properties stand out from the crowd.`,
  `Immersive & Detailed: Scale models go beyond basic layouts. Explore intricate project details, showcase surrounding amenities, and invite buyers to fully immerse themselves in the property, fostering a deeper understanding and connection.`,
  `Clear Visualization: Easily grasp project details with a clear overview. From design and layout to amenities, scale models make it simple for buyers to envision themselves living or working in the space.`,
  
    ],
    connect:[
      `Scale models are the future of property presentation.  These engaging and immersive tools revolutionise marketing, creating a tactile experience that captivates potential buyers.`,
`Join us in redefining the standards of property marketing!  Contact us today to learn more about how scale models can elevate your listings.`,

    ],
    subhead:"",
    subhea:"",
  },
  {
    id: 3,
    cx: "438.74",
    title: "Scale Models",
    thumbsrc: "scalemodels.webp",
    connect:[
      `Scale models are the future of property presentation.  These engaging and immersive tools revolutionise marketing, creating a tactile experience that captivates potential buyers.`,
`Join us in redefining the standards of property marketing!  Contact us today to learn more about how scale models can elevate your listings.`,

    ],
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/scale-model.mp4",
    src: "scalemodels.webp",
    description:
      "Scale models are smaller, proportional physical representations of properties or developments.",
      descriptions:"Tired of flat photos and boring brochures?  Take property presentation to the next level with captivating scale models!  Let potential buyers explore a tangible representation of the space, fostering deeper engagement and lasting impressions.   ",
  subheading:[
    `Here's how scale models elevate your marketing:`,
`Engaging & Interactive: Unlike static photos, scale models offer a hands-on experience. Buyers can explore layouts, visualise details, and truly connect with the space in a way brochures simply can't.`,
`Memorable & Effective: Ditch outdated brochures that get tossed aside. Scale models capture attention and create lasting impressions, making your properties stand out from the crowd.`,
`Immersive & Detailed: Scale models go beyond basic layouts. Explore intricate project details, showcase surrounding amenities, and invite buyers to fully immerse themselves in the property, fostering a deeper understanding and connection.`,
`Clear Visualization: Easily grasp project details with a clear overview. From design and layout to amenities, scale models make it simple for buyers to envision themselves living or working in the space.`,

  ],
  subhead:"",
  subhea:"",
      features: [
      "A great replacement for sales brochures and pamphlets.",
      "Granular details to build more immersive customer engagement journeys.",
      "Easy visualization and understanding of project details.",
    ],
    brands: ["Brand X", "Brand Y", "Brand Z"],
  },
  {
    id: 4,
    cx: "610.74",
    title: "Immersive AV Rooms",
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/immersive.mp4",
    thumbsrc: "immersive.webp",
    src: "immersiverooms.webp",
    description:
      "Immersive Audio Visual rooms are specialized spaces with high-quality audio visual equipment.",
      descriptions:"Imagine stepping into a property and feeling surrounded by it.  That's the power of our Immersive Audio Visual Rooms!      ",
    features: [
      "A dynamic and engaging way to explore properties.",
      "Elevating your projects and showcasing in the best possible environment.",
      "Leaving an imprint on customers to accelerate decision making.",
    ],
    subheading:[
      `These state-of-the-art spaces feature:`,
`Expansive Screens: Picture yourself transported directly into the property with crystal-clear, high-definition visuals.`,
`Surround Sound Systems: Immerse yourself in the sights and sounds of the space, creating a truly realistic experience.`,
`Ambient Lighting: Mood lighting sets the perfect atmosphere, allowing you to fully envision yourself living or working in the property.`,

    ],

    subhead:[`Here's what sets our Audio Visual Rooms apart:`,
    `Dynamic Exploration: Explore properties in a whole new way! Go beyond virtual tours with interactive presentations that ignite your curiosity and foster deeper connections.`,
    `Elevated Project Showcasing: Showcase your properties in the best possible light. Our rooms provide a stunning backdrop that makes every detail pop, leaving a lasting impression on potential buyers.`,
    `Faster Decisions: Immerse your clients in a captivating audio-visual experience that creates a sense of excitement and urgency, accelerating their decision-making process.`,
    
        ],
        subhea:"",
        connect:[
          `Experience the future of property presentation! Our audio-visual rooms combine cutting-edge technology and creativity to transform how properties are showcased and experienced.`,
`Unlock the full potential of your projects!  Contact us today to learn more.`,

        ],
            brands: ["Brand P", "Brand Q", "Brand R"],
  },
  {
    id: 5,
    cx: "782.74",
    title: "Custom Curated Fittings",
    connect:[
      `Stop wondering and start designing!  Our interactive mood board lets you personalize your future home and make informed decisions you'll love.`,
    ],
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/custom-curated.mp4",
    src: "customcurated.webp",
    thumbsrc: "customcurated.webp",
    descriptions:"Imagine customising your dream home before you even buy it!  Our interactive mood board puts the power of design choices at your fingertips.    ",
    description:
      "An interactive mood board of different finishes, materials, and fixtures displayed using touch screens.",
    
      features: [
      "Minimize the risk of dissatisfaction after the sale.",
      "Cater to individual tastes to strengthen client relationships.",
      "Faster decision-making by eliminating the back-and-forth of endless 'what-if' scenarios.",
    ],
    brands: ["Brand M", "Brand N", "Brand O"],
    subheading:[
      `Here's how it elevates the homebuying experience:`,
`See it Before You Buy it: With touch screen technology, you can explore various finishes, materials, and fixtures – all in real time! Instantly see how your design choices come together, fostering a sense of ownership and confidence before you commit.`,
`Reduced Buyer Remorse: No more worrying about post-purchase regrets! The interactive mood board minimises dissatisfaction by allowing you to experiment with different design options upfront. Ensure your chosen finishes perfectly match your vision for your new home.`,
`Personalized to Your Taste: Your dream home should reflect your unique style. Our interactive mood board caters to individual preferences, allowing you to personalize every detail and create a space that truly feels like yours.`,
`Faster Decisions & Smoother Sales: Streamline the decision-making process and eliminate endless "what-if" scenarios. See your design choices come to life instantly, allowing you to make informed decisions quickly and confidently move forward, expediting the homebuying journey.`,

    ],
    subhed:"",
  },
];

export const TeemData = [
  {
    id: 1,
    title: "Vikrant Singh",
    src: "team1.webp",
    post: "CEO",
  },
  {
    id: 2,
    title: "Vishesh Khatri",
    src: "team2.webp",
    post: "CBO",
  },
  {
    id: 3,
    title: "Anshul Padyal",
    src: "team3.webp",
    post: "CTO",
  },
  
];

// data.js
export const data = [
  {
    imageSrc: "/arposter1.png",
    imageAlt: "ll",
    title: "Blog",
    author: "Instructor name",
    buttonText: "Read Now",
  },
  {
    imageSrc: "/arposter1.png",
    imageAlt: "ll",
    title: "Blog",
    author: "Instructor name",
    buttonText: "Read Now",
  },
  {
    imageSrc: "/arposter1.png",
    imageAlt: "ll",
    title: "Blog",
    author: "Instructor name",
    buttonText: "Read Now",
  },
  {
    imageSrc: "/arposter1.png",
    imageAlt: "ll",
    title: "Blog",
    author: "Instructor name",
    buttonText: "Read Now",
  },
  {
    imageSrc: "/arposter1.png",
    imageAlt: "ll",
    title: "Blog",
    author: "Instructor name",
    buttonText: "Read Now",
  },
  {
    imageSrc: "/arposter1.png",
    imageAlt: "ll",
    title: "Blog",
    author: "Instructor name",
    buttonText: "Read Now",
  },
];

export const Homevid = [
  {
    id: 1,
    cx: "107.5",
    thumbsrc: "walkthroug.png",
    title: "Video Walkthroughs",
    src: "videowalkthrough.webp",
    videosrc:
      "https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/video-walkthroughs.mp4",
    description:
      "Give your listings, product demonstrations, and customer interaction a new lease of life. We provide best-in-quality drone+ 3D tracking, expert storyboarding with the fastest turn-around times for the best representation of your property.",
    benefits: [
      "Increased product knowledge",
      "Remote sales and client engagement",
      "Increased perceived value",
    ],
    brand: "Brand Name",
  },
];
