import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import VideoWalk from "./VideoWalk";
import solutionsData from "../data";
import { Link as ScrollLink } from "react-scroll";
import { useSelectedTitleContext } from "../SelectedTitleContext";
import NavbarComponent from "../NavbarComponent";
import LogoToggle from "../LogoToggle";
import ExperienceFooter from "../ExperienceFooter";
import GetInTouchButton from "../GetInTouchButton";

const moveUp = keyframes`
from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const InteractiveSuite = () => {
  const {
    isNavOpen,
    toggleNav,
    activeNavItem,
    handleNavItemChange,
    selectedTitleId,
    setSelectedTitleId,
    handleTitleClick,
  } = useSelectedTitleContext();

  return (
    <>
      <>
        <Style>
          <header className="header">
            <div className="img"></div>
            <div className="heading">
              <h1>Interactive Suite</h1>
              <p>Products that get your project launch eyeballs it deserves</p>
            </div>
          </header>
          <main className="main">
            <div className="row">
              {solutionsData.map((solution) => {
                return (
                  <section
                    key={solution.id}
                    className="section section-phygital"
                  >
                    <ScrollLink
                      className="link"
                      to="walk"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      <div className="img-container">
                        <img
                          onClick={() => handleTitleClick(solution.id)}
                          src={`/poster/${solution.src}`}
                          alt={solution.title}
                          loading="eager|lazy"
                        />
                        <div className="overlay">
                          <h2
                            className="title"
                            onClick={() => handleTitleClick(solution.id)}
                          >
                            {solution.title}
                          </h2>
                          <svg
                            className="svg-element"
                            width="120"
                            height="120"
                            viewBox="0 0 187 187"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M85.7709 13.1452V4.35903H91.4853V5.69326H87.3625V8.07857H91.1893V9.41279H87.3625V11.811H91.5197V13.1452H85.7709Z"
                              fill="white"
                            />
                            <path
                              d="M94.8008 4.11609L96.4519 7.48811L96.5203 7.49401L98.7331 4.45539L100.541 4.61139L97.4664 8.75551L99.8286 13.3687L97.9906 13.2101L96.3163 9.85766L96.2479 9.85176L94.0241 12.8679L92.1948 12.7101L95.3378 8.57184L92.9843 3.95936L94.8008 4.11609Z"
                              fill="white"
                            />
                            <path
                              d="M99.6772 12.9644L101.182 4.30812L104.428 4.87249C105.093 4.98811 105.63 5.2091 106.037 5.53545C106.448 5.8623 106.731 6.26278 106.887 6.73689C107.046 7.20868 107.077 7.71931 106.982 8.26878C106.885 8.82389 106.683 9.29668 106.374 9.68714C106.065 10.0776 105.661 10.3571 105.161 10.5257C104.662 10.6915 104.079 10.7164 103.411 10.6002L101.259 10.2262L101.483 8.93705L103.423 9.27436C103.812 9.34196 104.143 9.3297 104.414 9.23755C104.685 9.14541 104.901 8.99128 105.06 8.77516C105.223 8.55953 105.331 8.29815 105.384 7.991C105.437 7.68386 105.424 7.40282 105.343 7.14788C105.266 6.89342 105.113 6.67958 104.885 6.50636C104.66 6.33081 104.351 6.20899 103.96 6.14089L102.522 5.89103L101.245 13.2371L99.6772 12.9644Z"
                              fill="white"
                            />
                            <path
                              d="M106.592 13.771L108.836 5.27608L110.374 5.68251L108.472 12.8874L112.213 13.8755L111.872 15.1655L106.592 13.771Z"
                              fill="white"
                            />
                            <path
                              d="M122.285 13.6834C121.965 14.5745 121.525 15.2779 120.963 15.7936C120.405 16.3075 119.779 16.63 119.085 16.7609C118.394 16.8927 117.686 16.8288 116.962 16.5692C116.237 16.3095 115.649 15.9086 115.196 15.3664C114.747 14.8226 114.469 14.1744 114.362 13.422C114.259 12.6679 114.366 11.8467 114.685 10.9582C115.004 10.0671 115.443 9.36456 116.001 8.85062C116.563 8.33495 117.189 8.01118 117.881 7.8793C118.575 7.74838 119.284 7.81277 120.008 8.07244C120.732 8.33212 121.32 8.73255 121.77 9.27374C122.222 9.81588 122.5 10.464 122.604 11.2181C122.711 11.9705 122.604 12.7923 122.285 13.6834ZM120.779 13.1433C121.004 12.516 121.095 11.9517 121.053 11.4505C121.014 10.9475 120.863 10.5239 120.597 10.1796C120.333 9.83255 119.976 9.57843 119.526 9.41722C119.076 9.25601 118.639 9.22535 118.214 9.32524C117.791 9.42244 117.403 9.65253 117.051 10.0155C116.703 10.3768 116.416 10.871 116.191 11.4983C115.966 12.1256 115.873 12.6908 115.911 13.1937C115.953 13.6949 116.107 14.1191 116.371 14.4661C116.637 14.8104 116.994 15.0632 117.444 15.2244C117.893 15.3856 118.33 15.4176 118.754 15.3204C119.179 15.2205 119.565 14.9899 119.913 14.6287C120.265 14.2657 120.554 13.7706 120.779 13.1433Z"
                              fill="white"
                            />
                            <path
                              d="M120.086 17.1478L123.751 9.16275L126.746 10.5373C127.359 10.8189 127.825 11.1618 128.143 11.5658C128.464 11.9711 128.642 12.415 128.679 12.8975C128.719 13.3786 128.622 13.8739 128.389 14.3834C128.154 14.8954 127.84 15.2898 127.449 15.5665C127.061 15.8417 126.61 15.9854 126.095 15.9974C125.58 16.0069 125.017 15.8708 124.403 15.5892L122.27 14.6102L122.822 13.4093L124.76 14.2988C125.118 14.4635 125.435 14.549 125.709 14.5552C125.984 14.5588 126.22 14.4895 126.418 14.3473C126.619 14.2036 126.785 13.9902 126.915 13.7068C127.045 13.4235 127.1 13.1561 127.08 12.9047C127.061 12.6506 126.961 12.4191 126.78 12.2101C126.601 11.9986 126.33 11.8098 125.969 11.644L124.643 11.0354L121.532 17.8118L120.086 17.1478ZM125.872 15.4232L126.188 19.9488L124.574 19.2079L124.293 14.6983L125.872 15.4232Z"
                              fill="white"
                            />
                            <path
                              d="M126.565 19.6932L130.903 12.0529L135.872 14.8745L135.213 16.0348L131.628 13.999L130.45 16.0733L133.778 17.9628L133.119 19.1231L129.792 17.2335L128.607 19.3189L132.222 21.3716L131.564 22.5318L126.565 19.6932Z"
                              fill="white"
                            />
                            <path
                              d="M149.907 24.2073L144.324 30.9916L143.231 30.092L143.837 22.8402L143.781 22.7938L139.978 27.415L138.749 26.4036L144.332 19.6193L145.432 20.5243L144.82 27.7767L144.879 27.8258L148.685 23.2014L149.907 24.2073Z"
                              fill="white"
                            />
                            <path
                              d="M153.048 32.8709C152.386 33.5474 151.692 34.0029 150.967 34.2372C150.245 34.4715 149.541 34.5033 148.856 34.3327C148.173 34.1641 147.556 33.8108 147.006 33.2726C146.456 32.7345 146.089 32.1246 145.903 31.443C145.722 30.7613 145.74 30.0563 145.957 29.3279C146.177 28.5994 146.618 27.898 147.278 27.2234C147.94 26.5469 148.632 26.0914 149.353 25.8571C150.079 25.6228 150.783 25.59 151.467 25.7586C152.152 25.9292 152.77 26.2835 153.32 26.8217C153.87 27.3598 154.236 27.9687 154.419 28.6483C154.605 29.3299 154.587 30.0349 154.366 30.7634C154.15 31.4918 153.71 32.1943 153.048 32.8709ZM151.905 31.7516C152.371 31.2753 152.689 30.8006 152.86 30.3276C153.035 29.8544 153.074 29.406 152.976 28.9824C152.88 28.5566 152.662 28.1767 152.321 27.8427C151.979 27.5086 151.595 27.2983 151.167 27.2119C150.741 27.1235 150.293 27.1708 149.822 27.3538C149.354 27.5368 148.888 27.8665 148.421 28.3427C147.955 28.819 147.635 29.2937 147.46 29.7668C147.289 30.2399 147.251 30.6893 147.347 31.115C147.445 31.5387 147.664 31.9175 148.005 32.2516C148.347 32.5857 148.73 32.797 149.156 32.8854C149.584 32.9718 150.031 32.9235 150.498 32.7405C150.97 32.5575 151.438 32.2278 151.905 31.7516Z"
                              fill="white"
                            />
                            <path
                              d="M150.809 36.504L155.855 28.896L156.971 30.194L153.107 35.6035L153.16 35.6653L159.158 32.738L160.174 33.9189L156.379 39.4158L156.429 39.4744L162.361 36.463L163.477 37.761L155.197 41.6083L154.174 40.4177L157.702 35.0649L157.657 35.0129L151.833 37.6947L150.809 36.504Z"
                              fill="white"
                            />
                            <path
                              d="M168.938 63.7336L177.383 61.3078L178.961 66.8001L177.678 67.1685L176.54 63.2059L174.247 63.8645L175.304 67.5425L174.021 67.9109L172.965 64.2329L170.66 64.895L171.808 68.8905L170.525 69.2589L168.938 63.7336Z"
                              fill="white"
                            />
                            <path
                              d="M180.111 69.9203L177.326 72.4382L177.339 72.5056L180.87 73.7934L181.22 75.5742L176.388 73.7632L172.606 77.3072L172.251 75.497L175.011 72.9621L174.998 72.8948L171.485 71.5902L171.131 69.7883L175.976 71.6667L179.76 68.1311L180.111 69.9203Z"
                              fill="white"
                            />
                            <path
                              d="M172.953 77.0514L181.689 76.108L182.043 79.3837C182.115 80.0548 182.051 80.6314 181.85 81.1133C181.649 81.5982 181.342 81.9808 180.929 82.2613C180.52 82.5443 180.038 82.7158 179.483 82.7756C178.923 82.8361 178.413 82.7719 177.952 82.5829C177.492 82.3938 177.111 82.0825 176.811 81.6489C176.514 81.2149 176.329 80.661 176.257 79.9871L176.022 77.816L177.323 77.6755L177.534 79.6333C177.577 80.0257 177.68 80.3397 177.843 80.5752C178.007 80.8107 178.214 80.9752 178.466 81.0689C178.718 81.1653 178.999 81.1968 179.309 81.1634C179.619 81.1299 179.886 81.0393 180.108 80.8915C180.331 80.7466 180.495 80.5405 180.598 80.2733C180.705 80.0086 180.737 79.6787 180.694 79.2834L180.537 77.8332L173.124 78.6339L172.953 77.0514Z"
                              fill="white"
                            />
                            <path
                              d="M174.088 83.9199L182.872 83.7309L182.906 85.3221L175.456 85.4825L175.539 89.3513L174.205 89.38L174.088 83.9199Z"
                              fill="white"
                            />
                            <path
                              d="M178.505 98.9782C177.56 98.9171 176.762 98.6879 176.112 98.2904C175.464 97.896 174.981 97.3833 174.663 96.7523C174.346 96.1242 174.212 95.4262 174.261 94.6585C174.311 93.8907 174.534 93.2144 174.93 92.6295C175.329 92.0477 175.875 91.6015 176.568 91.2909C177.265 90.9834 178.084 90.8601 179.026 90.921C179.97 90.9821 180.767 91.2098 181.415 91.6042C182.065 92.0017 182.55 92.5145 182.867 93.1426C183.185 93.7736 183.319 94.473 183.269 95.2407C183.219 96.0085 182.997 96.6834 182.601 97.2654C182.205 97.8503 181.659 98.2965 180.962 98.604C180.269 98.9145 179.449 99.0393 178.505 98.9782ZM178.608 97.3813C179.273 97.4243 179.841 97.3564 180.311 97.1775C180.784 97.0018 181.149 96.7388 181.406 96.3886C181.667 96.0385 181.813 95.6252 181.843 95.1486C181.874 94.6719 181.783 94.2433 181.57 93.8627C181.359 93.4822 181.031 93.1729 180.585 92.9349C180.142 92.6999 179.587 92.5609 178.922 92.5179C178.257 92.4749 177.688 92.5413 177.216 92.7171C176.746 92.8959 176.38 93.1603 176.12 93.5104C175.862 93.8606 175.718 94.274 175.687 94.7506C175.656 95.2273 175.746 95.6558 175.956 96.0363C176.17 96.4169 176.498 96.7247 176.941 96.9597C177.387 97.1978 177.943 97.3383 178.608 97.3813Z"
                              fill="white"
                            />
                            <path
                              d="M174.566 97.8213L183.253 99.1397L182.759 102.397C182.657 103.065 182.456 103.607 182.156 104.024C181.855 104.444 181.478 104.738 181.024 104.907C180.573 105.078 180.07 105.122 179.516 105.038C178.959 104.953 178.493 104.761 178.119 104.461C177.748 104.165 177.485 103.771 177.331 103.279C177.18 102.787 177.155 102.208 177.256 101.54L177.608 99.2203L178.915 99.4185L178.595 101.527C178.536 101.917 178.541 102.244 178.611 102.51C178.683 102.775 178.815 102.983 179.006 103.134C179.2 103.288 179.451 103.388 179.759 103.435C180.067 103.481 180.339 103.461 180.576 103.372C180.815 103.284 181.01 103.124 181.16 102.892C181.314 102.661 181.421 102.349 181.48 101.956L181.699 100.514L174.328 99.3949L174.566 97.8213ZM177.821 102.906L173.559 104.459L173.825 102.703L178.082 101.188L177.821 102.906Z"
                              fill="white"
                            />
                            <path
                              d="M173.911 104.751L182.452 106.812L181.112 112.367L179.815 112.054L180.782 108.046L178.463 107.487L177.565 111.207L176.268 110.894L177.166 107.174L174.834 106.612L173.86 110.653L172.563 110.34L173.911 104.751Z"
                              fill="white"
                            />
                            <path
                              d="M176.016 128.432L167.954 124.94L168.517 123.641L175.654 122.221L175.683 122.154L170.191 119.775L170.824 118.314L178.886 121.807L178.32 123.114L171.18 124.528L171.15 124.599L176.645 126.98L176.016 128.432Z"
                              fill="white"
                            />
                            <path
                              d="M168.556 133.842C167.723 133.393 167.094 132.852 166.668 132.219C166.244 131.59 166.019 130.923 165.994 130.217C165.967 129.513 166.136 128.823 166.502 128.146C166.867 127.469 167.352 126.947 167.956 126.581C168.561 126.219 169.243 126.041 170.003 126.048C170.764 126.059 171.56 126.289 172.391 126.737C173.224 127.187 173.853 127.726 174.277 128.355C174.703 128.987 174.929 129.656 174.955 130.359C174.981 131.065 174.811 131.756 174.445 132.433C174.08 133.11 173.596 133.631 172.993 133.995C172.389 134.361 171.707 134.539 170.946 134.528C170.186 134.52 169.389 134.292 168.556 133.842ZM169.316 132.434C169.903 132.751 170.447 132.926 170.949 132.959C171.452 132.997 171.893 132.91 172.273 132.699C172.656 132.49 172.961 132.175 173.188 131.755C173.415 131.334 173.511 130.907 173.476 130.472C173.443 130.038 173.274 129.62 172.968 129.218C172.663 128.819 172.217 128.462 171.631 128.145C171.044 127.829 170.5 127.652 169.997 127.614C169.495 127.581 169.052 127.669 168.67 127.878C168.289 128.089 167.986 128.404 167.759 128.825C167.532 129.245 167.435 129.672 167.467 130.106C167.502 130.54 167.672 130.957 167.977 131.356C168.283 131.758 168.73 132.118 169.316 132.434Z"
                              fill="white"
                            />
                            <path
                              d="M164.445 132.694L173.151 135.443L172.211 136.874L165.945 134.654L165.9 134.722L170.37 139.679L169.515 140.981L163.184 138.851L163.142 138.916L167.674 143.785L166.734 145.216L160.751 138.321L161.613 137.008L167.731 138.922L167.769 138.864L163.583 134.007L164.445 132.694Z"
                              fill="white"
                            />
                            <path
                              d="M143.282 157.638L147.945 165.084L143.101 168.117L142.393 166.986L145.888 164.798L144.622 162.777L141.378 164.808L140.67 163.677L143.913 161.646L142.641 159.613L139.117 161.82L138.409 160.689L143.282 157.638Z"
                              fill="white"
                            />
                            <path
                              d="M140.418 170.083L137.229 168.102L137.168 168.133L136.905 171.883L135.29 172.71L135.696 167.566L131.246 164.91L132.888 164.069L136.086 166.021L136.147 165.99L136.431 162.254L138.065 161.416L137.598 166.592L142.04 169.252L140.418 170.083Z"
                              fill="white"
                            />
                            <path
                              d="M131.589 165.173L134.908 173.308L131.857 174.552C131.232 174.807 130.66 174.905 130.141 174.844C129.62 174.785 129.167 174.596 128.784 174.277C128.399 173.961 128.101 173.545 127.89 173.029C127.677 172.507 127.598 171.999 127.653 171.504C127.707 171.009 127.901 170.558 128.235 170.15C128.57 169.744 129.052 169.414 129.679 169.158L131.701 168.333L132.196 169.545L130.372 170.288C130.007 170.437 129.733 170.623 129.552 170.845C129.371 171.067 129.27 171.312 129.25 171.58C129.227 171.849 129.274 172.128 129.392 172.417C129.509 172.705 129.67 172.936 129.874 173.11C130.074 173.284 130.318 173.384 130.603 173.41C130.887 173.439 131.213 173.379 131.581 173.229L132.931 172.678L130.115 165.774L131.589 165.173Z"
                              fill="white"
                            />
                            <path
                              d="M125.299 168.159L127.906 176.549L126.386 177.021L124.175 169.905L120.48 171.053L120.084 169.779L125.299 168.159Z"
                              fill="white"
                            />
                            <path
                              d="M112.047 176.561C111.845 175.637 111.845 174.807 112.047 174.071C112.247 173.34 112.607 172.734 113.126 172.255C113.642 171.776 114.275 171.455 115.027 171.29C115.779 171.126 116.49 171.153 117.162 171.373C117.831 171.595 118.411 171.997 118.901 172.578C119.388 173.162 119.733 173.915 119.935 174.837C120.137 175.762 120.138 176.591 119.938 177.322C119.735 178.057 119.376 178.664 118.86 179.143C118.341 179.622 117.706 179.944 116.954 180.109C116.203 180.273 115.493 180.245 114.824 180.025C114.152 179.806 113.573 179.404 113.085 178.82C112.595 178.239 112.249 177.486 112.047 176.561ZM113.61 176.22C113.752 176.871 113.974 177.398 114.276 177.8C114.576 178.206 114.929 178.484 115.337 178.635C115.745 178.789 116.183 178.815 116.649 178.713C117.116 178.611 117.503 178.405 117.81 178.095C118.117 177.788 118.324 177.387 118.43 176.892C118.533 176.401 118.514 175.83 118.371 175.179C118.229 174.528 118.008 174 117.709 173.594C117.407 173.191 117.052 172.913 116.644 172.759C116.236 172.608 115.799 172.584 115.332 172.686C114.865 172.788 114.478 172.993 114.171 173.3C113.864 173.61 113.659 174.011 113.555 174.502C113.449 174.996 113.468 175.569 113.61 176.22Z"
                              fill="white"
                            />
                            <path
                              d="M112.072 172.458L113.203 181.171L109.936 181.595C109.267 181.682 108.69 181.639 108.206 181.465C107.719 181.292 107.332 181.01 107.045 180.621C106.756 180.235 106.575 179.763 106.503 179.207C106.43 178.649 106.486 178.148 106.671 177.706C106.853 177.267 107.16 176.906 107.59 176.622C108.021 176.341 108.571 176.157 109.24 176.07L111.567 175.768L111.738 177.079L109.623 177.353C109.232 177.404 108.918 177.499 108.683 177.64C108.447 177.783 108.284 177.967 108.192 178.192C108.098 178.421 108.071 178.69 108.111 178.999C108.151 179.308 108.246 179.564 108.396 179.766C108.547 179.972 108.755 180.115 109.019 180.196C109.284 180.28 109.613 180.296 110.007 180.245L111.454 180.057L110.494 172.663L112.072 172.458ZM108.084 176.991L105.414 173.322L107.175 173.094L109.807 176.767L108.084 176.991Z"
                              fill="white"
                            />
                            <path
                              d="M105.229 173.74L105.607 182.518L99.8981 182.764L99.8407 181.431L103.96 181.253L103.857 178.87L100.034 179.035L99.9764 177.702L103.8 177.537L103.696 175.141L99.5432 175.32L99.4858 173.987L105.229 173.74Z"
                              fill="white"
                            />
                            <path
                              d="M83.0504 182.302L84.1816 173.589L85.5856 173.771L88.9206 180.239L88.9929 180.249L89.7634 174.314L91.3418 174.519L90.2106 183.232L88.7982 183.048L85.468 176.577L85.3914 176.567L84.6203 182.506L83.0504 182.302Z"
                              fill="white"
                            />
                            <path
                              d="M75.7913 176.626C75.9935 175.701 76.3398 174.947 76.8304 174.363C77.3176 173.781 77.897 173.381 78.5684 173.162C79.2371 172.942 79.9473 172.914 80.6989 173.079C81.4505 173.243 82.0857 173.565 82.6046 174.044C83.12 174.526 83.4791 175.133 83.6821 175.865C83.8817 176.599 83.8807 177.428 83.6792 178.35C83.477 179.275 83.1323 180.028 82.6452 180.609C82.1546 181.193 81.5749 181.595 80.9062 181.815C80.2347 182.034 79.5232 182.061 78.7716 181.897C78.02 181.733 77.3861 181.411 76.8701 180.932C76.3513 180.453 75.9921 179.846 75.7926 179.112C75.5896 178.379 75.5892 177.551 75.7913 176.626ZM77.3546 176.967C77.2124 177.618 77.1943 178.19 77.3006 178.681C77.4035 179.175 77.6087 179.576 77.9162 179.883C78.2232 180.193 78.61 180.399 79.0766 180.501C79.5432 180.603 79.9807 180.577 80.389 180.424C80.7968 180.273 81.1518 179.995 81.4541 179.589C81.753 179.186 81.9735 178.659 82.1158 178.008C82.2581 177.357 82.2778 176.785 82.175 176.291C82.0687 175.799 81.8621 175.398 81.5552 175.088C81.2476 174.781 80.8605 174.576 80.3939 174.474C79.9273 174.372 79.4901 174.397 79.0824 174.548C78.674 174.701 78.3204 174.98 78.0215 175.383C77.7192 175.788 77.4969 176.316 77.3546 176.967Z"
                              fill="white"
                            />
                            <path
                              d="M75.7582 172.358L75.5193 181.485L73.8846 180.977L74.2886 174.341L74.2108 174.317L70.6808 179.981L69.1936 179.519L69.4924 172.847L69.4186 172.824L65.9898 178.524L64.3551 178.016L69.3301 170.361L70.8296 170.827L70.6801 177.236L70.7457 177.257L74.2587 171.893L75.7582 172.358Z"
                              fill="white"
                            />
                            <path
                              d="M45.9422 158.903L40.0725 165.44L35.8204 161.623L36.7117 160.63L39.7795 163.384L41.3731 161.609L38.5255 159.053L39.4169 158.06L42.2644 160.617L43.8665 158.832L40.7732 156.055L41.6646 155.062L45.9422 158.903Z"
                              fill="white"
                            />
                            <path
                              d="M33.1901 159.588L34.2142 155.976L34.1673 155.926L30.4907 156.709L29.2496 155.385L34.306 154.355L35.6303 149.344L36.8919 150.69L35.8981 154.303L35.945 154.354L39.6148 153.595L40.8706 154.934L35.7672 155.914L34.4371 160.918L33.1901 159.588Z"
                              fill="white"
                            />
                            <path
                              d="M35.4717 149.745L28.5689 155.181L26.5305 152.593C26.1129 152.062 25.8614 151.54 25.776 151.024C25.6889 150.507 25.7458 150.02 25.9467 149.563C26.1437 149.106 26.4612 148.705 26.8994 148.36C27.342 148.011 27.8087 147.795 28.2994 147.71C28.79 147.626 29.2775 147.688 29.7619 147.896C30.2441 148.107 30.6948 148.478 31.1142 149.01L32.4652 150.726L31.4371 151.536L30.2189 149.988C29.9747 149.678 29.7208 149.467 29.4572 149.354C29.1937 149.241 28.9304 149.212 28.6673 149.266C28.4025 149.318 28.1476 149.441 27.9027 149.634C27.6577 149.826 27.4801 150.045 27.3696 150.288C27.2575 150.529 27.2284 150.791 27.2825 151.072C27.3326 151.353 27.4807 151.65 27.7266 151.962L28.629 153.108L34.487 148.495L35.4717 149.745Z"
                              fill="white"
                            />
                            <path
                              d="M30.866 144.526L23.5214 149.348L22.6479 148.018L28.8771 143.928L26.7533 140.693L27.8686 139.961L30.866 144.526Z"
                              fill="white"
                            />
                            <path
                              d="M19.1299 134.108C19.963 133.658 20.7608 133.429 21.5232 133.421C22.2818 133.411 22.963 133.589 23.567 133.956C24.1696 134.319 24.6536 134.84 25.019 135.517C25.3844 136.194 25.5545 136.885 25.5292 137.591C25.5001 138.296 25.274 138.964 24.8509 139.595C24.424 140.225 23.7953 140.765 22.9647 141.213C22.1316 141.663 21.3358 141.892 20.5772 141.902C19.8148 141.91 19.1323 141.733 18.5297 141.369C17.9257 141.003 17.4411 140.481 17.0756 139.804C16.7102 139.127 16.5408 138.437 16.5675 137.733C16.5928 137.027 16.8188 136.359 17.2458 135.729C17.6688 135.098 18.2968 134.557 19.1299 134.108ZM19.89 135.516C19.3035 135.832 18.8585 136.191 18.555 136.592C18.2476 136.992 18.0776 137.409 18.0451 137.843C18.0101 138.277 18.106 138.705 18.3328 139.125C18.5597 139.546 18.8645 139.861 19.2473 140.07C19.6276 140.281 20.0699 140.369 20.5743 140.334C21.0748 140.298 21.6182 140.121 22.2047 139.805C22.7911 139.488 23.2381 139.13 23.5455 138.73C23.849 138.329 24.0183 137.911 24.0533 137.476C24.0858 137.043 23.9887 136.616 23.7618 136.195C23.535 135.775 23.2314 135.459 22.8511 135.249C22.4684 135.039 22.0267 134.953 21.5262 134.989C21.0218 135.024 20.4764 135.199 19.89 135.516Z"
                              fill="white"
                            />
                            <path
                              d="M23.0806 132.999L15.0185 136.492L13.7087 133.468C13.4403 132.849 13.3228 132.283 13.3561 131.77C13.3882 131.254 13.5519 130.804 13.8471 130.421C14.1385 130.036 14.5414 129.732 15.0557 129.509C15.5727 129.285 16.0693 129.201 16.5453 129.257C17.0176 129.311 17.4495 129.505 17.841 129.841C18.2299 130.177 18.5585 130.655 18.8268 131.274L19.7598 133.428L18.5473 133.953L17.6997 131.996C17.5428 131.634 17.3644 131.359 17.1646 131.172C16.9622 130.985 16.74 130.879 16.4981 130.853C16.2524 130.825 15.9865 130.873 15.7004 130.997C15.4144 131.121 15.1947 131.283 15.0416 131.484C14.8857 131.685 14.8054 131.924 14.8005 132.201C14.7929 132.478 14.8682 132.799 15.0262 133.164L15.6061 134.503L22.4478 131.538L23.0806 132.999ZM17.623 130.417L20.4114 126.838L21.1175 128.468L18.3137 132.011L17.623 130.417Z"
                              fill="white"
                            />
                            <path
                              d="M19.9609 126.776L11.6285 129.563L9.81588 124.144L11.0812 123.721L12.3889 127.631L14.6511 126.874L13.4372 123.245L14.7026 122.822L15.9164 126.451L18.1907 125.69L16.8721 121.748L18.1375 121.324L19.9609 126.776Z"
                              fill="white"
                            />
                            <path
                              d="M5.60849 107.823L14.2952 106.505L14.5076 107.905L9.21188 112.896L9.22283 112.968L15.1398 112.07L15.3786 113.643L6.69193 114.962L6.4782 113.554L11.7788 108.566L11.7672 108.49L5.84603 109.388L5.60849 107.823Z"
                              fill="white"
                            />
                            <path
                              d="M9.05801 99.2791C10.0027 99.218 10.8233 99.3427 11.5198 99.653C12.2132 99.9607 12.7579 100.407 13.154 100.992C13.5499 101.574 13.7726 102.249 13.8222 103.017C13.8719 103.784 13.738 104.484 13.4205 105.115C13.1 105.743 12.6158 106.256 11.968 106.653C11.3171 107.048 10.5207 107.275 9.57885 107.336C8.63414 107.397 7.81507 107.274 7.12166 106.966C6.42521 106.656 5.87904 106.21 5.48316 105.628C5.0871 105.043 4.86425 104.367 4.81462 103.599C4.76499 102.831 4.899 102.133 5.21665 101.505C5.53412 100.874 6.01829 100.361 6.66917 99.9666C7.31702 99.5693 8.11329 99.3402 9.05801 99.2791ZM9.16124 100.876C8.49623 100.919 7.94213 101.059 7.49893 101.297C7.0527 101.532 6.72438 101.84 6.51395 102.221C6.30068 102.601 6.20945 103.03 6.24026 103.507C6.27107 103.983 6.41673 104.397 6.67724 104.747C6.93489 105.097 7.30021 105.361 7.77319 105.54C8.24314 105.716 8.81061 105.782 9.47563 105.739C10.1406 105.696 10.6963 105.557 11.1425 105.322C11.5857 105.084 11.9139 104.775 12.1272 104.394C12.3376 104.014 12.4274 103.585 12.3966 103.109C12.3658 102.632 12.2216 102.219 11.9639 101.868C11.7034 101.518 11.3382 101.255 10.8682 101.079C10.3952 100.901 9.82625 100.833 9.16124 100.876Z"
                              fill="white"
                            />
                            <path
                              d="M13.1514 98.0708L4.31386 100.361L4.35069 98.6495L10.8399 97.2058L10.8416 97.1243L4.42287 95.2954L4.45638 93.7385L10.9523 92.1833L10.9539 92.1061L4.52857 90.3844L4.5654 88.673L13.2962 91.3412L13.2624 92.911L7.06107 94.5369L7.0596 94.6055L13.1851 96.501L13.1514 98.0708Z"
                              fill="white"
                            />
                            <path
                              d="M17.8517 65.6971L9.94739 61.8606L12.4426 56.7197L13.6429 57.3023L11.8427 61.0113L13.9886 62.0528L15.6596 58.6101L16.8599 59.1927L15.1889 62.6354L17.3464 63.6826L19.1616 59.9427L20.3619 60.5253L17.8517 65.6971Z"
                              fill="white"
                            />
                            <path
                              d="M13.6705 53.6302L17.425 53.6173L17.4602 53.5583L15.6928 50.2408L16.6226 48.6823L19.0082 53.258L24.1898 53.1473L23.2447 54.7315L19.4976 54.7739L19.4624 54.8328L21.2048 58.1504L20.264 59.7273L17.9135 55.0927L12.7363 55.196L13.6705 53.6302Z"
                              fill="white"
                            />
                            <path
                              d="M23.7624 53.1057L16.6321 47.9719L18.5572 45.298C18.9516 44.7503 19.3846 44.3642 19.8563 44.1399C20.3296 43.9133 20.8135 43.8335 21.308 43.9005C21.8018 43.9635 22.275 44.158 22.7276 44.4839C23.1849 44.8131 23.5217 45.2019 23.7381 45.6502C23.9545 46.0985 24.0295 46.5842 23.963 47.1073C23.8942 47.6287 23.6618 48.1645 23.2658 48.7145L21.9898 50.4867L20.9279 49.7221L22.0785 48.1241C22.3091 47.8038 22.4423 47.5014 22.4779 47.2169C22.5136 46.9324 22.469 46.6713 22.3442 46.4334C22.2211 46.1932 22.0331 45.9821 21.7801 45.7999C21.5271 45.6178 21.2683 45.5072 21.0038 45.4683C20.741 45.4271 20.4818 45.4714 20.2263 45.6011C19.9702 45.7268 19.7259 45.9509 19.4936 46.2736L18.6414 47.4573L24.6924 51.814L23.7624 53.1057Z"
                              fill="white"
                            />
                            <path
                              d="M27.5064 47.2374L20.8438 41.5097L21.8814 40.3027L27.5322 45.1606L30.0549 42.2262L31.0666 43.096L27.5064 47.2374Z"
                              fill="white"
                            />
                            <path
                              d="M34.2794 33.0818C34.9416 33.7584 35.382 34.4619 35.6007 35.1924C35.8194 35.9188 35.8361 36.6228 35.6508 37.3044C35.4675 37.984 35.1009 38.5929 34.5511 39.131C34.0012 39.6692 33.3836 40.0235 32.6982 40.1941C32.0128 40.3607 31.3083 40.3278 30.5847 40.0955C29.8612 39.8592 29.1693 39.4038 28.5091 38.7293C27.847 38.0527 27.4065 37.3512 27.1878 36.6248C26.9692 35.8943 26.9515 35.1893 27.1347 34.5097C27.3201 33.8281 27.6876 33.2182 28.2375 32.6801C28.7873 32.1419 29.4039 31.7886 30.0873 31.62C30.7728 31.4494 31.4773 31.4822 32.2008 31.7185C32.9244 31.9508 33.6173 32.4053 34.2794 33.0818ZM33.1358 34.2011C32.6697 33.7249 32.202 33.3962 31.7327 33.2152C31.2634 33.0302 30.816 32.9819 30.3903 33.0703C29.9626 33.1567 29.5781 33.367 29.2367 33.7011C28.8954 34.0351 28.6769 34.4151 28.5813 34.8408C28.4837 35.2644 28.5214 35.7138 28.6942 36.189C28.8671 36.6601 29.1867 37.1337 29.6528 37.61C30.1189 38.0862 30.5866 38.4169 31.0558 38.6019C31.5251 38.7829 31.9736 38.8302 32.4013 38.7438C32.827 38.6554 33.2105 38.4441 33.5518 38.11C33.8932 37.7759 34.1127 37.3971 34.2102 36.9734C34.3058 36.5477 34.2672 36.0993 34.0943 35.6282C33.9214 35.1531 33.6019 34.6774 33.1358 34.2011Z"
                              fill="white"
                            />
                            <path
                              d="M36.4369 36.5727L30.8539 29.7884L33.398 27.6948C33.9192 27.2659 34.431 26.9966 34.9334 26.8869C35.4379 26.7754 35.9153 26.8085 36.3653 26.9863C36.8158 27.1601 37.2191 27.4635 37.5753 27.8963C37.9334 28.3314 38.1514 28.7853 38.2294 29.2582C38.3078 29.7271 38.2399 30.196 38.0257 30.6648C37.8097 31.1315 37.4411 31.5793 36.9199 32.0082L35.1079 33.4994L34.2683 32.4791L35.9146 31.1242C36.2194 30.8734 36.4344 30.6261 36.5597 30.3822C36.6832 30.1362 36.724 29.8933 36.6823 29.6535C36.641 29.4097 36.5212 29.1675 36.3232 28.9268C36.1251 28.6861 35.9086 28.5197 35.6737 28.4278C35.4369 28.3337 35.1851 28.3225 34.918 28.3941C34.6492 28.4634 34.3613 28.6244 34.0543 28.8771L32.928 29.8039L37.6659 35.5613L36.4369 36.5727ZM37.4119 30.6144L41.6212 32.3064L40.2498 33.435L36.0703 31.7184L37.4119 30.6144Z"
                              fill="white"
                            />
                            <path
                              d="M41.5555 31.8551L36.5763 24.616L41.2846 21.3776L42.0407 22.4769L38.6438 24.8133L39.9956 26.7786L43.1485 24.61L43.9047 25.7093L40.7517 27.8779L42.1107 29.8539L45.5359 27.498L46.292 28.5973L41.5555 31.8551Z"
                              fill="white"
                            />
                            <path
                              d="M55.8094 12.827L59.475 20.812L58.1883 21.4027L51.9291 17.6908L51.8628 17.7213L54.3596 23.1603L52.913 23.8243L49.2475 15.8393L50.542 15.245L56.7991 18.9626L56.8693 18.9304L54.3707 13.4875L55.8094 12.827Z"
                              fill="white"
                            />
                            <path
                              d="M64.9746 13.7852C65.2942 14.6763 65.4009 15.4994 65.2949 16.2545C65.1906 17.0059 64.9121 17.6527 64.4593 18.1948C64.0092 18.736 63.4221 19.1364 62.6979 19.3961C61.9736 19.6558 61.2645 19.7202 60.5704 19.5893C59.878 19.4547 59.2514 19.1309 58.6907 18.6179C58.1318 18.1013 57.693 17.3988 57.3744 16.5103C57.0549 15.6192 56.9473 14.7979 57.0516 14.0465C57.1576 13.2914 57.4356 12.6433 57.8857 12.1021C58.3385 11.56 58.927 11.1591 59.6512 10.8994C60.3754 10.6397 61.0833 10.5758 61.7747 10.7077C62.4688 10.8386 63.0953 11.1624 63.6543 11.679C64.215 12.192 64.6551 12.894 64.9746 13.7852ZM63.4683 14.3253C63.2434 13.698 62.9555 13.2042 62.6045 12.8439C62.2552 12.48 61.8687 12.2494 61.4449 12.1522C61.0202 12.0523 60.583 12.083 60.1334 12.2442C59.6838 12.4054 59.3268 12.6595 59.0623 13.0065C58.7968 13.3508 58.6436 13.775 58.6024 14.2789C58.5631 14.7792 58.6558 15.3429 58.8807 15.9702C59.1057 16.5975 59.3928 17.0931 59.742 17.4571C60.093 17.8174 60.4809 18.0475 60.9056 18.1474C61.3294 18.2446 61.7661 18.2125 62.2157 18.0513C62.6653 17.8901 63.0228 17.6374 63.2882 17.293C63.5527 16.946 63.7047 16.5224 63.744 16.0221C63.7852 15.5182 63.6933 14.9526 63.4683 14.3253Z"
                              fill="white"
                            />
                            <path
                              d="M67.2641 17.3853L62.623 9.52359L64.278 9.08649L67.4573 14.9248L67.5361 14.904L67.5217 8.22981L69.0274 7.83214L72.3156 13.6462L72.3903 13.6264L72.271 6.97546L73.926 6.53836L73.7721 15.6665L72.254 16.0674L68.9791 10.556L68.9128 10.5736L68.7822 16.9843L67.2641 17.3853Z"
                              fill="white"
                            />
                            <path
                              d="M94.2949 36.8923C90.5935 36.8923 87.5928 39.8929 87.5928 43.5944C87.5928 47.2958 90.5935 50.2965 94.2949 50.2965C97.9964 50.2965 100.997 47.2958 100.997 43.5944C100.997 39.8929 97.9964 36.8923 94.2949 36.8923ZM93.4063 149.413C93.8971 149.903 94.6927 149.903 95.1835 149.413L103.181 141.415C103.671 140.925 103.671 140.129 103.181 139.638C102.69 139.147 101.894 139.147 101.404 139.638L94.2949 146.747L87.1863 139.638C86.6955 139.147 85.8999 139.147 85.4091 139.638C84.9184 140.129 84.9184 140.925 85.4091 141.415L93.4063 149.413ZM93.0383 43.5944L93.0383 148.524L95.5516 148.524L95.5516 43.5944L93.0383 43.5944Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </ScrollLink>
                  </section>
                );
              })}
            </div>
          </main>
          <div id="walk"></div>
          <VideoWalk />
        </Style>
      </>
    </>
  );
};

const Style = styled.div`
  animation: ${moveUp} 0.7s ease forwards;
  min-height: 70vh;
  .header {
    display: flex;
    flex-direction: row;
    .img {
      width: 30%;
      justify-content: center;
      align-item: start;
      img {
        align-item: start;
        justify-content: start;
        margin-top: 2rem;
        margin-right: 5rem;
        width: 40%;
      }
    }
    .heading {
      width: 40%;
      margin-top: -3%;

      h1 {
        text-transform: uppercase;
        font-size: 2.9rem;
        margin-bottom: 0;
        font-weight: 400;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        text-align: center;
        margin-top: 0;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  .header h1 {
    color: black;
    text-align: center;
  }

  .main {
    display: flex;
    padding: 1.65rem;
    margin-top: -1.5rem;
    justify-content: center;
    cursor: pointer;
    .row {
      display: flex;
      flex-wrap: wrap;

      justify-content: space-between;
      margin: 0 -10px;
    }

    .section {
      width: calc(30.333% - 31px); /* Adjust as needed */
      margin: 0 19px; /* Adjust as needed */
      /* Other styles */
    }
  }

  .section {
    margin: 1rem;
    padding: 1rem;
    border-radius: 4px;
    background-color: white;
    text-align: center;
    position: relative;
  }

  .section h2 {
    margin-top: 0;
  }

  .section p {
    margin-bottom: 0;
  }

  .img-container {
    position: relative;
    margin-top: 2rem;
    border-radius: 0.7rem;

    display: flex;
    justify-content: center;
    .title {
      font-size: 2.2rem;
      font-weight: 400;
      text-transform: uppercase;
      font-family: "Bebas Neue", sans-serif;
    }
    .svg-element {
      transition: transform 0.5s;
    }

    .svg-element:hover {
      transform: rotate(360deg);
    }
  }

  .img-container img {
    max-width: 100%;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 15px 0 16px rgba(106, 89, 255, 0.14),
      /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.25),
      /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
  }

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    max-width: 80%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .header {
      padding: 1rem;
    }

    .header h1 {
      color: black;
      text-align: center;
    }

    .main {
      display: flex;
      padding: 1rem;
      justify-content: center;

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -15px;
      }

      .section {
        width: calc(45.333% - 30px); /* Adjust as needed */
        margin: 0 15px; /* Adjust as needed */
        /* Other styles */
      }
    }

    .section {
      margin: 1rem;
      padding: 1rem;
      border-radius: 4px;
      background-color: white;
      text-align: center;
      position: relative;
    }

    .section h2 {
      margin-top: 0;
    }

    .section p {
      margin-bottom: 0;
    }

    .img-container {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .img-container img {
      max-width: 100%;
      height: auto;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: white;
      padding: 1rem;
      border-radius: 8px;
      text-align: center;
      max-width: 80%;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 130vw;

    .header {
      margin-left: 1rem;
      padding: 0rem;
      display: flex;
      flex-direction: column;
      .img {
        width: 30%;
        justify-content: center;
        align-item: start;
        img {
          align-item: start;
          justify-content: start;
          margin-top: 0.5rem;
          margin-left: 2rem;
          width: 40%;
        }
      }
      .heading {
        display: flex;
        align-item: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        h1 {
          text-transform: uppercase;
          font-size: 2rem;
        }
        p {
          display: flex;
          align-item: center;
          justify-content: center;
          font-size: 0.9rem;
        }
      }
    }

    .header h1 {
      color: black;
      text-align: center;
    }

    .main {
      display: flex;
      padding: 0rem;
      margin-top: -1rem;
      margin-right: -1rem;
      justify-content: center;

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -15px;
      }

      .section {
        width: calc(98.333% - 30px); /* Adjust as needed */
        margin: 0 15px; /* Adjust as needed */
        /* Other styles */
      }
    }

    .section {
      margin: 1rem;
      padding: 1rem;
      border-radius: 4px;
      background-color: white;
      text-align: center;
      position: relative;
    }

    .section h2 {
      margin-top: 0;
    }

    .section p {
      margin-bottom: 0;
    }

    .img-container {
      position: relative;
      display: flex;
      justify-content: center;
    }

    .img-container img {
      max-width: 100%;
      height: auto;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: white;
      padding: 1rem;
      border-radius: 8px;
      text-align: center;
      max-width: 80%;
      h2 {
        font-size: 1rem;
      }
    }
  }
`;

export default InteractiveSuite;
