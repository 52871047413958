// NavbarComponent.js

import React from "react";
import styled, { keyframes } from "styled-components";
import NavbarList from "./NavbarList";
import { useSelectedTitleContext } from "./SelectedTitleContext";
import ExperienceFooter from "./ExperienceFooter";
import { fadeOutUp } from "./Animations";
const moveUp = keyframes`
from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const NavbarComponent = () => {
  const { isNavOpen, toggleNav, activeNavItem, handleNavItemChange } =
    useSelectedTitleContext();
  return (
    <>
      {isNavOpen && (
        <>
          <Div>
            <div className={`navbar ${isNavOpen ? "open" : ""}`}>
              {/* Navbar content */}
              <YourComponentWrapper>
                <NavbarList
                  firstItems={[
                    "home",
                    "About",
                    "our solutions",
                  ]}
                  secondItems={[
                    "The Value-Added Suite",
                    "The Journey",
                    "Contact us",
                   
                  ]}
                  activeItem={activeNavItem}
                  onItemClick={handleNavItemChange}
                  color="black"
                />
              </YourComponentWrapper>
            </div>
            <div className="hand-images">
              <AnimatedImageLeft
                src="/hand-left.png"
                alt="hand-left"
                className="hand-left"
              />
              <AnimatedImageRight
                src="/hand-right.png"
                alt="hand-right"
                className="hand-right"
              />
            </div>
          </Div>
          <div className="footerr">
            <ExperienceFooter />
          </div>
        </>
      )}
    </>
  );
};
const YourComponentWrapper = styled.div`
  animation: ${fadeOutUp} 0.9s ease forwards;
  animation-delay: 0.5s;
`;

const moveUpDown = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust the distance as needed */
  }
  100% {
    transform: translateY(0);
  }
`;

// Styled components for images
const AnimatedImageLeft = styled.img`
  animation: ${moveUpDown} 2s infinite; /* Adjust the animation duration as needed */
`;

const AnimatedImageRight = styled.img`
  animation: ${moveUpDown} 2s infinite; /* Adjust the animation duration as needed */
`;

const Div = styled.div`
  min-height: 70vh;
  animation: ${moveUp} 0.7s ease forwards;
  .navbar {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: trans;
    color: white;
    padding: 10px;
    z-index: 1; /* Ensure the navbar appears above other content */
    ul {
      display: flex;
      flex-direction: row;
      width: 60%;
      gap: 60%;
      .first {
        width: 50%;
        li {
          min-width: 20vw;
          max-width: max-content;
          font-size: 1.7rem;
          margin-top: 1rem;
          list-style-type: none;
          text-align: left;
          text-transform: uppercase;
          span {
            
          }
        }
      }
      .sec {
        width: 30vw;
        li {
          margin-top: 1rem;
          width: 20vw;
          text-transform: uppercase;
          text-align: left;
          font-size: 1.7rem;
          margin-bottom: 6%;
          list-style-type: none;
        }
      }
    }
  }

  .navbar.open {
    display: block;
    top: 30%; /* Position the navbar vertically centered */
    transform: translate(-50%, -50%);
  }

  .hand-images {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    align-items: center;
    gap: 70%;
    justify-content: center;
  }

  .hand-left,
  .hand-right {
    top: 0;
    bottom: 0;
    width: 10%;
    height: 45vh;
  }

  .hand-left {
    left: 2rem;
  }
  .img {
    width: 80%;
    margin-top: -5%;
    height: 100vh;
  }
  .hand-right {
    right: 2rem;
  }
  .footerr {
    display: flex;
    justify-content: flex-end;
  }
  @media only screen and (max-width: 767px) {
    max-height: 58vh;
    overflow-y: hidden;
    width: 140vw;
    .navbar {
      display: none;
      position: absolute;
      left: 45%;
      transform: translateX(-50%);
      background-color: trans;
      color: white;
      padding: 10px;
      z-index: 1; /* Ensure the navbar appears above other content */
      ul {
        display: flex;
        flex-direction: row;
        width: 60%;
        gap: 50%;
        .first {
          width: 50%;
          li {
            width: 29vw;
            font-size: 1.7rem;
            margin-top: 0.2rem;
            list-style-type: none;
            text-transform: uppercase;
          }
        }
        .sec {
          width: 30vw;
          li {
            margin-top: 0.2rem;
            width: 25vw;
            text-transform: uppercase;
            font-size: 1.7rem;
            margin-bottom: 0%;
            list-style-type: none;
          }
        }
      }
    }

    .navbar.open {
      display: block;
      top: 40%; /* Position the navbar vertically centered */
      transform: translate(-50%, -50%);
    }

    .hand-images {
      position: relative;
      display: flex;
      width: 95%;
      justify-content: space-between;
      margin-top: 5rem;
      align-items: center;
      gap: 70%;

      justify-content: center;
    }

    .hand-left,
    .hand-right {
      top: 0;
      bottom: 0;
      width: 10%;
      height: 35vh;
    }

    .hand-left {
      left: 2rem;
    }
    .img {
      width: 80%;
      margin-top: -5%;
      height: 70vh;
    }
    .hand-right {
      right: 2rem;
    }
    .footerr {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
export default NavbarComponent;
