// Animations.js

import { keyframes } from "styled-components";

export const fadeOutUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(-20px); /* Adjust as needed */
  }
`;
