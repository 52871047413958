import React, { useState } from "react";
import styled from "styled-components";
import NavbarList from "./NavbarList";
import LogoToggle from "./LogoToggle";
import ExperienceFooter from "./ExperienceFooter";

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleNavItemChange = (item) => {
    setActiveNavItem(item);
  };

  return (
    <Style>
      <LogoToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />
      {isNavOpen && (
        <>
          <div className={`navbar ${isNavOpen ? "open" : ""}`}>
            {/* Navbar content */}
            <NavbarList
              firstItems={[
                "home",
                "About",
                "interactive content",
                "immersive and physical experience",
              ]}
              secondItems={[
                "The Value-Added Suite",
                "The Journey",
                "Contact us",
                "Resources",
              ]}
              activeItem={activeNavItem}
              onItemClick={handleNavItemChange}
            />
          </div>
          <div className="hand-images">
            {activeNavItem === null && (
              <img src="/mask group.png" alt="img" className="img" />
            )}
            {activeNavItem === "home" && (
              <>
                <img
                  src="/hand-left.png"
                  alt="hand-left"
                  className="hand-left"
                />
                <img
                  src="/hand-right.png"
                  alt="hand-right"
                  className="hand-right"
                />
              </>
            )}
            {activeNavItem === "about" && (
              <img src="/aboutback.png" alt="img" className="img" />
            )}
            {[
              "interactive content",
              "immersive and physical experience",
              "Contact us",
              "Resources",
              "The Journey",
              "The Value-Added Suite",
            ].includes(activeNavItem) && (
              <>
                <img
                  src="/hand-left.png"
                  alt="hand-left"
                  className="hand-left"
                />
                <img
                  src="/hand-right.png"
                  alt="hand-right"
                  className="hand-right"
                />
              </>
            )}
          </div>
          <ExperienceFooter />
        </>
      )}
    </Style>
  );
};

const Style = styled.div`
  display: flex;
  flex-direction: column;
  max: 130vh;
  overflow: hidden;

  .experience-home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .navbar {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: trans;
    color: white;
    padding: 10px;
    z-index: 1; /* Ensure the navbar appears above other content */
    ul {
      display: flex;
      flex-direction: row;
      width: 60%;
      gap: 60%;
      .first {
        width: 50%;
        li {
          width: 23vw;
          font-size: 1.7rem;
          margin-top: 1rem;
          list-style-type: none;
          text-transform: uppercase;
        }
      }
      .sec {
        width: 30vw;
        li {
          margin-top: 1rem;
          width: 20vw;
          text-transform: uppercase;
          font-size: 1.7rem;
          list-style-type: none;
        }
      }
    }
  }

  .navbar.open {
    display: block;
    top: 60%; /* Position the navbar vertically centered */
    transform: translate(-50%, -50%);
  }

  .hand-images {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    align-items: center;
    justify-content: center;
  }

  .hand-left,
  .hand-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    height: 45vh;
  }

  .hand-left {
    left: 2rem;
  }
  .img {
    width: 70%;
    margin-top: -5%;
    height: 100vh;
  }
  .hand-right {
    right: 2rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: 80vh;

    .experience-home {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    .navbar {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      padding: 10px;
      z-index: 1; /* Ensure the navbar appears above other content */
      ul {
        display: flex;
        flex-direction: row;
        width: 60%;
        gap: 60%;
        .first {
          width: 50%;
          li {
            width: 23vw;
            font-size: 1.7rem;
            margin-top: 1rem;
            list-style-type: none;
            text-transform: uppercase;
          }
        }
        .sec {
          width: 30vw;
          li {
            margin-top: 1rem;
            width: 20vw;
            text-transform: uppercase;
            font-size: 1.7rem;
            list-style-type: none;
          }
        }
      }
    }

    .navbar.open {
      display: block;
      top: 50%; /* Position the navbar vertically centered */
      transform: translate(-50%, -50%);
    }

    .hand-images {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
    }

    .hand-left,
    .hand-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10%;
      height: 45vh;
    }

    .hand-left {
      left: 2rem;
    }

    .hand-right {
      right: 2rem;
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 40vh;

    .experience-home {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    .navbar {
      display: none;
      position: absolute;
      left: 50%;
      top: 23%;
      transform: translateX(20%);
      background-color: white;
      padding: 1px;
      z-index: 1; /* Ensure the navbar appears above other content */
      ul {
        display: flex;
        flex-direction: row;
        width: 50%;
        gap: 40%;
        .first {
          width: 50%;
          li {
            width: 23vw;
            font-size: 0.7rem;
            margin-top: 1rem;
            list-style-type: none;
            text-transform: uppercase;
          }
        }
        .sec {
          width: 30vw;
          li {
            margin-top: 1rem;
            width: 20vw;
            text-transform: uppercase;
            font-size: 0.7rem;
            list-style-type: none;
          }
        }
      }
    }

    .navbar.open {
      display: block;
      top: 27%; /* Position the navbar vertically centered */
      transform: translate(-50%, -50%);
    }

    .hand-images {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
    }

    .hand-left,
    .hand-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10%;
      height: 15vh;
    }

    .hand-left {
      left: 2rem;
    }

    .hand-right {
      right: 2rem;
    }
  }
`;

export default Navbar;
