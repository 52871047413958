import React from "react";
import ExperienceFooter from "../ExperienceFooter";
// import Navbar from "../homePage/Navbar";
// import Footer from "../homePage/Footer";

const PrivacyPolicy = () => {
  return (
    <div
      className={`w-full min-h-screen p-1 md:p-5 overflow-x-hidden flex flex-col justify-between`}
    >
      {/* <Navbar /> */}
      <div className="w-4/5 m-auto pt-10 pb-10 flex flex-col gap-6">
        <div className="flex justify-center">
          <h1 className="text-[36px] font-bold text-[#6A59FF]">
            Privacy Policy for Propviz Technology Private Limited{" "}
          </h1>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold text-[#6A59FF]">
            Introduction
          </h3>
          <div className="p-5">
            {`Propviz Technology Private Limited ("Propviz," "we," "us," or "our") is committed in its endeavour to protect your personal information and respecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our services.`}
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold text-[#6A59FF]">
            Applicability
          </h3>
          <div className="p-5">
            This Privacy Policy is in compliance with the Information Technology
            Act, 2000, the Information Technology (Reasonable Security Practices
            and Procedures and Sensitive Personal Data or Information) Rules,
            2011 (" <span className="font-bold"> SPDI Rules</span>"). It is
            applicable to all users of our services in India.
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Information We Collect
          </h2>
          <div className="p-5">
            We may collect the following types of information:
            <h3 className="text-[20px] font-semibold pb-3">
              Personal Information:
            </h3>
            <div className="">
              <div>
                <span className="">
                  Name, contact details (email, phone number), address, and any
                  other information you provide during registration or use of
                  our services (“
                  <span className="font-semibold"> Personal Information</span>
                  ”).
                </span>
              </div>
            </div>
          </div>
          <div className="px-5">
            <h3 className="text-[20px] font-semibold pb-3">
              Sensitive Personal Data or Information
            </h3>
            <div className="">
              Passwords, financial information (bank account details,
              credit/debit card details), biometric information, or any other
              information classified as sensitive under applicable laws (“{" "}
              <span className="font-semibold">SPDI</span>”).{" "}
            </div>
          </div>
          <div className="px-5">
            <h3 className="text-[20px] font-semibold pb-3">Usage Data:</h3>
            <div className="">
              Information about how you use our services, including device
              information, IP address, browser type, and access times.
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            How We Use Your Information
          </h2>
          <div className="p-5">
            We use your information to:
            <div>
              <ul className="list-disc">
                <li> Provide and maintain our services.</li>
                <li> Process transactions and send related information.</li>
                <li>
                  Communicate with you about updates, offers, and promotions.
                </li>
                <li> Improve and personalize your experience.</li>
                <li>
                  Comply with legal obligations and enforce our terms and
                  conditions.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Disclosure of Your Information{" "}
          </h2>
          <div className="px-5">We may disclose your information to:</div>
          <div className="px-5">
            <ul className="list-disc">
              <li>
                <span className=" font-semibold">Service Providers:</span>
                Third-party vendors who assist us in providing our services.
              </li>
              <li>
                <span className=" font-semibold"> Legal Obligations: </span> Law
                enforcement or governmental agencies as required by law.
              </li>
              <li>
                <span className=" font-semibold"> Business Transfers </span> In
                the event of a merger, acquisition, or sale of assets.
              </li>
            </ul>
          </div>
          <div className="px-5 py-2">
            We may disclose and/or transfer your Personal Information to third
            parties in cases it is necessary for providing services to you
            and/or if you have expressly consented for the same. We ensure that
            any third parties with whom we share your information maintain
            reasonable security practices as required under applicable law,
            including but not limited to the SPDI Rules.
          </div>
          <div className="px-5 py-2">
            We may share your Personal Information without obtaining your prior
            written consent, with government agencies mandated under the law to
            obtain information for the purpose of verification of identity or
            for prevention, detection, investigation or where disclosure is
            necessary for compliance of a legal obligation.
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Data Security
          </h2>
          <div className="p-5">
            We implement appropriate security measures to protect your
            information against unauthorized access, alteration, disclosure, or
            destruction. We follow the "Reasonable Security Practices and
            Procedures" as prescribed under the SPDI Rules.
          </div>
        </div>

        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Your Rights{" "}
          </h2>
          <div className="p-5">
            <div>
              All Personal Information or SPDI provided to us by you have been
              voluntarily provided to us.
            </div>
            <div>
              <div>Under applicable laws, you have the right to:</div>
              <ul className="list-disc pt-2">
                <li>Access, update, and correct your personal information.</li>
                <li>
                  Withdraw consent for the collection and processing of your
                  information. However, please note that withdrawal of consent
                  will not be retrospective in nature and shall be applicable
                  prospectively.
                </li>
                <li>Request the deletion of your personal data.</li>
                <li>Opt-out of marketing communications.</li>
              </ul>
              <div className="py-2">
                To exercise these rights, please contact us at hi@convrse.ai
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Cookies and Tracking Technologies{" "}
          </h2>
          <div className="p-5">
            We may use cookies and similar tracking technologies to collect
            usage data and enhance your experience. By using the our website,
            you agree that these types of cookies can be placed on your device.
            You can manage your cookie preferences through your browser
            settings. We are not responsible for cookies placed in your device
            by any other website and information collected thereto.
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Third-Party Links
          </h2>
          <div className="p-5">
            Our services may contain links to third-party websites or services.
            We are not responsible for the privacy practices of such third
            parties. We encourage you to review their privacy policies.
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Changes to This Privacy Policy
          </h2>
          <div className="p-5">
            We may update this Privacy Policy from time to time to reflect
            changes in legal requirements or our practices. In case, any
            significant change is made, We will notify you by posting the new
            policy on this page with an updated effective date.
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Grievance Officer{" "}
          </h2>
          <div className="p-5">
            We have appointed a Grievance Officer to address any concerns or
            complaints. If you have any questions or grievances regarding this
            Privacy Policy, please contact:{" "}
            <div>
              <span className=" font-semibold">Name:</span> Anshul Padyal
            </div>
            <div>
              <span className=" font-semibold">Email:</span> anshul@convrse.ai
            </div>
            <div>
              <span className=" font-semibold">Address:</span> Gurgaon
            </div>
            <div>
              <span className=" font-semibold">Phone:</span>
              +91 7988256651
            </div>
          </div>
        </div>
        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">
            Contact Us
          </h2>
          <div className="p-5">
            If you have any questions about this Privacy Policy or our data
            practices, please contact us at:
            <div>
              <span className=" font-semibold">Email:</span> hi@convrse.ai
            </div>
            <div>
              <span className=" font-semibold">Address:</span> Gurgaon
            </div>
            <div>
              <span className=" font-semibold">Phone:</span>
              +91 8473994820
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-[28px] font-semibold text-[#6A59FF]">Consent </h2>
          <div className="p-5">
            By using our services, you consent to the collection, use, and
            disclosure of your information as described in this Privacy Policy.
          </div>
        </div>
      </div>
      <ExperienceFooter />
      {/* <Footer /> */}
    </div>
  );
};

export default PrivacyPolicy;
