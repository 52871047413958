// Industry.js
import React, { useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
const flipOutYAnimation = keyframes`
  50% { transform: none; opacity: 1; }
  100% { transform: none; opacity: 1; }
`;

// Styled component for the image with animation
const AnimatedImage = styled.div`
  opacity: ${(props) => (props.isVisible ? "1" : "0")}; // Initially hidden
  animation: ${(props) => (props.isVisible ? flipOutYAnimation : "none")} 2s
    linear forwards;
  transform: rotateX(50deg);
  transition: opacity 0.5s ease-in-out;
`;

const Solutions = () => {
  const [isVisible, setIsVisible] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio >= 0.7) {
            setIsVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.7 }
    ); // Set threshold to 0.5 (50% visible)

    observer.observe(imageRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Style>
      <AnimatedImage isVisible={isVisible} ref={imageRef} className="main">
        <div className="image">
          <img className="img" src="/soluhand.webp" alt="mobilehand" />
        </div>
        <div className="overview">
          <div>
            <h2>How can we help you with our solutions?</h2>
          </div>
          <div className="learnmore">
            <div>
              <svg
                width="59"
                height="24"
                viewBox="0 0 89 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                  fill="#1C1C1C"
                />
              </svg>
            </div>
            <div>
              <p><a href="/our-solutions">learn more </a> </p>
            </div>
          </div>
        </div>
      </AnimatedImage>
    </Style>
  );
};
const Style = styled.div`

  scroll-behavior: smooth;
  margin-top: 1%;
  overflow: hidden;
  .main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image {
    position: relative;
  }

  .image .img {
    width: 100%;
    height: auto;
    margin-left: 0.8%;
  }

  .overview {
    position: absolute;
    width: 45%;
    height: 60%;
    top: 27%;
    left: 9%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .overview h2 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-family: "Bebas Neue", sans-serif;
  }

  .learnmore {
    display: flex;
    cursor: pointer;
    gap: 2%;
    font-weight: 600;
    margin-bottom: -0%;
    align-items: center;
    p {
      font-weight: 590;
      font-family: "Poppins", sans-serif;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .main {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image {
      position: relative;
      margin-right: 20px;
    }

    .image .img {
      width: 100%;
      height: auto;
    }

    .overview {
      position: absolute;
      width: 45%;
      top: 50%;
      left: 32%;
      transform: translate(-50%, -50%);
      h2 {
        text-transform: uppercase;
        font-size: 2.8rem;
      }
    }

    .learnmore {
      display: flex;
      gap: 2%;
      margin-bottom: -3%;
      align-items: center;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 138vw;
    .main {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .image {
      position: relative;
      margin-right: 20px;
    }

    .image .img {
      width: 100%;
      height: auto;
    }

    .overview {
      position: absolute;
      width: 45%;
      top: 50%;
      left: 32%;
      transform: translate(-50%, -50%);
      h2 {
        text-transform: uppercase;
        font-size: 1.6rem;
      }
    }

    .learnmore {
      display: flex;
      gap: 2%;
      margin-bottom: 3%;
      align-items: center;
      P{
        font-size: 0.6rem;
      }
    }
  }
`;
export default Solutions;
