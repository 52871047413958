import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import "./Offers.css";
import Logo from "./Logo";

const Offerings = () => {
  const [isVisible, setIsVisible] = useState(false);

  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.7 } // Change the threshold as needed
    );

    observer.observe(targetRef.current);

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <Style>
      <div className="main">
        <div className="heading">
          <h2>Why are we the best?</h2>
          <p>Because we are uncompromisingly passionate!</p>
        </div>
        <div className="table">
          <table>
            <thead>
              <tr>
                <th className="big" id="big">
                  Offerings
                </th>
                <th className="big">Others</th>
                <th className="thh">
                  {/* <img src="/spaceslogo.png" alt="logo" /> */}
                  <Logo />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="td">
                  Unlimited Hotspots for VR Walkthrough Videos
                </td>
                <td>
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7035 12.5034L24.5236 2.683C24.6726 2.53905 24.7915 2.36685 24.8733 2.17646C24.9551 1.98607 24.9981 1.7813 24.9999 1.57409C25.0017 1.36688 24.9623 1.16139 24.8838 0.96961C24.8053 0.777827 24.6894 0.603591 24.5429 0.457068C24.3964 0.310546 24.2222 0.194672 24.0304 0.116207C23.8386 0.0377421 23.6331 -0.00174164 23.4259 5.8921e-05C23.2187 0.00185949 23.0139 0.0449087 22.8235 0.126695C22.6331 0.20848 22.4609 0.327365 22.317 0.476412L12.4965 10.2964L2.67639 0.476412C2.38266 0.189138 1.98749 0.0292867 1.57664 0.0315493C1.16579 0.0338119 0.77241 0.198005 0.481865 0.488497C0.19132 0.778988 0.027056 1.17234 0.0247208 1.58319C0.0223855 1.99403 0.182168 2.38922 0.469392 2.683L10.2899 12.5034L0.469392 22.3238C0.321831 22.4681 0.204367 22.6403 0.123799 22.8303C0.0432308 23.0204 0.00116035 23.2245 2.36663e-05 23.4309C-0.00111302 23.6373 0.0387067 23.8418 0.117177 24.0327C0.195647 24.2237 0.311208 24.3971 0.457169 24.543C0.603131 24.689 0.776594 24.8045 0.967512 24.8829C1.15843 24.9614 1.36301 25.0012 1.56941 25C1.77581 24.9988 1.97993 24.9567 2.16994 24.8761C2.35996 24.7955 2.53209 24.678 2.67639 24.5304L12.4965 14.7104L22.317 24.5304C22.6217 24.8351 23.0212 24.9876 23.4203 24.9876C23.8194 24.9876 24.2193 24.8351 24.5236 24.5304C24.8161 24.2378 24.9805 23.8409 24.9805 23.4271C24.9805 23.0133 24.8161 22.6165 24.5236 22.3238L14.7035 12.5034Z"
                      fill="#B3B3B3"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    className={`box ${isVisible ? "visible" : ""}`}
                    ref={targetRef}
                    width="18"
                    height="30"
                    viewBox="0 0 35 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4433 29.1582C12.0381 29.6173 11.4641 30 10.9576 30C10.451 30 9.87699 29.5982 9.4549 29.139L0 18.4247L3.00531 15.0191L10.9744 24.0497L32.0453 0L35 3.46301L12.4433 29.1582Z"
                      fill="#6A59FF"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="td">Free Technical Support</td>
                <td>
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7035 12.5034L24.5236 2.683C24.6726 2.53905 24.7915 2.36685 24.8733 2.17646C24.9551 1.98607 24.9981 1.7813 24.9999 1.57409C25.0017 1.36688 24.9623 1.16139 24.8838 0.96961C24.8053 0.777827 24.6894 0.603591 24.5429 0.457068C24.3964 0.310546 24.2222 0.194672 24.0304 0.116207C23.8386 0.0377421 23.6331 -0.00174164 23.4259 5.8921e-05C23.2187 0.00185949 23.0139 0.0449087 22.8235 0.126695C22.6331 0.20848 22.4609 0.327365 22.317 0.476412L12.4965 10.2964L2.67639 0.476412C2.38266 0.189138 1.98749 0.0292867 1.57664 0.0315493C1.16579 0.0338119 0.77241 0.198005 0.481865 0.488497C0.19132 0.778988 0.027056 1.17234 0.0247208 1.58319C0.0223855 1.99403 0.182168 2.38922 0.469392 2.683L10.2899 12.5034L0.469392 22.3238C0.321831 22.4681 0.204367 22.6403 0.123799 22.8303C0.0432308 23.0204 0.00116035 23.2245 2.36663e-05 23.4309C-0.00111302 23.6373 0.0387067 23.8418 0.117177 24.0327C0.195647 24.2237 0.311208 24.3971 0.457169 24.543C0.603131 24.689 0.776594 24.8045 0.967512 24.8829C1.15843 24.9614 1.36301 25.0012 1.56941 25C1.77581 24.9988 1.97993 24.9567 2.16994 24.8761C2.35996 24.7955 2.53209 24.678 2.67639 24.5304L12.4965 14.7104L22.317 24.5304C22.6217 24.8351 23.0212 24.9876 23.4203 24.9876C23.8194 24.9876 24.2193 24.8351 24.5236 24.5304C24.8161 24.2378 24.9805 23.8409 24.9805 23.4271C24.9805 23.0133 24.8161 22.6165 24.5236 22.3238L14.7035 12.5034Z"
                      fill="#B3B3B3"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    className={`box ${isVisible ? "visible" : ""}`}
                    ref={targetRef}
                    width="18"
                    height="30"
                    viewBox="0 0 35 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4433 29.1582C12.0381 29.6173 11.4641 30 10.9576 30C10.451 30 9.87699 29.5982 9.4549 29.139L0 18.4247L3.00531 15.0191L10.9744 24.0497L32.0453 0L35 3.46301L12.4433 29.1582Z"
                      fill="#6A59FF"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="td">Guaranteed 20 Days Turn Around Time</td>
                <td>
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7035 12.5034L24.5236 2.683C24.6726 2.53905 24.7915 2.36685 24.8733 2.17646C24.9551 1.98607 24.9981 1.7813 24.9999 1.57409C25.0017 1.36688 24.9623 1.16139 24.8838 0.96961C24.8053 0.777827 24.6894 0.603591 24.5429 0.457068C24.3964 0.310546 24.2222 0.194672 24.0304 0.116207C23.8386 0.0377421 23.6331 -0.00174164 23.4259 5.8921e-05C23.2187 0.00185949 23.0139 0.0449087 22.8235 0.126695C22.6331 0.20848 22.4609 0.327365 22.317 0.476412L12.4965 10.2964L2.67639 0.476412C2.38266 0.189138 1.98749 0.0292867 1.57664 0.0315493C1.16579 0.0338119 0.77241 0.198005 0.481865 0.488497C0.19132 0.778988 0.027056 1.17234 0.0247208 1.58319C0.0223855 1.99403 0.182168 2.38922 0.469392 2.683L10.2899 12.5034L0.469392 22.3238C0.321831 22.4681 0.204367 22.6403 0.123799 22.8303C0.0432308 23.0204 0.00116035 23.2245 2.36663e-05 23.4309C-0.00111302 23.6373 0.0387067 23.8418 0.117177 24.0327C0.195647 24.2237 0.311208 24.3971 0.457169 24.543C0.603131 24.689 0.776594 24.8045 0.967512 24.8829C1.15843 24.9614 1.36301 25.0012 1.56941 25C1.77581 24.9988 1.97993 24.9567 2.16994 24.8761C2.35996 24.7955 2.53209 24.678 2.67639 24.5304L12.4965 14.7104L22.317 24.5304C22.6217 24.8351 23.0212 24.9876 23.4203 24.9876C23.8194 24.9876 24.2193 24.8351 24.5236 24.5304C24.8161 24.2378 24.9805 23.8409 24.9805 23.4271C24.9805 23.0133 24.8161 22.6165 24.5236 22.3238L14.7035 12.5034Z"
                      fill="#B3B3B3"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    className={`box ${isVisible ? "visible" : ""}`}
                    ref={targetRef}
                    width="18"
                    height="30"
                    viewBox="0 0 35 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4433 29.1582C12.0381 29.6173 11.4641 30 10.9576 30C10.451 30 9.87699 29.5982 9.4549 29.139L0 18.4247L3.00531 15.0191L10.9744 24.0497L32.0453 0L35 3.46301L12.4433 29.1582Z"
                      fill="#6A59FF"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="td">Video Conferencing enabled Walkthroughs</td>
                <td>
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7035 12.5034L24.5236 2.683C24.6726 2.53905 24.7915 2.36685 24.8733 2.17646C24.9551 1.98607 24.9981 1.7813 24.9999 1.57409C25.0017 1.36688 24.9623 1.16139 24.8838 0.96961C24.8053 0.777827 24.6894 0.603591 24.5429 0.457068C24.3964 0.310546 24.2222 0.194672 24.0304 0.116207C23.8386 0.0377421 23.6331 -0.00174164 23.4259 5.8921e-05C23.2187 0.00185949 23.0139 0.0449087 22.8235 0.126695C22.6331 0.20848 22.4609 0.327365 22.317 0.476412L12.4965 10.2964L2.67639 0.476412C2.38266 0.189138 1.98749 0.0292867 1.57664 0.0315493C1.16579 0.0338119 0.77241 0.198005 0.481865 0.488497C0.19132 0.778988 0.027056 1.17234 0.0247208 1.58319C0.0223855 1.99403 0.182168 2.38922 0.469392 2.683L10.2899 12.5034L0.469392 22.3238C0.321831 22.4681 0.204367 22.6403 0.123799 22.8303C0.0432308 23.0204 0.00116035 23.2245 2.36663e-05 23.4309C-0.00111302 23.6373 0.0387067 23.8418 0.117177 24.0327C0.195647 24.2237 0.311208 24.3971 0.457169 24.543C0.603131 24.689 0.776594 24.8045 0.967512 24.8829C1.15843 24.9614 1.36301 25.0012 1.56941 25C1.77581 24.9988 1.97993 24.9567 2.16994 24.8761C2.35996 24.7955 2.53209 24.678 2.67639 24.5304L12.4965 14.7104L22.317 24.5304C22.6217 24.8351 23.0212 24.9876 23.4203 24.9876C23.8194 24.9876 24.2193 24.8351 24.5236 24.5304C24.8161 24.2378 24.9805 23.8409 24.9805 23.4271C24.9805 23.0133 24.8161 22.6165 24.5236 22.3238L14.7035 12.5034Z"
                      fill="#B3B3B3"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    className={`box ${isVisible ? "visible" : ""}`}
                    ref={targetRef}
                    width="18"
                    height="30"
                    viewBox="0 0 35 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4433 29.1582C12.0381 29.6173 11.4641 30 10.9576 30C10.451 30 9.87699 29.5982 9.4549 29.139L0 18.4247L3.00531 15.0191L10.9744 24.0497L32.0453 0L35 3.46301L12.4433 29.1582Z"
                      fill="#6A59FF"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="td">Dedicated Service Teams</td>
                <td>
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7035 12.5034L24.5236 2.683C24.6726 2.53905 24.7915 2.36685 24.8733 2.17646C24.9551 1.98607 24.9981 1.7813 24.9999 1.57409C25.0017 1.36688 24.9623 1.16139 24.8838 0.96961C24.8053 0.777827 24.6894 0.603591 24.5429 0.457068C24.3964 0.310546 24.2222 0.194672 24.0304 0.116207C23.8386 0.0377421 23.6331 -0.00174164 23.4259 5.8921e-05C23.2187 0.00185949 23.0139 0.0449087 22.8235 0.126695C22.6331 0.20848 22.4609 0.327365 22.317 0.476412L12.4965 10.2964L2.67639 0.476412C2.38266 0.189138 1.98749 0.0292867 1.57664 0.0315493C1.16579 0.0338119 0.77241 0.198005 0.481865 0.488497C0.19132 0.778988 0.027056 1.17234 0.0247208 1.58319C0.0223855 1.99403 0.182168 2.38922 0.469392 2.683L10.2899 12.5034L0.469392 22.3238C0.321831 22.4681 0.204367 22.6403 0.123799 22.8303C0.0432308 23.0204 0.00116035 23.2245 2.36663e-05 23.4309C-0.00111302 23.6373 0.0387067 23.8418 0.117177 24.0327C0.195647 24.2237 0.311208 24.3971 0.457169 24.543C0.603131 24.689 0.776594 24.8045 0.967512 24.8829C1.15843 24.9614 1.36301 25.0012 1.56941 25C1.77581 24.9988 1.97993 24.9567 2.16994 24.8761C2.35996 24.7955 2.53209 24.678 2.67639 24.5304L12.4965 14.7104L22.317 24.5304C22.6217 24.8351 23.0212 24.9876 23.4203 24.9876C23.8194 24.9876 24.2193 24.8351 24.5236 24.5304C24.8161 24.2378 24.9805 23.8409 24.9805 23.4271C24.9805 23.0133 24.8161 22.6165 24.5236 22.3238L14.7035 12.5034Z"
                      fill="#B3B3B3"
                    />
                  </svg>
                </td>
                <td>
                  <svg
                    className={`box ${isVisible ? "visible" : ""}`}
                    ref={targetRef}
                    width="18"
                    height="30"
                    viewBox="0 0 35 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4433 29.1582C12.0381 29.6173 11.4641 30 10.9576 30C10.451 30 9.87699 29.5982 9.4549 29.139L0 18.4247L3.00531 15.0191L10.9744 24.0497L32.0453 0L35 3.46301L12.4433 29.1582Z"
                      fill="#6A59FF"
                    />
                  </svg>
                </td>
              </tr>
              <tr>
                <td className="td" id="td">
                  One-Stop Solution [From 3D Renders to Metaverse]
                </td>
                <td>
                  <svg
                    width="20"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.7035 12.5034L24.5236 2.683C24.6726 2.53905 24.7915 2.36685 24.8733 2.17646C24.9551 1.98607 24.9981 1.7813 24.9999 1.57409C25.0017 1.36688 24.9623 1.16139 24.8838 0.96961C24.8053 0.777827 24.6894 0.603591 24.5429 0.457068C24.3964 0.310546 24.2222 0.194672 24.0304 0.116207C23.8386 0.0377421 23.6331 -0.00174164 23.4259 5.8921e-05C23.2187 0.00185949 23.0139 0.0449087 22.8235 0.126695C22.6331 0.20848 22.4609 0.327365 22.317 0.476412L12.4965 10.2964L2.67639 0.476412C2.38266 0.189138 1.98749 0.0292867 1.57664 0.0315493C1.16579 0.0338119 0.77241 0.198005 0.481865 0.488497C0.19132 0.778988 0.027056 1.17234 0.0247208 1.58319C0.0223855 1.99403 0.182168 2.38922 0.469392 2.683L10.2899 12.5034L0.469392 22.3238C0.321831 22.4681 0.204367 22.6403 0.123799 22.8303C0.0432308 23.0204 0.00116035 23.2245 2.36663e-05 23.4309C-0.00111302 23.6373 0.0387067 23.8418 0.117177 24.0327C0.195647 24.2237 0.311208 24.3971 0.457169 24.543C0.603131 24.689 0.776594 24.8045 0.967512 24.8829C1.15843 24.9614 1.36301 25.0012 1.56941 25C1.77581 24.9988 1.97993 24.9567 2.16994 24.8761C2.35996 24.7955 2.53209 24.678 2.67639 24.5304L12.4965 14.7104L22.317 24.5304C22.6217 24.8351 23.0212 24.9876 23.4203 24.9876C23.8194 24.9876 24.2193 24.8351 24.5236 24.5304C24.8161 24.2378 24.9805 23.8409 24.9805 23.4271C24.9805 23.0133 24.8161 22.6165 24.5236 22.3238L14.7035 12.5034Z"
                      fill="#B3B3B3"
                    />
                  </svg>
                </td>
                <td className="svgtd">
                  <svg
                    className={`box ${isVisible ? "visible" : ""}`}
                    ref={targetRef}
                    width="18"
                    height="30"
                    viewBox="0 0 35 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4433 29.1582C12.0381 29.6173 11.4641 30 10.9576 30C10.451 30 9.87699 29.5982 9.4549 29.139L0 18.4247L3.00531 15.0191L10.9744 24.0497L32.0453 0L35 3.46301L12.4433 29.1582Z"
                      fill="#6A59FF"
                    />
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Style>
  );
};
const Style = styled.div`
  width: 98.5;
  height: 99vh;
  overflow: hidden;
  display: flex;
  padding:0.8rem;
  scroll-behavior: smooth;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .main {
    width: 97%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .heading {
      width: 90%;
      text-align: center;
      margin-bottom: 1.5rem;

      h2 {
        text-transform: uppercase;
        font-size: 2.6rem;
        font-weight: 400;
        font-family: "Bebas Neue", sans-serif;
        margin-bottom: 0;
      }
      p {
        margin-top: 0;
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif;
      }
    }
    .table {
      border-radius: 2rem;
      box-shadow: 6px 10px 20.3px 2px rgba(0, 0, 0, 0.06);


      background-color: rgba(248, 248, 248, 1);
      width: 90%;

      thead {
        overflow: hidden;
        object-fit: cover;
        background-color: rgba(248, 248, 248, 1);
        height: 11vh;

        .big {
          border-radius: 2.1rem;
          font-size: 2rem;
          text-transform: uppercase;
          font-weight: 400;
          font-family: "Bebas Neue", sans-serif;
        }
        #big {
          text-align: start;
          padding-left: 1.8rem;
        }
        .thh {
          border-top-right-radius: 2rem;
          img {
            width: 35%;
            background-color: transparent;
          }
        }
      }
      tbody {
        tr {
          .td {
            text-align: start;
            width: 40rem;
            padding-left: 1.8rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: "Poppins", sans-serif;
          }
          #td {
            border-bottom-left-radius: 2rem;
          }
          .svgtd {
            border-bottom-right-radius: 2rem;
          }
          td {
            width: content-fit;
            height: 9vh;
            font-family: "Poppins", sans-serif;
            width: 35%;
            background-color: white;
            font-weight: 440;
            text-align: center;
            svg {
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5;
    height: 75vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .main {
      width: 94%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      .heading {
        width: 90%;
        text-align: start;
        h2 {
          text-transform: uppercase;
          font-size: 2rem;
        }
      }
      .table {
        border-radius: 0.5rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        background-color: rgba(248, 248, 248, 1);
        width: 90%;

        thead {
          overflow: hidden;
          object-fit: cover;
          background-color: rgba(248, 248, 248, 1);

          .big {
            font-size: 2rem;
            text-transform: uppercase;
          }
        }
        tbody {
          tr {
            .td {
              text-align: start;
            }
            td {
              width: 30%;
              height: 6vh;
              background-color: white;
              font-weight: bold;
              text-align: center;
              svg {
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 128vw;
    height: 80vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .main {
      width: 97%;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      .heading {
        width: 90%;
        text-align: start;
        h2 {
          text-transform: uppercase;
          font-size: 2.2rem;
        }
      }
      .table {
        border-radius: 0.5rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        background-color: rgba(248, 248, 248, 1);
        width: 97%;

        thead {
          overflow: hidden;
          object-fit: cover;
          background-color: rgba(248, 248, 248, 1);

          .big {
            font-size: 2rem;
            text-transform: uppercase;
          }
        }
        tbody {
          tr {
            .td {
              text-align: start;
              white-space: wrap;
              text-overflow: ellipsis;
            }
            td {
              width: 26%;
              height: 6vh;
              background-color: white;
              font-weight: bold;
              text-align: center;
              svg {
              }
            }
          }
        }
      }
    }
  }
`;

export default Offerings;
