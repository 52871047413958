import React from "react";
import styled, { keyframes } from "styled-components";
import ExperienceFooter from "../ExperienceFooter";
import LogoToggle from "../LogoToggle";
import Navbar from "../Navbar";
import { Link as ScrollLink } from "react-scroll";
import NavbarComponent from "../NavbarComponent";
import { useSelectedTitleContext } from "../SelectedTitleContext";
import GetInTouchButton from "../GetInTouchButton";

const moveUp = keyframes`
from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const Journey = () => {
  const { isNavOpen, toggleNav, hostName } = useSelectedTitleContext();
  return (
    <>
      <LogoToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />
      {isNavOpen && <NavbarComponent />}
      {!isNavOpen && (
        <>
          <Style>
            <div className="heading">
              <div className="header">
                {" "}
                <h2>THE JOURNEY OF THE CUSTOMER</h2>
                <p>AT A {hostName} PROJECT</p>
              </div>
            </div>
            <div id="walks" className="container">
              <div className="har">
                <h2>
                  From the first contact to the final possession & beyond, the 3
                  Flagship Suites -
                </h2>
              </div>
              <div className="hr">
                <p>
                  The Visualisation Suite, The Meta Real Estate Suite & The
                  Value-Added Service Suite - can be optimally harnessed to
                  deliver a one-of-kind home buying experience to the customer.
                </p>
              </div>
              <div className="data">
                <div className="first">
                  <p className="svvg">
                    <svg
                      width="50"
                      height="104"
                      viewBox="0 0 80 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_173_3075)">
                        <path
                          d="M40 0C20 0 4 16 4 36C4 56 20 72 40 96C60 72 76 56 76 36C76 16 60 0 40 0ZM40 24C46.6394 24 52 29.3606 52 36C52 42.6394 46.6394 48 40 48C33.3606 48 28 42.6394 28 36C28 29.3606 33.3606 24 40 24Z"
                          fill="#1C1C1C"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_173_3075"
                          x="0"
                          y="0"
                          width="80"
                          height="104"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_173_3075"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_173_3075"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </p>
                  <h2 className="head">Lead Generation</h2>
                </div>
                <div className="sec">
                  <p className="para">
                    Gone are the days of sustained attention spans, especially
                    when it’s about sales. The collaterals have to be engaging
                    and on your fingertip in a market of cluttered offerings
                  </p>
                  <p>Stand out, stand tall!</p>
                  <a href="/our-solutions" target="_blank">
                    {" "}
                    <h2> The Bird Eye | The VR & Video Walkthrough</h2>{" "}
                  </a>
                </div>

                <div className="third">
                  <h2 className="headd"></h2>
                  <p className="svvgg">
                    <svg
                      width="92"
                      height="24"
                      viewBox="0 0 113 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M112.061 13.0607C112.646 12.4749 112.646 11.5251 112.061 10.9393L102.515 1.3934C101.929 0.807611 100.979 0.807611 100.393 1.3934C99.8076 1.97919 99.8076 2.92893 100.393 3.51472L108.879 12L100.393 20.4853C99.8076 21.0711 99.8076 22.0208 100.393 22.6066C100.979 23.1924 101.929 23.1924 102.515 22.6066L112.061 13.0607ZM0 13.5H111V10.5H0V13.5Z"
                        fill="black"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="dat">
                <div className="first">
                  <p className="svvg">
                    <svg
                      width="50"
                      height="104"
                      viewBox="0 0 80 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_173_3075)">
                        <path
                          d="M40 0C20 0 4 16 4 36C4 56 20 72 40 96C60 72 76 56 76 36C76 16 60 0 40 0ZM40 24C46.6394 24 52 29.3606 52 36C52 42.6394 46.6394 48 40 48C33.3606 48 28 42.6394 28 36C28 29.3606 33.3606 24 40 24Z"
                          fill="#1C1C1C"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_173_3075"
                          x="0"
                          y="0"
                          width="80"
                          height="104"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_173_3075"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_173_3075"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </p>
                  <h2 className="head">Site Visit</h2>
                </div>
                <div className="sec">
                  <p className="para">
                    <ul>
                      <li>
                        Now the customer is impressed. They want to see more.
                        Are you going to show them the same old collaterals?
                        Same old videos? 
                      </li>
                      <li>
                        If they want to physically visit the sales office, the
                        setup is ready for them. If they want to explore
                        remotely, just a link does the trick, and quite well.
                      </li>
                    </ul>
                    <a href="/our-solutions" target="_blank">
                      {" "}
                      <h2>
                        The Meta Site Office | The Meta Project | The Meta City
                      </h2>{" "}
                    </a>
                  </p>
                </div>

                <div className="third">
                  <h2 className="headd"></h2>
                  <p className="svvgg">
                    <svg
                      width="92"
                      height="24"
                      viewBox="0 0 113 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M112.061 13.0607C112.646 12.4749 112.646 11.5251 112.061 10.9393L102.515 1.3934C101.929 0.807611 100.979 0.807611 100.393 1.3934C99.8076 1.97919 99.8076 2.92893 100.393 3.51472L108.879 12L100.393 20.4853C99.8076 21.0711 99.8076 22.0208 100.393 22.6066C100.979 23.1924 101.929 23.1924 102.515 22.6066L112.061 13.0607ZM0 13.5H111V10.5H0V13.5Z"
                        fill="black"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="dataaa">
                <div className="first">
                  <p className="svvg">
                    <svg
                      width="50"
                      height="104"
                      viewBox="0 0 80 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_173_3075)">
                        <path
                          d="M40 0C20 0 4 16 4 36C4 56 20 72 40 96C60 72 76 56 76 36C76 16 60 0 40 0ZM40 24C46.6394 24 52 29.3606 52 36C52 42.6394 46.6394 48 40 48C33.3606 48 28 42.6394 28 36C28 29.3606 33.3606 24 40 24Z"
                          fill="#1C1C1C"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_173_3075"
                          x="0"
                          y="0"
                          width="80"
                          height="104"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_173_3075"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_173_3075"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </p>
                  <h2 className="head">Sustenance</h2>
                </div>
                <div className="sec">
                  <p className="para">
                    <p>
                      Handovers. Community Events. Project Updates. Festive
                      Celebrations. Grievance Management. 
                    </p>
                    <p>
                      Etcetera Etcetera Etcetera. The possibilities are
                      endless. 
                    </p>
                  </p>
                  <a href="/the-value-added-suite" target="_blank">
                    {" "}
                    <h2>
                      The Meta Real Estate Suite | The Value-Added Service Suite
                    </h2>{" "}
                  </a>
                </div>

                <div className="third">
                  <h2 className="headd"></h2>
                  <p className="svvgg">
                    <svg
                      width="92"
                      height="24"
                      viewBox="0 0 113 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M112.061 13.0607C112.646 12.4749 112.646 11.5251 112.061 10.9393L102.515 1.3934C101.929 0.807611 100.979 0.807611 100.393 1.3934C99.8076 1.97919 99.8076 2.92893 100.393 3.51472L108.879 12L100.393 20.4853C99.8076 21.0711 99.8076 22.0208 100.393 22.6066C100.979 23.1924 101.929 23.1924 102.515 22.6066L112.061 13.0607ZM0 13.5H111V10.5H0V13.5Z"
                        fill="black"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="dataa">
                <div className="first">
                  <p className="svvg">
                    <svg
                      width="50"
                      height="104"
                      viewBox="0 0 80 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_173_3075)">
                        <path
                          d="M40 0C20 0 4 16 4 36C4 56 20 72 40 96C60 72 76 56 76 36C76 16 60 0 40 0ZM40 24C46.6394 24 52 29.3606 52 36C52 42.6394 46.6394 48 40 48C33.3606 48 28 42.6394 28 36C28 29.3606 33.3606 24 40 24Z"
                          fill="#1C1C1C"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_173_3075"
                          x="0"
                          y="0"
                          width="80"
                          height="104"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_173_3075"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_173_3075"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>
                  </p>
                  <h2 className="head">Booking</h2>
                </div>
                <div className="sec">
                  <p className="para">
                    <h1>Pre</h1>
                    Now when they like what they see, they can select their next
                    home then & there.    <h1>Post</h1>
                    Updates about the documentation process & payment cycle,
                    including, but not limited to money trenches can be tracked
                    & communicated to them. {" "}
                  </p>
                  <a href="/the-value-added-suite" target="_blank">
                    {" "}
                    <h2>
                      {" "}
                      The Flat Selector + The Meta Real Estate Suite | The
                      Value-Added Service Suite
                    </h2>{" "}
                  </a>
                </div>

                <div className="third">
                  <h2 className="headd"></h2>
                  <p className="svvgg">
                    <svg
                      width="92"
                      height="24"
                      viewBox="0 0 113 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M112.061 13.0607C112.646 12.4749 112.646 11.5251 112.061 10.9393L102.515 1.3934C101.929 0.807611 100.979 0.807611 100.393 1.3934C99.8076 1.97919 99.8076 2.92893 100.393 3.51472L108.879 12L100.393 20.4853C99.8076 21.0711 99.8076 22.0208 100.393 22.6066C100.979 23.1924 101.929 23.1924 102.515 22.6066L112.061 13.0607ZM0 13.5H111V10.5H0V13.5Z"
                        fill="black"
                      />
                    </svg>
                  </p>
                </div>
              </div>
              <div className="back">
                {/*   <svg
                width="100%"
                height="668"
                viewBox="0 0 1555 668"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_di_167_2236)">
                  <path
                    d="M4 42H1245.5C1393.9 42 1448 112 1448 187.5C1448 296.5 1345 336.5 1287 336.5H241C130.6 336.5 103 431.5 103 479C103 575.4 192.5 618 241 618H1273H1550.5"
                    stroke="#4C4C4C"
                    strokeWidth="84"
                  />
                </g>
                <path
                  d="M4 49H1245.5C1393.9 49 1448 119 1448 194.5C1448 303.5 1345 343.5 1287 343.5H241C130.6 343.5 103 438.5 103 486C103 582.4 195 618.833 241 625H1273H1550.5"
                  stroke="white"
                  strokeWidth="5"
                  strokeDasharray="20 20"
                />
                <defs>
                  <filter
                    id="filter0_di_167_2236"
                    x="0"
                    y="0"
                    width="1554.5"
                    height="668"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_167_2236"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_167_2236"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dx="2" dy="10" />
                    <feGaussianBlur stdDeviation="3.15" />
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect2_innerShadow_167_2236"
                    />
                  </filter>
                </defs>
              </svg> */}
              </div>
            </div>
          </Style>
          <GetInTouchButton />
          <ExperienceFooter />
        </>
      )}
    </>
  );
};

const Style = styled.div`
  width: 98.5vw;
  display: flex;
  overflow: hidden;
  animation: ${moveUp} 0.7s ease forwards;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .heading {
    width: 95%;
    display: flex;
    align-item: center;
    .header {
      width: 95%;
      text-align: center;
      text-transform: uppercase;
      h2 {
        font-size: 2.8rem;
        margin-bottom: 0;
        white-space: nowrap;
        font-weight: 400;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        font-size: 1.2rem;
        margin-top: 0;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }
    }
  }
  .container {
    position: relative;
    margin-top: -1.5rem;
    padding-left: 2.5rem;
  }
  .har {
    position: absolute;
    top: 7%;
    left: 13.3%;
    width: 39%;
    height: 19.5%;
    display: flex;

    flex-direction: column;
    z-index: 2;
    h2 {
      font-weight: 500;
      font-family: "Poppins", sans-serif;
    }
  }
  .hr {
    position: absolute;
    top: 12.9%;
    left: 13.3%;
    width: 66%;
    height: 19.5%;
    display: flex;
    flex-direction: column;
    z-index: 2;
    p {
      font-weight: 400;
      font-size: 1.2rem;
      font-family: "Poppins", sans-serif;
    }
  }
  .data {
    position: absolute;
    top: 24%;
    left: 13.3%;
    background-image: url("path.png");
    background-size: cover;
    width: 31.2rem;
    height: 18.625rem;
    display: flex;
    transition: transform 0.3s ease;
    flex-direction: column;
    z-index: 2;
    .first {
      position: absolute;
      margin-top: 0%;
      top: -24%;
      left: 24%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: row;
      gap: 31%;
      .head {
        font-family: "Bebas Neue", sans-serif;
        top: 1%;
        font-size: 2.3rem;
        white-space: nowrap;
        font-weight: 400;
        text-overflow: ellipsis;
        text-transform: uppercase;
        left: 1%;
        color: rgba(106, 89, 255, 1);
        margin-right: 1.2rem;
      }
    }
    .sec {
      position: absolute;
      margin-bottom: 0%;
      margin-left: 3%;
      top: 12%;
      height: auto;
      width: 80%;
      padding-left: 1rem;
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
      h1 {
        font-family: "Poppins", sans-serif;
        font-size: 1.2rem;
      }
      h2 {
        color: rgba(106, 89, 255, 1);
        font-size: 1rem;
        width: 90%;
        margin-top: 5%;
        font-weight: 500;
        text-decoration: underline;
      }
    }

    .third {
      position: absolute;
      top: 83%;
      margin-left: 2rem;
      margin-top: 0.3rem;
      cursor: pointer;
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 45%;
      .headd {
        width: 80%;
        color: rgba(106, 89, 255, 1);
        height: 3rem;
        font-size: 0.7rem;
        top: 10%;
      }
      svg {
        width: 5.7rem;
      }
    }
  }

  .data:hover {
    transform: translateY(-22px); /* Move the img-container upwards on hover */
  }
  .dataa {
    position: absolute;
    top: 60%;
    left: 13.3%;
    background-image: url("path.png");
    background-size: cover;
    width: 31.2rem;
    height: 18.625rem;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    z-index: 2;
    .first {
      position: absolute;
      margin-top: 0%;
      top: -24%;
      left: 24%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: row;
      gap: 62%;
      .head {
        font-family: "Bebas Neue", sans-serif;
        top: 1%;
        font-size: 2.6rem;
        font-weight: 400;
        color: rgba(106, 89, 255, 1);
        margin-left: 2rem;
        text-transform: uppercase;
      }
    }
    .sec {
      position: absolute;
      top: 7%;
      margin-bottom: 0%;
      margin-left: 3%;
      height: auto;
      width: 95%;
      width: 80%;
      padding-left: 1rem;
      font-size: 1rem;
      font-family: "Poppins", sans-serif;
      h1 {
        font-size: 0.9rem;
        margin-bottom: 0;
        margin-top: 0;
        cursor: pointer;
        padding: 0;
      }
      .pr {
        margin-top: 0;
        padding: 0;
        margin-bottom: 0;
      }
      .po {
        margin-top: -1.1rem;
      }
      h2 {
        margin-top: 0;
        margin-top: -2%;
        color: rgba(106, 89, 255, 1);
        font-size: 1rem;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        text-decoration: underline;
      }
    }
    .third {
      position: absolute;
      margin-left: 2rem;
      margin-top: 0rem;
      top: 84%;
      left: 0%;
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      cursor: pointer;
      gap: 45%;
      .headd {
        width: 90%;
        height: 3rem;
        font-size: 0.7rem;
        color: rgba(106, 89, 255, 1);
        top: 20%;
      }
      svg {
        width: 5.7rem;
      }
    }
  }
  .dataa:hover {
    transform: translateY(-22px); /* Move the img-container upwards on hover */
  }
  .dataaa {
    position: absolute;
    margin-left: 0.6rem;
    top: 60%;
    left: 48%;
    background-image: url("path.png");
    background-size: cover;
    width: 31.2rem;
    height: 18.625rem;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    z-index: 2;
    .first {
      position: absolute;
      margin-top: 0%;
      top: -24%;
      left: 22%;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: row;
      gap: 36%;
      .head {
        font-family: "Bebas Neue", sans-serif;
        top: 1%;
        margin-left: 2rem;
        font-size: 2.6rem;
        font-weight: 400;
        color: rgba(106, 89, 255, 1);
        text-transform: uppercase;
      }
    }
    .sec {
      position: absolute;
      margin-bottom: 0%;
      margin-left: 3%;
      top: 10%;
      height: auto;
      width: 75%;
      padding-left: 1rem;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      h1 {
        font-size: 0.8rem;
      }
      h2 {
        color: rgba(106, 89, 255, 1);
        font-size: 1rem;
        cursor: pointer;
        font-weight: 500;
        margin-top: 11%;
        font-family: "Poppins", sans-serif;
        text-decoration: underline;
      }
    }
    .third {
      position: absolute;
      margin-left: 2rem;
      margin-top: -1rem;
      top: 90%;
      left: 0%;
      display: flex;
      cursor: pointer;
      width: 90%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 45%;
      .headd {
        width: 90%;
        height: 3rem;
        font-size: 0.7rem;
        top: 20%;
        color: rgba(106, 89, 255, 1);
      }
      svg {
        width: 5.5rem;
      }
    }
  }
  .dataaa:hover {
    transform: translateY(-22px); /* Move the img-container upwards on hover */
  }
  .dat {
    position: absolute;
    top: 24%;
    left: 48%;
    margin-left: 0.6rem;
    background-image: url("path.png");
    background-size: cover;
    width: 31.2rem;
    height: 18.625rem;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    z-index: 2;
    .first {
      margin-top: 0%;
      align-items: center;
      position: absolute;
      top: -24%;
      left: 23%;
      justify-content: center;
      display: flex;
      font-size: 1rem;
      flex-direction: row;
      gap: 39%;
      .head {
        font-family: "Bebas Neue", sans-serif;
        top: 1%;
        margin-left: 2rem;
        font-size: 2.5rem;
        font-weight: 400;
        color: rgba(106, 89, 255, 1);
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .sec {
      position: absolute;
      margin-bottom: 0;
      top: 11%;
      margin-left: 0%;
      height: auto;
      width: 84%;
      padding-left: 1rem;
      font-family: "Poppins", sans-serif;
      font-size: 1rem;
      h1 {
        font-size: 0.7rem;
      }
      h2 {
        color: rgba(106, 89, 255, 1);
        font-size: 1rem;
        width: 70%;
        font-weight: 500;
        cursor: pointer;
        padding-left: 2.2rem;
        font-family: "Poppins", sans-serif;
        margin-top: -2%;
        text-decoration: underline;
      }
    }
    .third {
      position: absolute;
      margin-left: 2rem;
      margin-top: -0rem;
      top: 85%;
      left: 0%;
      display: flex;
      width: 90%;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      cursor: pointer;
      gap: 45%;
      .headd {
        width: 100%;
        height: 3rem;
        font-size: 0.7rem;
        top: 20%;
        color: rgba(106, 89, 255, 1);
      }
      .svvgg {
        svg {
          width: 5.7rem;
        }
      }
    }
  }
  .dat:hover {
    transform: translateY(-22px); /* Move the img-container upwards on hover */
  }
  .back {
    position: relative;
    min-width: 112vw;
    padding: 1rem;
    height: 220vh;
    background-image: url("jon.webp");
    background-size: cover;
    background-position: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 2%;
      text-align: center;
      width: 94%;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .heading {
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .link {
      }

      .svg-element {
        transition: transform 0.5s;
      }

      .svg-element:hover {
        transform: rotate(
          360deg
        ); /* Apply rotation only when .link is hovered */
      }
      .header {
        h2 {
          font-size: 3rem;
          margin-bottom: 0;
          white-space: nowrap;
          font-weight: 570;
          font-family: "Bebas Neue", sans-serif;
        }
        p {
          font-size: 1.2rem;
          margin-top: 0;
          font-weight: 420;
          font-family: "Poppins", sans-serif;
        }
      }
    }
    .container {
      position: relative;
    }
    .har {
      position: absolute;
      top: 8.8%;
      left: 12%;
      width: 43%;
      height: 19.5%;
      display: flex;

      flex-direction: column;
      z-index: 2;
      h2 {
        font-weight: 500;
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif;
      }
    }
    .hr {
      position: absolute;
      top: 16%;
      left: 12%;
      width: 81%;
      height: 19.5%;
      display: flex;
      flex-direction: column;
      z-index: 2;
      p {
        font-weight: 400;
        font-size: 0.9rem;
        font-family: "Poppins", sans-serif;
      }
    }
    .data {
      position: absolute;
      top: 28%;
      left: 10.5%;
      background-image: url("path.png");
      background-size: cover;
      width: 24.2rem;
      height: 14.3rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      .first {
        margin-top: 1%;
        top: -35%;
        align-items: center;
        justify-content: center;
        display: flex;
        font-size: 1rem;
        flex-direction: row;
        gap: 18%;
        .head {
          top: 1%;
          font-size: 2rem;
          text-transform: uppercase;
          left: 2%;
          color: rgba(106, 89, 255, 1);
        }
      }
      .sec {
        margin-bottom: -6%;
        height: auto;
        width: 95%;
        font-size: 0.8rem;
        h1 {
          font-size: 0.8rem;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 0.8rem;
          margin-top: 10%;
          text-decoration: underline;
        }
      }
      .third {
        margin-left: 2rem;
        margin-top: 2rem;
        top: 69%;
        left: 0%;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        .headd {
          width: 90%;
          color: rgba(106, 89, 255, 1);
          height: 3rem;
          font-size: 0.7rem;
          top: 20%;
        }
      }
    }
    .dataa {
      position: absolute;
      top: 59%;
      left: 10.5%;
      background-image: url("path.png");
      background-size: cover;
      width: 24.2rem;
      height: 14.3rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      .first {
        margin-top: -1%;
        top: -32%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 22%;
        .head {
          top: 1%;
          color: rgba(106, 89, 255, 1);
          left: 4%;
          text-transform: uppercase;
        }
      }
      .sec {
        margin-bottom: -6%;

        height: auto;
        width: 90%;
        font-size: 0.8rem;
        h1 {
          font-size: 0.8rem;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 1rem;
          text-decoration: underline;
        }
      }
      .third {
        margin-left: 2rem;
        margin-top: 0rem;
        top: 82%;
        left: 0%;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        .headd {
          width: 90%;
          height: 3rem;
          font-size: 0.7rem;
          color: rgba(106, 89, 255, 1);
          top: 20%;
        }
      }
    }
    .dataaa {
      position: absolute;
      top: 59%;
      left: 52%;
      margin-left: 1%;
      background-image: url("path.png");
      background-size: cover;
      width: 24.2rem;
      height: 14.3rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      .first {
        margin-top: -1%;
        top: -32%;
        left: 16%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 12%;
        .head {
          top: 1%;
          left: 4%;
          color: rgba(106, 89, 255, 1);
          text-transform: uppercase;
        }
      }
      .sec {
        margin-bottom: -6%;

        height: auto;
        width: 90%;
        font-size: 0.8rem;
        h1 {
          font-size: 0.8rem;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 0.9rem;
          margin-top: 5%;
          text-decoration: underline;
        }
      }
      .third {
        margin-left: 2rem;
        margin-top: 1rem;
        top: 76%;
        left: 0%;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        .headd {
          width: 90%;
          height: 3rem;
          font-size: 0.7rem;
          top: 20%;
          color: rgba(106, 89, 255, 1);
        }
      }
    }
    .dat {
      position: absolute;
      top: 28%;
      left: 52%;
      margin-left: 1%;
      background-image: url("path.png");
      background-size: cover;
      width: 24.2rem;
      height: 14.3rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      .first {
        margin-top: -1%;
        top: -32%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 22%;
        .head {
          top: 1%;
          left: 4%;
          color: rgba(106, 89, 255, 1);
          text-transform: uppercase;
        }
      }
      .sec {
        margin-bottom: -6%;

        height: auto;
        width: 93%;
        font-size: 0.8rem;
        h1 {
          font-size: 0.8rem;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 0.8rem;
          margin-top: 10%;
          text-decoration: underline;
        }
      }
      .third {
        margin-left: 2rem;
        margin-top: 0.2rem;
        top: 76%;
        left: 0%;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        .headd {
          width: 100%;
          height: 3rem;
          font-size: 0.7rem;
          top: 20%;
          color: rgba(106, 89, 255, 1);
        }
        .svvgg {
          svg {
            margin-top: 1rem;
          }
        }
      }
    }

    .back {
      position: relative;
      width: 100vw;
      height: 130vh;
      background-image: url("jon.webp");
      background-size: cover;
      background-position: center;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 2%;
        text-align: center;
        width: 94%;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 135vw;
    overflow: hidden;
    display: flex;
    margin-left: 0%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .heading {
      width: 95%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .link {
      }

      .svg-element {
        transition: transform 0.5s;
      }

      .svg-element:hover {
        transform: rotate(
          360deg
        ); /* Apply rotation only when .link is hovered */
      }
      .header {
        h2 {
          font-size: 2rem;
          margin-bottom: 0;
          white-space: nowrap;
          font-weight: 570;
          font-family: "Bebas Neue", sans-serif;
        }
        p {
          font-size: 0.7rem;
          margin-top: 0;
          font-weight: 420;
          font-family: "Poppins", sans-serif;
        }
      }
    }
    .container {
      position: relative;
    }
    .har {
      position: absolute;
      top: 7.8%;
      left: 7.6%;
      width: 49%;
      height: 19.5%;
      display: flex;

      flex-direction: column;
      z-index: 2;
      h2 {
        font-weight: 550;
        font-size: 0.8rem;
        font-family: "Poppins", sans-serif;
      }
    }
    .hr {
      position: absolute;
      top: 17%;
      left: 7.6%;
      width: 86%;
      height: 19.5%;
      display: flex;
      flex-direction: column;
      z-index: 2;
      p {
        font-weight: 440;
        font-size: 0.5rem;
        font-family: "Poppins", sans-serif;
      }
    }
    .data {
      position: absolute;
      top: 28%;
      left: 8%;
      background-image: url("path.png");
      background-size: cover;
      width: 12.2rem;
      height: 7.2rem;
      display: flex;
      flex-direction: column;
      z-index: 1;
      .first {
        position: absolute;
        top: -62%;
        left: 15%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 22%;
        .head {
          top: 0%;
          text-transform: uppercase;
          left: 2%;
          font-size: 0.6rem;
          color: rgba(106, 89, 255, 1);
          margin-left: 1.2rem;
        }
        svg {
          width: 110%;
        }
      }
      .sec {
        bottom: 10%;
        position: absolute;
        margin-bottom: -7%;
        margin-left: 0%;
        height: auto;
        width: 95%;
        font-size: 0.5rem;
        h1 {
          font-size: 0.5rem;
          margin-top: 5%;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 0.4rem;
          margin-top: 5%;
          text-decoration: underline;
        }
      }
      .third {
        position: absolute;
        margin-left: 1rem;
        margin-top: 2rem;
        top: 42%;
        left: 10%;
        display: flex;
        width: 90%;
        z-index: 4;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        svg {
          width: 60%;
        }
        .headd {
          width: 40%;
          color: rgba(106, 89, 255, 1);
          height: 3rem;
          font-size: 0.7rem;
          top: 20%;
        }
      }
    }
    .dataa {
      position: absolute;
      top: 59%;
      left: 8%;
      background-image: url("path.png");
      background-size: cover;
      width: 12.2rem;
      height: 7.2rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      .first {
        position: absolute;
        top: -62%;
        left: 16%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 26%;
        .head {
          top: 1%;
          color: rgba(106, 89, 255, 1);
          left: 4%;
          font-size: 0.8rem;
          text-transform: uppercase;
        }
        svg {
          width: 120%;
        }
      }
      .sec {
        position: absolute;
        margin-bottom: -5%;
        margin-left: 0%;
        height: auto;
        width: 90%;
        font-size: 0.4rem;
        h1 {
          font-size: 0.5rem;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 0.4rem;
          text-decoration: underline;
        }
      }
      .third {
        position: absolute;
        margin-left: 2rem;
        margin-top: 0rem;
        top: 70%;
        left: -5%;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        svg {
          width: 60%;
        }
        .headd {
          width: 90%;
          height: 3rem;
          font-size: 0.7rem;
          color: rgba(106, 89, 255, 1);
          top: 20%;
        }
      }
    }
    .dataaa {
      position: absolute;
      top: 59%;
      left: 55%;
      background-image: url("path.png");
      background-size: cover;
      width: 12.2rem;
      height: 7.2rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      .first {
        position: absolute;
        top: -64%;
        left: 18%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 22%;
        .head {
          top: 1%;
          left: 4%;
          font-size: 0.7rem;
          color: rgba(106, 89, 255, 1);
          text-transform: uppercase;
        }
        svg {
          width: 110%;
        }
      }
      .sec {
        position: absolute;
        margin-bottom: -6%;
        margin-left: 0%;
        top: 12%;
        height: auto;
        width: 90%;
        font-size: 0.45rem;
        h1 {
          font-size: 0.5rem;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 0.4rem;
          margin-top: 5%;
          text-decoration: underline;
        }
      }
      .third {
        margin-left: 2rem;
        position: absolute;
        margin-top: 1rem;
        top: 56%;
        left: -5%;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        svg {
          width: 70%;
        }
        .headd {
          width: 90%;
          height: 3rem;
          font-size: 0.7rem;
          top: 20%;
          color: rgba(106, 89, 255, 1);
        }
      }
    }
    .dat {
      position: absolute;
      top: 28%;
      left: 55%;
      background-image: url("path.png");
      background-size: cover;
      width: 12.2rem;
      height: 7.2rem;
      display: flex;
      flex-direction: column;
      z-index: 2;
      .first {
        position: absolute;
        margin-bottom: 0%;
        top: -65%;
        left: 8%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: row;
        gap: 0%;
        .head {
          width: 10%;
          color: rgba(106, 89, 255, 1);
          text-transform: uppercase;
          font-size: 0.8rem;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        svg {
          width: 60%;
        }
      }
      .sec {
        position: absolute;
        margin-bottom: -22%;
        margin-left: -14%;
        height: auto;
        top: 12%;
        width: 94%;
        font-size: 0.4rem;
        h1 {
          font-size: 0.4rem;
        }
        h2 {
          color: rgba(106, 89, 255, 1);
          font-size: 0.4rem;
          margin-top: 3%;
          margin-left: 5%;
          text-decoration: underline;
        }
      }
      .third {
        position: absolute;
        margin-left: 2rem;
        margin-top: 0.2rem;
        top: 55%;
        left: -6%;
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        gap: 45%;
        svg {
          width: 70%;
        }
        .headd {
          width: 100%;
          height: 3rem;
          font-size: 0.7rem;
          top: 20%;
          color: rgba(106, 89, 255, 1);
        }
        .svvgg {
          svg {
            margin-top: 1rem;
            width: 70%;
          }
        }
      }
    }

    .back {
      margin-left: 1%;
      position: relative;
      width: 120vw;
      height: 90vh;
      background-image: url("jon.webp");
      background-size: cover;
      background-position: center;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 2%;
        text-align: center;
        width: 94%;
      }
    }
  }
`;

export default Journey;
