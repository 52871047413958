import React, { useState } from "react";
import styled from "styled-components";

const Awards = () => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Style>
      <div className="main">
        <div className="heading">
          <h1>Awards</h1>
          <p>Unmatched Quality, Service and Trust</p>
        </div>
        <div className="award">
          <div
            className="first"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="fir">
              {/* <div className="awardsvg">
                <div>
                  <svg
                    width="176"
                    height="222"
                    viewBox="0 0 276 252"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M216.702 9.81397C216.702 3.8271 216.702 0.108276 216.702 0.108276H134.485H52.267C52.267 0.108276 52.267 3.8271 52.267 9.81397H0.557841H0.451858H0.310547V57.2025C0.310547 74.0939 6.35158 89.6875 17.3214 101.114C28.4832 112.74 44.028 119.741 63.6396 122.028C82.4121 151.401 117.836 167.56 117.836 181.158C117.836 199.657 99.3364 231.104 99.3364 231.104V251.271V251.312V251.454H169.633V231.104C169.633 231.104 151.135 199.657 151.135 181.158C151.135 167.653 186.065 151.622 204.934 122.633C227.991 121.345 245.998 114.137 258.5 101.114C269.469 89.6881 275.51 74.0945 275.51 57.2031V9.81397H216.702ZM22.0071 57.2031V31.5099H52.267C52.267 49.3256 52.267 70.759 52.267 84.6098C52.267 88.57 52.6833 92.3265 53.3215 95.967C53.4169 96.6477 53.4734 97.326 53.5841 98.0096C25.5863 89.833 22.0071 67.8427 22.0071 57.2031ZM99.1993 86.2837L99.1439 110.845C85.4886 104.955 72.8218 85.46 72.8218 77.6567C72.8218 69.8387 72.8218 22.947 72.8218 22.947H99.1993C99.1993 22.947 99.1993 54.3004 99.1993 86.2837ZM253.813 57.2031C253.813 66.5367 251.06 84.6003 231.491 94.4167C226.948 96.6801 221.498 98.4924 214.972 99.6446C216.08 94.9113 216.702 89.9113 216.702 84.6098C216.702 70.759 216.702 49.3256 216.702 31.5099H253.814V57.2031H253.813Z"
                      fill="#6B6B6B"
                      fill-opacity="0.25"
                    />
                  </svg>
                </div>
                <div>
                  <svg
                    width="78"
                    height="30"
                    viewBox="0 0 132 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M131.067 0.848145H0.890625V29.1103H131.067V0.848145Z"
                      fill="#6B6B6B"
                      fill-opacity="0.25"
                    />
                  </svg>
                </div>
              </div> */}
              {isHovered && (
                <img
                  src="Animation.gif"
                  alt="Your GIF"
                  style={{
                    position: "absolute",
                    top: "-110%",
                    left: "10%",
                    width: " 9rem",
                  }}
                />
              )}

              <div className="header">
                <div>
                  <h2>YourStory TechSparks 2022</h2>
                  <p className="p"> Most Promising Startup in Web 3.0 </p>
                </div>
                <div>
                  {/*   <p>
                    Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, Qorem ipsum dolor sit amet,
                  </p>*/}
                </div>
              </div>
            </div>
          </div>
          <div
            className="firstt"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <div className="fir">
              {isHovered && (
                <img
                  src="Animation.gif"
                  alt="Your GIF"
                  style={{
                    position: "absolute",
                    top: "-110%",
                    left: "10%",
                    width: " 9rem",
                  }}
                />
              )}
              {/*    <div className="awardsvg">
                <div>
                  <svg
                    className="svg"
                    width="176"
                    height="222"
                    viewBox="0 0 276 252"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M216.702 9.81397C216.702 3.8271 216.702 0.108276 216.702 0.108276H134.485H52.267C52.267 0.108276 52.267 3.8271 52.267 9.81397H0.557841H0.451858H0.310547V57.2025C0.310547 74.0939 6.35158 89.6875 17.3214 101.114C28.4832 112.74 44.028 119.741 63.6396 122.028C82.4121 151.401 117.836 167.56 117.836 181.158C117.836 199.657 99.3364 231.104 99.3364 231.104V251.271V251.312V251.454H169.633V231.104C169.633 231.104 151.135 199.657 151.135 181.158C151.135 167.653 186.065 151.622 204.934 122.633C227.991 121.345 245.998 114.137 258.5 101.114C269.469 89.6881 275.51 74.0945 275.51 57.2031V9.81397H216.702ZM22.0071 57.2031V31.5099H52.267C52.267 49.3256 52.267 70.759 52.267 84.6098C52.267 88.57 52.6833 92.3265 53.3215 95.967C53.4169 96.6477 53.4734 97.326 53.5841 98.0096C25.5863 89.833 22.0071 67.8427 22.0071 57.2031ZM99.1993 86.2837L99.1439 110.845C85.4886 104.955 72.8218 85.46 72.8218 77.6567C72.8218 69.8387 72.8218 22.947 72.8218 22.947H99.1993C99.1993 22.947 99.1993 54.3004 99.1993 86.2837ZM253.813 57.2031C253.813 66.5367 251.06 84.6003 231.491 94.4167C226.948 96.6801 221.498 98.4924 214.972 99.6446C216.08 94.9113 216.702 89.9113 216.702 84.6098C216.702 70.759 216.702 49.3256 216.702 31.5099H253.814V57.2031H253.813Z"
                      fill="#6B6B6B"
                      fill-opacity="0.25"
                    />
                  </svg>
                </div>
                <div>
                  <svg
                    className="svgg"
                    width="78"
                    height="30"
                    viewBox="0 0 132 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M131.067 0.848145H0.890625V29.1103H131.067V0.848145Z"
                      fill="#6B6B6B"
                      fill-opacity="0.25"
                    />
                  </svg>
                </div>
                </div> */}

              <div className="header">
                <div>
                  <h2> Silicon India 2021</h2>
                  <p className="p">VR Tech Startup of the  Year</p>
                </div>
                <div>
                  {/*   <p>
                    Qorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam eu turpis molestie, dictum est a, mattis tellus. Sed
                    dignissim, Qorem ipsum dolor sit amet,
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Style>
  );
};
const Style = styled.div`
  width: 98.5;
  scroll-behavior: smooth;
  max-height: 89vh;
  overflow: hidden;
  margin-top: 5%;
  padding: 0.8rem;
  flex-direction: column;
  align-item: center;
  justify-content: center;
  .main {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    .heading {
      width: 100%;
      margin-left: 2.7%;

      h1 {
        text-transform: uppercase;
        font-size: 2.9rem;
        font-weight: 400;
        letter-spacing: 2%;
        font-family: "Bebas Neue", sans-serif;
        text-align: center;
        margin-bottom: 0;
      }
      p {
        text-align: center;
        font-weight: 400;
        margin-top: 0;
        letter-spacing: 2%;
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
      }
    }
    .award {
      margin-top: 2.5%;
      width: 100%;
      margin-left: 2.7%;
      display: flex;
      align-item: center;
      padding-bottom: 0.8rem;
      align-content: center;
      justify-content: center;
      flex-direction: row;
      gap: 7%;
      .first {
        width: 40%;
        height: 23vh;
        padding-left: 2.5rem;
        background-color: rgba(255, 200, 94, 1);
        transition: transform 0.3s ease;
        /*   background-image: linear-gradient(
          to right,
          rgba(255, 200, 94, 1),
          rgba(144, 95, 0, 0.84)
        ); */
        background-color: rgba(255, 255, 255, 1);

        box-shadow: 10px 10px 26.54px 0px rgba(106, 89, 255, 0.14);

        border-radius: 1.5rem;
        .fir {
          display: flex;
          flex-direction: row;
          gap: 6%;
          .awardsvg {
            display: flex;
            display: none;
            align-item: center;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            margin-left: 3%;
          }
          .header {
            margin-top: -1.3rem;
            h2 {
              font-weight: 400;
              text-transform: uppercase;
              color: rgba(106, 89, 255, 1);
              font-size: 3.1rem;
              margin-bottom: 0;
              font-family: "Bebas Neue", sans-serif;
            }
            .p {
              margin-top: -0.4rem;
              font-size: 1.1rem;
              width: 85%;
              font-weight: 500;
              font-family: "Poppins", sans-serif;
            }
            p {
              font-size: 0.85rem;
              width: 95%;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
            }
          }
        }
      }
      .firstt:hover {
        transform: translateY(-1px);
        background-color: rgba(241, 241, 241, 1);
        box-shadow: 0px 4px 20px 0px rgba(21, 24, 55, 0.15) inset;
      }
      .first:hover {
        transform: translateY(-1px);
        background-color: rgba(241, 241, 241, 1);
        box-shadow: 0px 4px 20px 0px rgba(21, 24, 55, 0.15) inset;
      }
      .firstt {
        width: 40%;
        padding: 0rem;
        min-height: 14vh;
        transition: transform 0.3s ease;
        background-color: rgba(255, 200, 94, 1);
        /*   background-image: linear-gradient(
          to right,
          rgba(255, 200, 94, 1),
          rgba(144, 95, 0, 0.84)
        ); */
        background-color: rgba(255, 255, 255, 1);
        padding-left: 2.5rem;
        border-radius: 1.5rem;
        box-shadow: 10px 10px 26.54px 0px rgba(106, 89, 255, 0.14);
        .fir {
          display: flex;
          flex-direction: row;
          gap: 6%;
          .awardsvg {
            display: flex;
            align-item: center;
            display: none;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            margin-left: 3%;
          }
          .header {
            margin-top: -1.3rem;
            margin-bottom: 0.8rem;
            h2 {
              text-transform: uppercase;
              font-size: 3.1rem;
              color: rgba(106, 89, 255, 1);
              font-weight: 400;
              margin-bottom: 0;
              font-family: "Bebas Neue", sans-serif;
            }
            .p {
              margin-top: -0.4rem;
              font-size: 1.1rem;
              font-weight: 500;
              font-family: "Poppins", sans-serif;
            }
            p {
              font-size: 0.85rem;
              width: 95%;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5;
    height: auto;
    scroll-behavior: smooth;
    max-height: 89vh;
    overflow: hidden;
    margin-top: 4%;
    padding: 0.8rem;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    .main {
      width: 95%;
      display: flex;
      flex-direction: column;
      align-item: center;
      justify-content: center;
      .heading {
        width: 100%;
        margin-left: 2.7%;

        h1 {
          text-transform: uppercase;
          font-size: 2.9rem;
          font-weight: 400;
          letter-spacing: 2%;
          font-family: "Bebas Neue", sans-serif;
          text-align: center;
          margin-bottom: 0;
        }
        p {
          text-align: center;
          font-weight: 400;
          margin-top: 0;
          letter-spacing: 2%;
          font-family: "Poppins", sans-serif;
          font-size: 1.3rem;
        }
      }
      .award {
        margin-top: 7%;
        width: 100%;
        margin-left: 2.7%;
        display: flex;
        align-item: center;
        align-content: center;
        justify-content: center;
        flex-direction: row;
        gap: 7%;
        .first {
          width: 40%;
          padding-left: 2.5rem;
          background-color: rgba(255, 200, 94, 1);
          transition: transform 0.3s ease;
          /*   background-image: linear-gradient(
          to right,
          rgba(255, 200, 94, 1),
          rgba(144, 95, 0, 0.84)
        ); */
          background-color: rgba(255, 255, 255, 1);

          box-shadow: 10px 10px 26.54px 0px rgba(106, 89, 255, 0.14);

          border-radius: 1.5rem;
          .fir {
            display: flex;
            flex-direction: row;
            gap: 6%;
            .awardsvg {
              display: flex;
              display: none;
              align-item: center;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              margin-left: 3%;
            }
            .header {
              margin-top: -1.3rem;
              h2 {
                font-weight: 400;
                text-transform: uppercase;
                color: rgba(106, 89, 255, 1);
                font-size: 3.1rem;
                margin-bottom: 0;
                font-family: "Bebas Neue", sans-serif;
              }
              .p {
                margin-top: -0.7rem;
                font-size: 1.1rem;
                width: 85%;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
              }
              p {
                font-size: 0.85rem;
                width: 95%;
                font-family: "Poppins", sans-serif;
                font-weight: 400;
              }
            }
          }
        }
        .firstt:hover {
          transform: translateY(-1px);
          background-color: rgba(241, 241, 241, 1);
          box-shadow: 0px 4px 20px 0px rgba(21, 24, 55, 0.15) inset;
        }
        .first:hover {
          transform: translateY(-1px);
          background-color: rgba(241, 241, 241, 1);
          box-shadow: 0px 4px 20px 0px rgba(21, 24, 55, 0.15) inset;
        }
        .firstt {
          width: 40%;
          padding: 0rem;
          transition: transform 0.3s ease;
          background-color: rgba(255, 200, 94, 1);
          /*   background-image: linear-gradient(
          to right,
          rgba(255, 200, 94, 1),
          rgba(144, 95, 0, 0.84)
        ); */
          background-color: rgba(255, 255, 255, 1);
          padding-left: 2.5rem;
          border-radius: 1.5rem;
          box-shadow: 10px 10px 26.54px 0px rgba(106, 89, 255, 0.14);
          .fir {
            display: flex;
            flex-direction: row;
            gap: 6%;
            .awardsvg {
              display: flex;
              align-item: center;
              display: none;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              margin-left: 3%;
            }
            .header {
              margin-top: -1.3rem;
              margin-bottom: 0.8rem;
              h2 {
                text-transform: uppercase;
                font-size: 3.1rem;
                color: rgba(106, 89, 255, 1);
                font-weight: 400;
                margin-bottom: 0;
                font-family: "Bebas Neue", sans-serif;
              }
              .p {
                margin-top: -0.7rem;
                font-size: 1.1rem;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
              }
              p {
                font-size: 0.85rem;
                width: 95%;
                font-family: "Poppins", sans-serif;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 130vw;
    height: auto;
    padding-bottom: 5rem;
    overflow: hidden;
    flex-direction: column;
    align-item: center;
    justify-content: center;
    .main {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-item: center;
      justify-content: center;
      .heading {
        h1 {
          text-transform: uppercase;
          font-size: 2.9rem;
        }
      }
      .award {
        margin-top: 5%;
        width: 98%;
        display: flex;
        align-item: center;
        justify-content: center;
        flex-direction: row;
        gap: 2%;
        .first {
          width: 47%;

          /*   background-image: linear-gradient(
            to right,
            rgba(255, 200, 94, 1),
            rgba(144, 95, 0, 0.84)
          ); */
          background-color: rgba(255, 255, 255, 1);
          border-radius: 1.2rem;
          .fir {
            display: flex;
            flex-direction: column;
            gap: 5%;
            .awardsvg {
              display: flex;
              align-item: center;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              margin-left: 1%;
            }
            .header {
              margin-left: 2%;
              h2 {
                text-transform: uppercase;
                font-size: 2.2rem;
              }
            }
          }
        }
        .firstt {
          width: 46%;
          /*   background-image: linear-gradient(
            to right,
            rgba(255, 200, 94, 1),
            rgba(144, 95, 0, 0.84)
          ); */
          background-color: rgba(255, 255, 255, 1);
          border-radius: 1.2rem;

          .fir {
            display: flex;
            flex-direction: column;
            gap: 5%;
            .awardsvg {
              display: flex;
              align-item: center;
              flex-direction: column;
              text-align: center;
              justify-content: center;
              margin-left: 1%;
            }
            .header {
              margin-left: 2%;
              h2 {
                margin-left: 1%;
                text-transform: uppercase;
                font-size: 2.2rem;
              }
            }
          }
        }
      }
    }
  }
`;

export default Awards;
