import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import Svgpath from "./Svgpath";

const Path = () => {
  const svgRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsIntersecting(entry.isIntersecting);
        });
      },
      {
        threshold: 0.5, // Adjust as needed
      }
    );

    if (svgRef.current) {
      observer.observe(svgRef.current);
    }

    return () => {
      if (svgRef.current) {
        observer.unobserve(svgRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isIntersecting && !animationStarted) {
      // Animation should start here
      console.log("Svgpath is partially visible");
      setAnimationStarted(true);
    }
  }, [isIntersecting, animationStarted]);

  return (
    <>
      <Style  ref={svgRef}>
        <div className="main">
          <div className="heading">
            <h2>That’s how we do it</h2>
            <p>Easy, short and simple</p>
          </div>
          <div className="content">
          <div>
            <Svgpath />
          </div>
          </div>
        </div>
      </Style>
    </>
  );
};

const Style = styled.div`
  width: 98.5vw;
  overflow: hidden;
  .main {
    display: flex;
    flex-direction: column;
    width: 100%;
    .heading {
        margin-bottom: 0;
      text-align: center;
      h2 {
        font-size: 2.8rem;
        font-family: "Bebas Neue", sans-serif;
        font-weight: 400;
        margin-bottom: 0;
      }
      p {
     font-size: 1.2rem;
     font-weight: 400;
     font-family: "Poppins", sans-serif;
     margin-top: 0;
      }
    }
    .content {
        margin-top: -5rem;
        text-align: center;
        align-item: center;
        margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 767px) { 
    width: 140vw;
    padding-bottom: 2.5rem;
    overflow: hidden;
    .main {
      display: flex;
      flex-direction: column;
      width: 100%;
      .heading {
          margin-bottom: 0;
        text-align: center;
        h2 {
          font-size: 2.8rem;
          font-family: "Bebas Neue", sans-serif;
          font-weight: 400;
          margin-bottom: 0;
        }
        p {
       font-size: 1.2rem;
       font-weight: 400;
       font-family: "Poppins", sans-serif;
       margin-top: 0;
        }
      }
      .content {
          margin-top: -2rem;
          text-align: center;
      }
    }
   }

`;

export default Path;
