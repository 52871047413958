import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelectedTitleContext } from "./SelectedTitleContext";

const Frontpage = () => {
  const { hostName } = useSelectedTitleContext();
  return (
    <Container>
      <div className="header">
        <div className="logo">
          <img
            src={`${process.env.PUBLIC_URL}/spaceslogo.png`}
            alt="Your Image"
            className="rounded-lg shadow-lg"
          />
        </div>
        <div>
          <Link to="/virtual-reality-vr-tours" className="button">
            VR Tour
          </Link>
        </div>
      </div>
      <div className="screen">
        <div className="main">
          <div className="content">
            <div className="text">
              <div className="maintext">
                <h3>
                  Bring your properties to life with VR, AR & the Metaverse
                </h3>
                <p className="text-gray-700 mt-2">
                  We at {hostName} help Real Estate Developers better present
                  their vision to prospects for properties that are still under
                  construction
                </p>
                <button className="button">Explore Our Work</button>
              </div>
            </div>
            <div className="image">
              <img
                src={`${process.env.PUBLIC_URL}/vr.png`}
                alt="Your Image"
                className="img"
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  /* General styles applicable to all screen sizes */

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 30px;

    .logo {
      width: 180px;
      margin-left: 120px;
    }

    .button {
      background-color: #5f3ec3;
      font-size: 20px;
      width: 80%;
      font-weight: 430;
      color: #fff;
      text-decoration: none;
      padding: 10px 27px;
      border: none;
      margin-right: 170px;
      border-radius: 15px;
      cursor: pointer;

      &:hover {
        background-color: black;
      }
    }
  }
  .screen {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .main {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 80vh;
      background-color: #f7f6f6;
      border-radius: 140px;
      width: 85%;
      margin-top: 120px;
    }

    .content {
      width: 90%;
      display: flex;
      padding: 15px 27px;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .text {
        text-align: left;
        font-weight: 330;
        max-width: 100%;
        .maintext {
          max-width: 70%;
          font-weight: 330;
          h3 {
            font-size: 3vw;
            font-weight: 420;
          }
          p {
            font-weight: 410;
          }

          .button {
            background-color: #5f3ec3;
            color: #fff;
            font-weight: bold;
            font-size: 17px;
            padding: 10px 20px;
            border: none;
            width: 70%;
            font-weight: 430;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
              background-color: black;
            }
          }
        }
      }
    }

    .image {
      width: 110%;

      .img {
        width: 100%;
      }
    }
  }

  @media (max-width: 1024px) {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      margin-top: 30px;

      .logo {
        width: 190px;
        margin-left: 50px;
      }

      .button {
        background-color: #5f3ec3;
        font-size: 25px;
        width: 70%;
        color: #fff;
        text-decoration: none;
        padding: 10px 27px;
        border: none;
        margin-right: 90px;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
          background-color: black;
        }
      }
    }
    .screen {
      .main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 75vh;
        background-color: #f7f6f6;
        border-radius: 140px;
        width: 85%;
        margin-top: 130px;
        margin-left: 6vw;
      }

      .content {
        width: 100%;
        display: flex;
        padding: 15px 0px;
        margin-left: 4rem;
        flex-direction: row;

        .text {
          text-align: left;
          max-width: 120%;
          .maintext {
            max-width: 160%;
            h3 {
              font-size: 2rem;
            }
            p {
              font-size: 1rem;
              font-weight: 470;
            }

            .button {
              background-color: #5f3ec3;
              color: #fff;
              font-weight: 430;
              font-size: 16px;
              padding: 10px 20px;
              border: none;
              width: 100%;
              border-radius: 10px;
              cursor: pointer;

              &:hover {
                background-color: black;
              }
            }
          }
        }
      }

      .image {
        width: 340%;
        .img {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;
      margin-top: 30px;

      .logo {
        width: 20%;
        margin-left: 50px;
      }

      .button {
        background-color: #5f3ec3;
        font-size: 25px;
        width: 70%;
        color: #fff;
        text-decoration: none;
        padding: 10px 27px;
        border: none;
        margin-right: 10px;
        border-radius: 15px;
        cursor: pointer;

        &:hover {
          background-color: black;
        }
      }
    }
    .screen {
      .main {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 126vh;
        background-color: white;
        border-radius: 140px;
        width: 85%;
        margin-top: 130px;
        margin-left: 6vw;
      }

      .content {
        width: 90%;
        display: flex;
        padding: 15px 0px;
        margin-left: 3.8rem;
        flex-direction: column;

        .text {
          text-align: left;
          .maintext {
            max-width: 160%;
            h3 {
              font-size: 3.8rem;
            }
            p {
              font-size: 1rem;
              font-weight: 470;
            }

            .button {
              background-color: #5f3ec3;
              color: #fff;
              font-weight: bold;
              font-size: 20px;
              padding: 10px 20px;
              border: none;
              font-weight: 430;
              width: auto;
              border-radius: 10px;
              cursor: pointer;

              &:hover {
                background-color: black;
              }
            }
          }
        }
      }

      .image {
        margin-right: 3.8rem;
        width: 140%;
        .img {
          width: 100%;
        }
      }
    }
  }
`;

export default Frontpage;
