import React, { useState, useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import LogoToggle from "../LogoToggle";
import NavbarComponent from "../NavbarComponent";
import { useSelectedTitleContext } from "../SelectedTitleContext";
import ExperienceFooter from "../ExperienceFooter";

import GetInTouchButton from "../GetInTouchButton";

import { ScrollDirection, useScrollDirection } from "../ScrollDirection";

const moveUp = keyframes`
from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const TheValue = () => {
  const { isNavOpen, toggleNav, hostName } = useSelectedTitleContext();
  const [firstRowVisible, setFirstRowVisible] = useState(false);
  const [secondRowVisible, setSecondRowVisible] = useState(false);
  const scrollDirection = useScrollDirection();
  const firstRowRef = useRef(null);
  const secondRowRef = useRef(null);
  const [imageZIndex, setImageZIndex] = useState([1, 1, 1, 1]); // Initial z-index for each image
  const handleClick = (index) => {
    const updatedZIndex = [1, 1, 1, 1]; // Reset z-index for all images
    updatedZIndex[index] = 4; // Set clicked image's z-index to 3
    setImageZIndex(updatedZIndex); // Update state
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Fully visible in viewport
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === firstRowRef.current) {
          if (entry.isIntersecting) {
            setFirstRowVisible(true);
          }
        } else if (entry.target === secondRowRef.current) {
          if (entry.isIntersecting) {
            setSecondRowVisible(true);
            setFirstRowVisible(true); // Set first row visible when second row becomes visible
          }
        }
      });
    }, options);

    if (firstRowRef.current) {
      observer.observe(firstRowRef.current);
    }
    if (secondRowRef.current) {
      observer.observe(secondRowRef.current);
    }

    return () => {
      if (firstRowRef.current) {
        observer.unobserve(firstRowRef.current);
      }
      if (secondRowRef.current) {
        observer.unobserve(secondRowRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (firstRowVisible && !secondRowVisible) {
      const firstRowShadows =
        firstRowRef.current.querySelectorAll(".image-content");
      firstRowShadows.forEach((shadow, index) => {
        shadow.style.animation = "fadeIn 1s ease-in-out forwards";
      });
    }
    if (secondRowVisible) {
      const secondRowShadows =
        secondRowRef.current.querySelectorAll(".image-content");
      secondRowShadows.forEach((shadow, index) => {
        shadow.style.animation = "fadeIn 1s ease-in-out forwards";
      });
    }
  }, [firstRowVisible, secondRowVisible]);

  useEffect(() => {
    if (scrollDirection === ScrollDirection.UP && !secondRowVisible) {
      setFirstRowVisible(true);
    }
  }, [scrollDirection, secondRowVisible]);

  return (
    <>
      <LogoToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />
      {isNavOpen && <NavbarComponent />}
      {!isNavOpen && (
        <>
          <Style>
            <div className="container">
              <div className="image-container">
                <img src="/thevalue.webp" alt="name" loading="eager|lazy" />

                <div className="overlay">
                  <h2 className="headingh">The Value-Added Service Suite</h2>
                  <p className="hedingpara">
                    We are not limited in our offerings. We can do whatever you
                    like. Get customer analytics of our Sales Interactions
                    through our tools, get gamified apps, interactive Dashboards
                    and what not! The possibilities are endless.
                  </p>
                </div>
              </div>

              <div className="content-container">
                <div>
                  <h2>Tell us your problem</h2>
                  <p>Get innovative solutions catered to your needs</p>
                </div>
                <div className="iimgclick">
                  <div className="image-box">
                    <img src="arposter11.webp" alt="Image 1" id="imgfirst" />
                    <div className="overlay-box-first">
                      <h2>DLF - Drone shoot</h2>
                      <p>Customer analytics suite</p>
                    </div>
                  </div>
                  <div className="image-box">
                    <img src="arposter21.webp" alt="Image 2" id="imgsec" />
                    <div className="overlay-box-second">
                      <h2>Adani Ambrosia</h2>
                      <p>A metaverse solution</p>
                    </div>
                  </div>
                  <div className="image-box">
                    <img src="arposter11.webp" alt="Image 3" id="imgthir" />
                    <div className="overlay-box-third"></div>
                  </div>
                  <div className="image-box">
                    <img src="arposter21.webp" alt="Image 4" id="imgfour" />
                    <div className="overlay-box-fourth"></div>
                  </div>
                </div>
              </div>
              <div className="caseheading">
                <h2>Some of our Innovative Offerings</h2>
                <p>
                  Innovation at scale and results at hand, you’ll only get at
                  {hostName}
                </p>
              </div>
              <div className="container">
                <Styl>
                  <div className="inner-containerr">
                    <div className="img1" ref={firstRowRef}>
                      <div className="image-content">
                        <div className="fontimg">
                          <img src="/unspla1.webp" alt="image" />
                        </div>
                        <div className="overlay">
                          <h2 id="dif" style={{ textTransform: "uppercase" }}>
                            Case Studies
                          </h2>
                        </div>
                      </div>
                      <div className="image-content">
                        <div className="fontimg">
                          <img src="/unspla.webp" alt="image" />
                        </div>
                        <div className="overlay">
                          <div className="headder" id="imgcontentfirst">
                            <h2 id="diff">Adani Ambrosia - Virtual Tour:</h2>
                            <p>
                              Step into luxury with Adani Ambrosia's Virtual
                              Tour, where you're invited to explore its opulent
                              lifestyle and state-of-the-art amenities,
                              providing a glimpse into a world of unparalleled
                              elegance.
                            </p>
                          </div>
                        </div>
                        <div className="learnmoree">
                          <div>
                            <svg
                              width="59"
                              height="24"
                              viewBox="0 0 89 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>
                            <a href="https://d1y894ed9p1p3a.cloudfront.net/">
                              {" "}
                              <p>learn more</p>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="img2" ref={secondRowRef}>
                      <div className="image-content">
                        <div className="fontimg">
                          <img src="/unsplash1.webp" alt="image" />
                        </div>
                        <div className="overlay">
                          <div id="imgcontentsec" className="headder">
                            <h2>
                              Smartworld One DXP - Flat Selector Tool + VR Tour:
                            </h2>
                            <p>
                              Seamlessly select your ideal flat at Smartworld
                              One DXP with our intuitive Flat Selector Tool,
                              then immerse yourself in a captivating VR Tour to
                              experience every detail firsthand, revolutionizing
                              your property exploration.
                            </p>
                          </div>
                        </div>
                        <div className="learnmore">
                          <div>
                            <svg
                              width="59"
                              height="24"
                              viewBox="0 0 89 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>
                            <a href="https://new-tower-renders.d22h0q7uf4giy9.amplifyapp.com/smart-world">
                              {" "}
                              <p>learn more</p>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="image-content">
                        <div className="fontimg">
                          <img src="/unsplash.webp" alt="image" />
                        </div>
                        <div className="overlay">
                          <div id="imgcontentthird" className="headder">
                            <h2>M3M City of Dreams - Plot Selector Tool:</h2>
                            <p>
                              Find your perfect plot effortlessly with our
                              tailored Plot Selector Tool for M3M City of
                              Dreams, empowering you to discover the ideal
                              canvas for your dream home or investment project,
                              simplifying your search process.
                            </p>
                          </div>
                        </div>
                        <div className="learnmore">
                          <div>
                            <svg
                              width="59"
                              height="24"
                              viewBox="0 0 89 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                          <div>
                            <a
                              href="https://d2u9hjviq82t82.cloudfront.net/"
                              target="_blank"
                            >
                              <p>learn more</p>{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Styl>
              </div>
            </div>
          </Style>
          <GetInTouchButton />
          <ExperienceFooter />
        </>
      )}
    </>
  );
};
const Styl = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image-content {
    opacity: 0.2;
    @media only screen and (max-width: 767px) {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes sequentialFadeIn {
    0% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
  }
`;
const Style = styled.div`
  width: 96.5vw;
  overflow: hidden;
  animation: ${moveUp} 0.7s ease forwards;
  padding: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  .container {
    width: 100%;
    overflow: hidden;
    padding: 0.9rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    position: relative;
    margin-bottom: 10px;
    min-height: 80vh;
    animation: ${zoomIn} 3s;
  }

  .image-container img {
    width: 100%;
    margin-left: 1rem;
    height: auto;
  }

  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-90%, -50%);
    background-color: rgba(255, 255, 255, 0);
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;

    .headingh {
      text-transform: uppercase;
      font-size: 3.3rem;
      font-weight: 400;
      text-align: left;
      margin-bottom: 1rem;
      font-family: "Bebas Neue", sans-serif;
    }
    .hedingpara {
      font-size: 1.2rem;
      width: 90%;
      font-weight: 500;
      text-align: left;
      font-family: "Poppins", sans-serif;
    }
  }

  .overlay h2,
  .overlay p {
    margin: 0;
  }

  .content-container {
    height: auto;
    min-height: 30rem;
    text-align: center;
    margin-top: -5rem;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    h2 {
      text-transform: uppercase;
      font-size: 2.7rem;
      font-weight: 400;
      font-family: "Bebas Neue", sans-serif;
      margin-bottom: 0;
    }
    p {
      margin-top: 1rem;
      font-weight: 400;
      font-size: 1.1rem;
      font-family: "Poppins", sans-serif;
    }
    .iimgclick {
      margin-top: 2rem;
      display: flex;
      align-items: center;
      transition: transform 0.3s ease;
      flex-direction: row;
      img {
        width: 21rem;
        border-radius: 0.9rem;
        box-shadow: -5.06px 3.38px 3.38px 0px rgba(0, 0, 0, 0.21);
      }
      .overlay-box-first {
        position: absolute;
        z-index: 3;
        text-align: left;
        padding: 2rem;
        color: white;
        right: 26%;
        width: 15%;
        h2 {
          font-family: "Bebas Neue", sans-serif;
          font-size: 2.6rem;
          font-weight: 400;
        }
        p {
          font-family: "Poppins", sans-serif;
          font-size: 1.2rem;
        }
      }
      .overlay-box-second {
        position: absolute;
        text-align: left;
        color: white;
        padding: 2rem;
        z-index: 5;
        right: 32.5%;
        width: 17%;
        h2 {
          font-family: "Bebas Neue", sans-serif;
          font-size: 2.8rem;
          font-weight: 400;
        }
        p {
          font-family: "Poppins", sans-serif;
          font-size: 1.2rem;
        }
      }
      #imgfirst {
        position: absolute;
        right: 40%;
        z-index: 1;
        transition: transform 0.3s ease-in-out;
      }

      #imgsec {
        position: absolute;
        right: 36%;
        transition: transform 0.3s ease-in-out;
        z-index: 2;
      }
      #imgthir {
        position: absolute;
        right: 32%;
        transition: transform 0.3s ease-in-out;
        z-index: 3;
      }
      #imgfour {
        position: absolute;
        right: 44%;
        transition: transform 0.3s ease-in-out;
      }
    }
  }
  .iimgclick:hover {
    width: 98%;

    padding: 1rem;
    img {
      width: 19rem;
      border-radius: 0.9rem;
      box-shadow: -5.06px 3.38px 3.38px 0px rgba(0, 0, 0, 0.21);
    }
    #imgfirst {
      position: absolute;
      right: 2.5%;
      z-index: 1;
      transition: transform 0.3s ease-in-out;
    }

    .overlay-box-second {
      position: absolute;
      text-align: left;
      color: white;
      padding: 2rem;
      z-index: 5;
      right: 02.5%;
    }

    #imgsec {
      position: absolute;
      right: 26%;
      transition: transform 0.3s ease-in-out;
      z-index: 2;
    }
    #imgthir {
      position: absolute;
      right: 49.5%;
      transition: transform 0.3s ease-in-out;
      z-index: 3;
    }
    #imgfour {
      position: absolute;
      right: 73%;
      transition: transform 0.3s ease-in-out;
    }
  }
  .caseheading {
    margin-top: 4%;
    width: 92%;
    align-items: center;
    margin-bottom: 3%;
    h2 {
      font-family: "Bebas Neue", sans-serif;
      text-transform: uppercase;
      text-align: center;
      font-size: 2.5rem;
      word-spacing: -2px;
      font-weight: 400;
      margin-bottom: 0;
      font-family: "Bebas Neue", sans-serif;
    }
    p {
      font-weight: 400;
      font-size: 1.1rem;
      text-align: center;
      margin-top: 0;
      font-family: "Poppins", sans-serif;
    }
  }
  .inner-container {
    display: flex;

    flex-direction: row;
  }
  .inner-containerr {
    display: flex;
    width: 92%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .inven {
    display: flex;
    flex-direction: row;
    .image-content {
      position: relative;
    }
  }
  .img2 {
    display: flex;
    flex-direction: row;
    margin-top: 2%;
    gap: 2%;

    .image-content {
      position: relative;
    }
  }

  .img1 {
    display: flex;
    flex-direction: row;
    gap: 2%;
    .image-content {
      position: relative;
    }
  }

  .image-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.7rem;
    object-fit: cover;
    overflow: hidden;
    transition: transform 0.3s ease;
  }

  .fontimg {
    background-color: trans;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 12px 0 15px rgba(106, 89, 255, 0.12),
      /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.25),
      /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
  }
  .image-content img:hover {
    transform: scale(1.1);
  }

  .image-content .overlay {
    width: 60%;
    position: absolute;
    top: 36%;
    left: 37%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0);
    color: white;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;

    #imgcontentfirst {
      margin-top: 7rem;
      font-family: "Bebas Neue", sans-serif;
      h2 {
        width: 150%;
      }
      p {
        width: 135%;
      }
    }
    #imgcontentsec {
      margin-top: 7rem;
      font-family: "Bebas Neue", sans-serif;
      h2 {
        width: 150%;
      }
      p {
        width: 135%;
      }
    }
    #imgcontentthird {
      margin-top: -3rem;
      font-family: "Bebas Neue", sans-serif;
      h2 {
        width: 150%;
      }
      p {
        width: 135%;
      }
    }
    #dif {
      margin-top: -4rem;
      font-weight: 400;
      margin-left: 0;
      font-size: 2.5rem;
      font-family: "Bebas Neue", sans-serif;
    }
    .headder {
      margin-bottom: 20%;
      h2 {
        text-transform: uppercase;
        text-align: start;
        font-size: 2.5rem;
        font-weight: 400;
      }
      p {
        margin-top: 2rem;
        text-align: start;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }
    }
  }
  .learnmore {
    position: absolute;
    top: 83%;
    left: 10%;
    display: flex;
    align-items: flex-end;
    text-align: start;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    p {
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
      font-weight: 500;
    }
  }
  .learnmoree {
    position: absolute;
    top: 69%;
    left: 10%;
    display: flex;
    cursor: pointer;
    align-items: flex-end;
    text-align: start;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    p {
      font-size: 1rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
      font-weight: 500;
    }
  }
  .image-content .overlay h2,
  .image-content .overlay p {
    margin: 0;
  }

  .image-content .overlay a {
    display: block;
    margin-top: 10px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5vw;
    overflow: hidden;
    animation: ${moveUp} 0.7s ease forwards;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    .container {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .image-container {
      position: relative;
      margin-bottom: 40px;
      min-height: 38vh;
      animation: ${zoomIn} 3s;
    }

    .image-container img {
      width: 100%;

      height: auto;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-90%, -50%);
      background-color: rgba(255, 255, 255, 0.7);
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 5px;

      .headingh {
        text-transform: uppercase;
        font-size: 2.1rem;
        font-weight: 400;
        text-align: left;
        margin-bottom: 1rem;
        font-family: "Bebas Neue", sans-serif;
      }
      .hedingpara {
        font-size: 0.8rem;
        width: 95%;
        font-weight: 400;
        text-align: left;
        font-family: "Poppins", sans-serif;
      }
    }

    .overlay h2,
    .overlay p {
      margin: 0;
    }

    .content-container {
      height: auto;
      min-height: 30vh;
      text-align: center;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      h2 {
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 400;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        font-weight: 450;
        font-size: 1.1rem;
        font-family: "Poppins", sans-serif;
      }
      .iimgclick {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        transition: transform 0.3s ease;
        flex-direction: row;
        img {
          width: 15rem;
          border-radius: 0.9rem;
          box-shadow: -5.06px 3.38px 3.38px 0px rgba(0, 0, 0, 0.21);
        }
        .overlay-box-first {
          position: absolute;
          z-index: 3;
          text-align: left;
          padding: 2rem;
          color: white;
          margin-top: -4%;
          right: 24%;
          width: 20%;
          h2 {
            font-family: "Bebas Neue", sans-serif;
            font-size: 2.6rem;
            font-weight: 400;
          }
          p {
            font-family: "Poppins", sans-serif;
            font-size: 1.2rem;
          }
        }
        .overlay-box-second {
          position: absolute;
          text-align: left;
          color: white;
          padding: 2rem;
          z-index: 5;
          margin-top: -4%;
          right: 32.5%;
          width: 17%;
          h2 {
            font-family: "Bebas Neue", sans-serif;
            font-size: 2.8rem;
            font-weight: 400;
          }
          p {
            font-family: "Poppins", sans-serif;
            font-size: 1.2rem;
          }
        }
        #imgfirst {
          position: absolute;
          right: 40%;
          z-index: 1;
          transition: transform 0.3s ease-in-out;
        }

        #imgsec {
          position: absolute;
          right: 36%;
          transition: transform 0.3s ease-in-out;
          z-index: 2;
        }
        #imgthir {
          position: absolute;
          right: 32%;
          transition: transform 0.3s ease-in-out;
          z-index: 3;
        }
        #imgfour {
          position: absolute;
          right: 44%;
          transition: transform 0.3s ease-in-out;
        }
      }
      .iimgclick:hover {
        #imgfirst {
          position: absolute;
          right: 2.3%;
          z-index: 1;
          transition: transform 0.3s ease-in-out;
        }

        .overlay-box-second {
          position: absolute;
          text-align: left;
          color: white;
          padding: 2rem;
          z-index: 5;
          right: 02.5%;
        }

        #imgsec {
          position: absolute;
          right: 25%;
          transition: transform 0.3s ease-in-out;
          z-index: 2;
        }
        #imgthir {
          position: absolute;
          right: 49%;
          transition: transform 0.3s ease-in-out;
          z-index: 3;
        }
        #imgfour {
          position: absolute;
          right: 69%;
          transition: transform 0.3s ease-in-out;
        }
      }
    }
    .caseheading {
      margin-top: 4%;
      width: 92%;
      margin-bottom: 3%;
      h2 {
        font-family: "Bebas Neue", sans-serif;
        text-transform: uppercase;
        font-size: 2.5rem;
        word-spacing: -2px;
        font-weight: 400;
        margin-bottom: 0;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        font-weight: 400;
        font-size: 1.1rem;
        margin-top: 0;
        font-family: "Poppins", sans-serif;
      }
    }
    .inner-container {
      display: flex;

      flex-direction: row;
    }
    .inner-containerr {
      display: flex;
      width: 92%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .inven {
      display: flex;
      flex-direction: row;
      .image-content {
        position: relative;
      }
    }
    .img2 {
      display: flex;
      flex-direction: row;
      margin-top: 2%;
      gap: 2%;

      .image-content {
        position: relative;
      }
    }

    .img1 {
      display: flex;
      flex-direction: row;
      gap: 2%;

      .image-content {
        position: relative;
      }
    }

    .image-content img {
      width: 100%;
      height: auto;
      border-radius: 0.7rem;
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.2),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.25),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
    }

    .image-content .overlay {
      position: absolute;
      top: 36%;
      left: 37%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: white;
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 5px;
      #imgcontentfirst {
        margin-top: 6rem;
        font-family: "Bebas Neue", sans-serif;
        h2 {
          width: 150%;
        }
        p {
          width: 135%;
          font-size: 0.8rem;
        }
      }
      #imgcontentsec {
        margin-top: 4rem;
        font-family: "Bebas Neue", sans-serif;
        h2 {
          width: 150%;
        }
        p {
          width: 135%;
        }
      }
      #imgcontentthird {
        margin-top: -0rem;
        font-family: "Bebas Neue", sans-serif;
        h2 {
          width: 150%;
        }
        p {
          width: 135%;
        }
      }
      #dif {
        margin-top: -4rem;
        font-size: 1.6rem;
        font-family: "Bebas Neue", sans-serif;
      }
      .headder {
        margin-bottom: 20%;
        h2 {
          text-transform: uppercase;
          text-align: start;
          font-weight: 400;
          font-size: 1.6rem;
        }
        p {
          margin-top: 2rem;
          text-align: start;
          font-size: 0.9rem;
          font-family: "Poppins", sans-serif;
        }
      }
    }
    .learnmore {
      position: absolute;
      top: 83%;
      left: 10%;
      display: flex;
      align-items: flex-end;
      text-align: start;
      flex-direction: row;
      align-items: center;
      gap: 10%;
      p {
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        font-weight: 500;
      }
    }
    .learnmoree {
      position: absolute;
      top: 69%;
      left: 10%;
      display: flex;
      align-items: flex-end;
      text-align: start;
      flex-direction: row;
      align-items: center;
      gap: 10%;
      p {
        font-size: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        font-weight: 500;
      }
    }
    .image-content .overlay h2,
    .image-content .overlay p {
      margin: 0;
    }

    .image-content .overlay a {
      display: block;
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 137vw;
    margin-left: -3%;
    overflow: hidden;
    animation: ${moveUp} 0.7s ease forwards;
    padding: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    .container {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .image-container {
      position: relative;
      margin-bottom: 40px;
      min-height: 30vh;
      animation: ${zoomIn} 3s;
    }

    .image-container img {
      width: 100%;

      height: auto;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-90%, -50%);
      background-color: rgba(255, 255, 255, 0.7);
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 5px;

      .headingh {
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 400;
        text-align: left;
        margin-bottom: 1rem;
        font-family: "Bebas Neue", sans-serif;
      }
      .hedingpara {
        font-size: 0.5rem;
        width: 95%;
        font-weight: 400;
        text-align: left;
        font-family: "Poppins", sans-serif;
      }
    }

    .overlay h2,
    .overlay p {
      margin: 0;
    }

    .content-container {
      height: auto;
      min-height: 32vh;
      text-align: center;
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      h2 {
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 400;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        font-weight: 400;
        font-size: 1.1rem;
        font-family: "Poppins", sans-serif;
      }
      .iimgclick {
        margin-top: 3%;
        display: flex;
        flex-direction: row;
        img {
          width: 10rem;
          border-radius: 0.9rem;
          box-shadow: 10px 0 15px rgba(106, 89, 255, 0.1),
            /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.15),
            /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
        }
        .overlay-box-first {
          position: absolute;
          z-index: 5;
          text-align: left;
          padding: 2rem;
          color: white;
          right: 56%;
          width: 25%;
          h2 {
            font-family: "Bebas Neue", sans-serif;
            font-size: 1rem;
          }
          p {
            font-family: "Poppins", sans-serif;
            font-size: 0.7rem;
          }
        }
        .overlay-box-second {
          position: absolute;
          text-align: left;
          color: white;
          padding: 2rem;
          z-index: 5;
          right: 34%;
          width: 20%;
          h2 {
            font-family: "Bebas Neue", sans-serif;
            font-size: 1rem;
          }
          p {
            font-family: "Poppins", sans-serif;
            font-size: 0.7rem;
          }
        }
        #imgfirst {
          position: absolute;
          right: 60%;
          z-index: 4;
        }
        #imgsec {
          position: absolute;
          right: 43%;
          z-index: 3;
        }
        #imgthir {
          position: absolute;
          right: 28%;
          z-index: 2;
        }
        #imgfour {
          position: absolute;
          right: 16%;
        }
      }
    }
    .caseheading {
      margin-top: 4%;
      width: 92%;
      margin-bottom: 3%;
      h2 {
        font-family: "Bebas Neue", sans-serif;
        text-transform: uppercase;
        font-size: 2.5rem;
        word-spacing: -2px;
        font-weight: 400;
        margin-bottom: 0;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        font-weight: 400;
        font-size: 0.9rem;
        margin-top: 0;
        font-family: "Poppins", sans-serif;
      }
    }
    .inner-container {
      display: flex;

      flex-direction: row;
    }
    .inner-containerr {
      display: flex;
      width: 92%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
    .inven {
      display: flex;
      flex-direction: row;
      .image-content {
        position: relative;
      }
    }
    .img2 {
      display: flex;
      flex-direction: row;
      margin-top: 2%;
      gap: 2%;

      .image-content {
        position: relative;
      }
    }

    .img1 {
      display: flex;
      flex-direction: row;
      gap: 2%;

      .image-content {
        position: relative;
      }
    }

    .image-content img {
      width: 100%;
      height: auto;
      border-radius: 0.7rem;
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.1),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.15),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
    }

    .image-content .overlay {
      position: absolute;
      top: 36%;
      left: 37%;
      transform: translate(-50%, -50%);
      background-color: rgba(255, 255, 255, 0);
      color: white;
      padding: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 5px;
      #imgcontentfirst {
        margin-top: 3rem;
        font-family: "Bebas Neue", sans-serif;
        h2 {
          width: 150%;
        }
        p {
          width: 135%;
          font-size: 0.4rem;
        }
      }
      #imgcontentsec {
        margin-top: 4rem;
        font-family: "Bebas Neue", sans-serif;
        h2 {
          width: 150%;
        }
        p {
          width: 135%;
        }
      }
      #imgcontentthird {
        margin-top: -0rem;
        font-family: "Bebas Neue", sans-serif;
        h2 {
          width: 150%;
        }
        p {
          width: 135%;
        }
      }
      #dif {
        margin-top: -1.5rem;
        font-size: 1rem;
        text-align: left;
        font-family: "Bebas Neue", sans-serif;
      }
      .headder {
        margin-bottom: 20%;
        h2 {
          text-transform: uppercase;
          text-align: start;
          font-weight: 400;
          font-size: 1rem;
        }
        p {
          margin-top: 2rem;
          text-align: start;
          font-size: 0.4rem;
          font-family: "Poppins", sans-serif;
        }
      }
    }
    .learnmore {
      position: absolute;
      top: 83%;
      left: 10%;
      display: flex;
      align-items: flex-end;
      text-align: start;
      flex-direction: row;
      align-items: center;
      gap: 1%;
      svg {
        width: 80%;
      }
      p {
        font-size: 0.7rem;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 0;
        text-overflow: ellipsis;
        color: white;
        font-weight: 400;
      }
    }
    .learnmoree {
      position: absolute;
      top: 69%;
      left: 60%;
      display: flex;
      align-items: flex-end;
      text-align: start;
      flex-direction: row;
      align-items: center;
      gap: 1%;
      svg {
        width: 80%;
      }
      p {
        font-size: 0.7rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: white;
        font-weight: 400;
      }
    }
    .image-content .overlay h2,
    .image-content .overlay p {
      margin: 0;
    }

    .image-content .overlay a {
      display: block;
      margin-top: 10px;
    }
  }
`;

export default TheValue;
