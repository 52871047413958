import React, { useState } from "react";
import styled from "styled-components";
import Logo from "../Logo";

const ExperienceHome = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <Style>
      <div className="experience-home">
        {/* <img src="/spaceslogo.png" alt="logo" /> */}
        <Logo />
        <button onClick={toggleNav}>
          {isNavOpen ? (
            <svg
              width="50"
              height="43"
              viewBox="0 0 84 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.23242 3.77014L75.7684 47.2299"
                stroke="black"
                strokeWidth="7"
                strokeLinecap="round"
              />
              <path
                d="M6 46L80 6"
                stroke="black"
                strokeWidth="7"
                strokeLinecap="round"
              />
            </svg>
          ) : (
            <svg
              width="60"
              height="43"
              viewBox="0 0 90 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 3.5H86"
                stroke="black"
                strokeWidth="7"
                strokeLinecap="round"
              />
              <path
                d="M4 26.5H86"
                stroke="black"
                strokeWidth="7"
                strokeLinecap="round"
              />
              <path
                d="M4 49.5H86"
                stroke="black"
                strokeWidth="7"
                strokeLinecap="round"
              />
            </svg>
          )}
        </button>
      </div>
      <div className={`navbar ${isNavOpen ? "open" : ""}`}>
        {/* Navbar content */}
        <ul>
          <div className="first">
            <li>home</li>
            <li>About</li>
            <li>interactive content</li>
            <li>immersive and physical experience</li>
          </div>
          <div className="sec">
            <li>The Value-Added Suite</li>
            <li>The Journey</li>
            <li>Contact us</li>
            <li>Resources</li>
          </div>
        </ul>
      </div>
      <div className="hand-images">
        <img src="/hand-left.png" alt="hand-left" className="hand-left" />
        <img src="/hand-right.png" alt="hand-right" className="hand-right" />
      </div>
    </Style>
  );
};

const Style = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;

  .experience-home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  .navbar {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 10px;
    z-index: 1; /* Ensure the navbar appears above other content */
    ul {
      display: flex;
      flex-direction: row;
      width: 60%;
      gap: 60%;
      .first {
        width: 50%;
        li {
          width: 23vw;
          font-size: 1.7rem;
          margin-top: 1rem;
          list-style-type: none;
          text-transform: uppercase;
        }
      }
      .sec {
        width: 30vw;
        li {
          margin-top: 1rem;
          width: 20vw;
          text-transform: uppercase;
          font-size: 1.7rem;
          list-style-type: none;
        }
      }
    }
  }

  .navbar.open {
    display: block;
    top: 50%; /* Position the navbar vertically centered */
    transform: translate(-50%, -50%);
  }

  .hand-images {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
  }

  .hand-left,
  .hand-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    height: 45vh;
  }

  .hand-left {
    left: 2rem;
  }

  .hand-right {
    right: 2rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: 80vh;

    .experience-home {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    .navbar {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: white;
      padding: 10px;
      z-index: 1; /* Ensure the navbar appears above other content */
      ul {
        display: flex;
        flex-direction: row;
        width: 60%;
        gap: 60%;
        .first {
          width: 50%;
          li {
            width: 23vw;
            font-size: 1.7rem;
            margin-top: 1rem;
            list-style-type: none;
            text-transform: uppercase;
          }
        }
        .sec {
          width: 30vw;
          li {
            margin-top: 1rem;
            width: 20vw;
            text-transform: uppercase;
            font-size: 1.7rem;
            list-style-type: none;
          }
        }
      }
    }

    .navbar.open {
      display: block;
      top: 50%; /* Position the navbar vertically centered */
      transform: translate(-50%, -50%);
    }

    .hand-images {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
    }

    .hand-left,
    .hand-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10%;
      height: 45vh;
    }

    .hand-left {
      left: 2rem;
    }

    .hand-right {
      right: 2rem;
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 40vh;

    .experience-home {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1rem;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }

    .navbar {
      display: none;
      position: absolute;
      left: 50%;
      top: 23%;
      transform: translateX(20%);
      background-color: white;
      padding: 1px;
      z-index: 1; /* Ensure the navbar appears above other content */
      ul {
        display: flex;
        flex-direction: row;
        width: 50%;
        gap: 40%;
        .first {
          width: 50%;
          li {
            width: 23vw;
            font-size: 0.7rem;
            margin-top: 1rem;
            list-style-type: none;
            text-transform: uppercase;
          }
        }
        .sec {
          width: 30vw;
          li {
            margin-top: 1rem;
            width: 20vw;
            text-transform: uppercase;
            font-size: 0.7rem;
            list-style-type: none;
          }
        }
      }
    }

    .navbar.open {
      display: block;
      top: 27%; /* Position the navbar vertically centered */
      transform: translate(-50%, -50%);
    }

    .hand-images {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
    }

    .hand-left,
    .hand-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10%;
      height: 15vh;
    }

    .hand-left {
      left: 2rem;
    }

    .hand-right {
      right: 2rem;
    }
  }
`;

export default ExperienceHome;
