import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const handleSlideChange = (index) => {
    setCurrentSlide(index);
    for (let i = 0; i < 3; i++) { // Assuming you have 3 slides, adjust the loop based on your actual number of slides
      if (i !== index) {
        console.log("Slide index:", i); 
      }
    }
  };

  return (
    <Style>
      <div className="main">
        <div className="heading">
          <h2>Our Customer Feedback</h2>
          <p>Don’t take our word for it. Trust our customers</p>
        </div>
        
        <Carousel
          selectedItem={currentSlide}
          onChange={handleSlideChange}
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={6100}
          centerMode={true} // Center mode enabled
          centerSlidePercentage={70} // Adjust as needed
        >
          <div >
            <img src="/Ellipse.png" alt="Shirley Fultz" />
            <div className={
                currentSlide === 0
                  ? "current-slide"
                  : currentSlide === 1
                  ? "left-slide"
                  : "right-slide"
              }>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
              </p>
              <h3>Mike Torello</h3>
              <h4>Executive Engineer</h4>
            </div>
          </div>

          <div >
            <img src="/Ellipse.png" alt="Daniel Keystone" />
            <div className={
                currentSlide === 1
                  ? "current-slide"
                  : currentSlide === 2
                  ? "left-slide"
                  : "right-slide"
              }>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
              </p>
              <h3>Mike Torello</h3>
              <h4>Executive Engineer</h4>
            </div>
          </div>

          <div >
            <img src="/Ellipse.png" alt="Theo Sorel" />
            <div className={
                currentSlide === 2
                  ? "current-slide"
                  : currentSlide === 0
                  ? "left-slide"
                  : "right-slide"
              }>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
              </p>
              <h3>Mike Torello</h3>
              <h4>Executive Engineer</h4>
            </div>
          </div>
        </Carousel>
      </div>
    </Style>
  );
};

const Style = styled.div`
  width: 98.5;
  max-height: 70vh;
  height: auto;
  margin-top: 5%;
  padding: 1.8rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    width: 95%;

    .heading {
      text-align: center;
      h2 {
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 400;
        margin-bottom: 0;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        margin-top: 0;
        font-size: 1.2rem;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }
    }
    .carousel-root {
      width: 69% !important;
      margin: auto !important;
      margin-top: 3% !important;
      padding-bottom: 1rem;
      position: relative;
    }
    .carousel .slide img {
      width: 85px !important;
      height: 85px;
      border: 1px solid rgba(255, 255, 255, 1);
margin-bottom: 0;6rem;
      border-radius: 50%;
    
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);


    }

    .current-slide {
  
      border-radius: 1.1rem;
height: 289px;
      background: white;
      margin-top: -9%;
      width: 99%;
      margin-bottom: 3.6rem;
      margin-left: auto;
      margin-right: auto;
      padding-top: 6%;
      padding-bottom: 6%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid white;
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.20),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.20),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
        p {
          margin-top: 2.2rem;
          font-weight: 400 !important;
          line-height: 29px !important;
          color: rgba(23, 23, 23, 1);
          font-size: 1.05rem;
          padding-left: 2%;
        padding-right: 2%;
          font-family: sans-serif;
          max-height: 67px;
          margin-bottom: 0.5rem;
          font-family: "Poppins", sans-serif;
        }
         h3 {
          margin-top: -1.2rem;
          color: #222;
          font-weight: 100;
          letter-spacing: 0.2px;
          margin-bottom: 4px;
          font-weight: 400;
          margin-top: 5rem;
          text-transform: uppercase;
          font-size: 17px;
          font-family: "Bebas Neue", sans-serif;
        }
    
         h4 {
          text-transform: uppercase;
          margin-top: 0;
          padding-top: 0;
          font-weight: 400;
          color: rgba(23, 23, 23, 1);
          font-family: "Poppins", sans-serif;
          font-size: 14px;
        }
    }
    .left-slide {
      position: absolute;
      border-radius: 1.1rem;
height: 230px;
position: absolute:
      background: white;
      margin-top: -4%;
      margin-left: 79%;
      width: 95%;
      opacity: 0.4;

      padding-top: 6%;
      padding-bottom: 8%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid white;
      p {
        font-weight: 400 !important;
        line-height: 29px !important;
        color: rgba(23, 23, 23, 1);
        font-size: 1.05rem;
        padding-left: 2%;
        padding-right: 2%;
        font-family: sans-serif;
        max-height: 67px;
        margin-bottom: 0.5rem;
        font-family: "Poppins", sans-serif;
      }
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.30),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.30),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
    }
    .right-slide{
      position: absolute;
      right: -3%;
      opacity: 0.4;
      border-radius: 1.5rem;
      height: 230px;
      z-index: -1;
      position: absolute:
            background: white;
            margin-top: -4%;
            margin-right: 82%;
            width: 95%;
            padding-top: 6%;
            padding-bottom: 8%;
            padding-left: 5%;
            padding-right: 5%;
            border: 1px solid white;
            box-shadow: 8px 0 15px rgba(106, 89, 255, 0.20),
               0 0px 15px rgba(106, 89, 255, 0.20),
             0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
              p {
                font-weight: 400 !important;
                line-height: 29px !important;
                color: rgba(23, 23, 23, 1);
                font-size: 1.05rem;
                font-family: sans-serif;
                max-height: 67px;
                margin-bottom: 0.5rem;
                font-family: "Poppins", sans-serif;
              }
    }
    .carousel .control-dots {
      
      padding-left: 5px !important;
      outline: 0;
      bottom: 1% !important;
     
    }

    
    .carousel .control-dots .dot {
     
      box-shadow: none !important;
      background: #454545 !important;
      outline: 0;
      width: 0.7rem;
      height: 0.7rem;
     
    }

    .carousel.carousel-slider .control-arrow {
      display: none;
    }

    .App {
      text-align: center;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {  
    height: auto;
  margin-top: 5%;
  padding: 1.8rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    width: 95%;

    .heading {
      text-align: center;
      h2 {
        text-transform: uppercase;
        font-size: 2.5rem;
        font-weight: 400;
        margin-bottom: 0;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        margin-top: 0;
        font-size: 1.2rem;
        font-weight: 400;
        font-family: "Poppins", sans-serif;
      }
    }
    .carousel-root {
      width: 89% !important;
      margin: auto !important;
      margin-top: 3% !important;
      padding-bottom: 1rem;
      position: relative;
    }
    .carousel .slide img {
      width: 85px !important;
      height: 85px;
      border: 1px solid rgba(255, 255, 255, 1);
margin-bottom: 0;6rem;
      border-radius: 50%;
    
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);


    }

    .current-slide {
  
      border-radius: 1.1rem;
height: 289px;
      background: white;
      margin-top: -9%;
      width: 99%;
      margin-bottom: 3.6rem;
      margin-left: auto;
      margin-right: auto;
      padding-top: 6%;
      padding-bottom: 6%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid white;
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.20),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.20),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
        p {
          margin-top: 2.2rem;
          font-weight: 400 !important;
          line-height: 29px !important;
          color: rgba(23, 23, 23, 1);
          font-size: 1.05rem;
          padding-left: 2%;
        padding-right: 2%;
          font-family: sans-serif;
          max-height: 67px;
          margin-bottom: 0.5rem;
          font-family: "Poppins", sans-serif;
        }
         h3 {
          margin-top: -1.2rem;
          color: #222;
          font-weight: 100;
          letter-spacing: 0.2px;
          margin-bottom: 4px;
          font-weight: 400;
          margin-top: 5rem;
          text-transform: uppercase;
          font-size: 17px;
          font-family: "Bebas Neue", sans-serif;
        }
    
         h4 {
          text-transform: uppercase;
          margin-top: 0;
          padding-top: 0;
          font-weight: 400;
          color: rgba(23, 23, 23, 1);
          font-family: "Poppins", sans-serif;
          font-size: 14px;
        }
    }
    .left-slide {
      position: absolute;
      border-radius: 1.1rem;
height: 230px;
position: absolute:
      background: white;
      margin-top: -4%;
      margin-left: 79%;
      width: 95%;
      opacity: 0.4;

      padding-top: 6%;
      padding-bottom: 8%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid white;
      p {
        font-weight: 400 !important;
        line-height: 29px !important;
        color: rgba(23, 23, 23, 1);
        font-size: 1.05rem;
        padding-left: 2%;
        padding-right: 2%;
        font-family: sans-serif;
        max-height: 67px;
        margin-bottom: 0.5rem;
        font-family: "Poppins", sans-serif;
      }
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.30),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.30),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
    }
    .right-slide{
      position: absolute;
      right: -3%;
      opacity: 0.4;
      border-radius: 1.5rem;
      height: 230px;
      z-index: -1;
      position: absolute:
            background: white;
            margin-top: -4%;
            margin-right: 82%;
            width: 95%;
            padding-top: 6%;
            padding-bottom: 8%;
            padding-left: 5%;
            padding-right: 5%;
            border: 1px solid white;
            box-shadow: 8px 0 15px rgba(106, 89, 255, 0.20),
               0 0px 15px rgba(106, 89, 255, 0.20),
             0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
              p {
                font-weight: 400 !important;
                line-height: 29px !important;
                color: rgba(23, 23, 23, 1);
                font-size: 1.05rem;
                font-family: sans-serif;
                max-height: 67px;
                margin-bottom: 0.5rem;
                font-family: "Poppins", sans-serif;
              }
    }
    .carousel .control-dots {
      
      padding-left: 5px !important;
      outline: 0;
      bottom: 1% !important;
     
    }

    
    .carousel .control-dots .dot {
     
      box-shadow: none !important;
      background: #454545 !important;
      outline: 0;
      width: 0.7rem;
      height: 0.7rem;
     
    }

    .carousel.carousel-slider .control-arrow {
      display: none;
    }

    .App {
      text-align: center;
    }
  }
  }
  @media only screen and (max-width: 767px) {   
    width: 125vw;
    max-height: 70vh;
    height: auto;
    margin-top: 5%;
    padding: 1.8rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .main {
      width: 95%;
  
      .heading {
        text-align: center;
        h2 {
          text-transform: uppercase;
          font-size: 2.5rem;
          font-weight: 400;
          margin-bottom: 0;
          font-family: "Bebas Neue", sans-serif;
        }
        p {
          margin-top: 0;
          font-size: 1.2rem;
          font-weight: 400;
          font-family: "Poppins", sans-serif;
        }
      }
      .carousel-root {
        width: 93% !important;
        margin: auto !important;
        margin-top: 3% !important;
        padding-bottom: 1rem;
        position: relative;
      }
      .carousel .slide img {
        width: 85px !important;
        height: 85px;
        border: 1px solid rgba(255, 255, 255, 1);
  margin-bottom: 0;6rem;
        border-radius: 50%;
      
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  
  
      }
  
      .current-slide {
    
        border-radius: 1.1rem;
  height: 289px;
        background: white;
        margin-top: -9%;
        width: 99%;
        margin-bottom: 3.6rem;
        margin-left: auto;
        margin-right: auto;
        padding-top: 6%;
        padding-bottom: 6%;
        padding-left: 5%;
        padding-right: 5%;
        border: 1px solid white;
        box-shadow: 10px 0 15px rgba(106, 89, 255, 0.20),
          /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.20),
          /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
          p {
            margin-top: 2.2rem;
            font-weight: 400 !important;
            line-height: 19px !important;
            color: rgba(23, 23, 23, 1);
            font-size: 0.7rem;
            padding-left: 2%;
          padding-right: 2%;
            font-family: sans-serif;
            
            margin-bottom: 0.5rem;
            font-family: "Poppins", sans-serif;
          }
           h3 {
            margin-top: -1.2rem;
            color: #222;
            font-weight: 100;
            letter-spacing: 0.2px;
            margin-bottom: 4px;
            font-weight: 400;
            margin-top: 5rem;
            text-transform: uppercase;
            font-size: 17px;
            font-family: "Bebas Neue", sans-serif;
          }
      
           h4 {
            text-transform: uppercase;
            margin-top: 0;
            padding-top: 0;
            font-weight: 400;
            color: rgba(23, 23, 23, 1);
            font-family: "Poppins", sans-serif;
            font-size: 14px;
          }
      }
      .left-slide {
        position: absolute;
        border-radius: 1.1rem;
  height: 230px;
  position: absolute:
        background: white;
        margin-top: -4%;
        margin-left: 79%;
        width: 95%;
        opacity: 0.4;
  
        padding-top: 6%;
        padding-bottom: 8%;
        padding-left: 5%;
        padding-right: 5%;
        border: 1px solid white;
        p {
          font-weight: 400 !important;
          line-height: 19px !important;
          color: rgba(23, 23, 23, 1);
          font-size: 0.7rem;
          padding-left: 2%;
          padding-right: 2%;
          font-family: sans-serif;
          max-height: 67px;
          margin-bottom: 0.5rem;
          font-family: "Poppins", sans-serif;
        }
        box-shadow: 10px 0 15px rgba(106, 89, 255, 0.30),
          /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.30),
          /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
      }
      .right-slide{
        position: absolute;
        right: -3%;
        opacity: 0.4;
        border-radius: 1.5rem;
        height: 230px;
        z-index: -1;
        position: absolute:
              background: white;
              margin-top: -4%;
              margin-right: 82%;
              width: 95%;
              padding-top: 6%;
              padding-bottom: 8%;
              padding-left: 5%;
              padding-right: 5%;
              border: 1px solid white;
              box-shadow: 8px 0 15px rgba(106, 89, 255, 0.20),
                 0 0px 15px rgba(106, 89, 255, 0.20),
               0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
                p {
                  font-weight: 400 !important;
                  line-height: 19px !important;
                  color: rgba(23, 23, 23, 1);
                  font-size: 0.7rem;
                  font-family: sans-serif;
                  max-height: 67px;
                  margin-bottom: 0.5rem;
                  font-family: "Poppins", sans-serif;
                }
      }
      .carousel .control-dots {
        
        padding-left: 5px !important;
        outline: 0;
        bottom: 1% !important;
       
      }
  
      
      .carousel .control-dots .dot {
       
        box-shadow: none !important;
        background: #454545 !important;
        outline: 0;
        width: 0.7rem;
        height: 0.7rem;
       
      }
  
      .carousel.carousel-slider .control-arrow {
        display: none;
      }
  
      .App {
        text-align: center;
      }
    }
  }


`;

export default Testimonials;
