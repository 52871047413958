import Runwal from "./Runwal";

const circleData = [
  {
    text: "1 BHK",
    url: "https://btvrprojects.s3.ap-south-1.amazonaws.com/Runwal_1BHK-balcony/index.htm",
  },
  {
    text: "3 BHK",
    url: "https://btvrprojects.s3.ap-south-1.amazonaws.com/Runwal_3BHK_Balcony/index.htm",
  },
  {
    text: "2.5 BHK",
    url: "https://btvrprojects.s3.ap-south-1.amazonaws.com/Runwal_Tour+First+Theme/index.htm",
  },
];

const RunnwalRoute = () => {
  return (
    <div>
      {/* Other content can go here */}
      <Runwal circleData={circleData} />
    </div>
  );
};
export default RunnwalRoute;
