const AldaramPage = () => {
  return (
    <iframe
      src="https://btvrprojects.s3.ap-south-1.amazonaws.com/Aldar_Amenities/index.htm"
      allow="xr-spatial-tracking; vr; fullscreen"
      className="h-screen w-screen overflow-hidden"
    ></iframe>
  );
};

export default AldaramPage;
