import React from "react";
import styled, { keyframes } from "styled-components";
import { data } from "./data";
import LogoToggle from "./LogoToggle";
import NavbarComponent from "./NavbarComponent";
import { useSelectedTitleContext } from "./SelectedTitleContext";
import ExperienceFooter from "./ExperienceFooter";
import GetInTouchButton from "./GetInTouchButton";
import { fadeOutUp } from "./Animations";

const moveUp = keyframes`
from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;
const zoomIn = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`;

const Resource = () => {
  const { isNavOpen, toggleNav } = useSelectedTitleContext();
  return (
    <>
      <LogoToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />
      {isNavOpen && <NavbarComponent />}
      {!isNavOpen && (
        <>
          <Style>
            <div className="maindiv">
              <div className="imgdiv">
                <div className="overlay">
                  <div className="vlog">
                    <div>
                      <h2>resources and blogs</h2>
                    </div>
                    <div>
                      <a href="#">
                        <svg
                          width="59"
                          height="14"
                          viewBox="0 0 89 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                            fill="#1C1C1C"
                          />
                        </svg>
                        <span> Read Now</span>
                      </a>
                    </div>
                  </div>
                  <div>
                    <img src="/resou.png" alt="logo" />
                  </div>
                </div>
              </div>
              <div className="searchdiv">
                <div className="serch">
                  <div className="heed">
                    {" "}
                    <h2>Read Our Latest Blogs</h2>{" "}
                  </div>
                  <div>
                    {" "}
                    <input type="search" placeholder="search" />{" "}
                  </div>
                </div>
                <div className="blog" style={{ marginBottom: "1%" }}>
                  {data.map((item, index) => (
                    <div className="datat" key={index}>
                      <img src={item.imageSrc} alt={item.imageAlt} />

                      <h2>{item.title}</h2>
                      <p>By {item.author}</p>
                      <p>
                        {" "}
                        <a href="#">
                          {" "}
                          <svg
                            width="59"
                            height="14"
                            viewBox="0 0 89 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M88.0607 13.0607C88.6464 12.4749 88.6464 11.5251 88.0607 10.9393L78.5147 1.3934C77.9289 0.807611 76.9792 0.807611 76.3934 1.3934C75.8076 1.97919 75.8076 2.92893 76.3934 3.51472L84.8787 12L76.3934 20.4853C75.8076 21.0711 75.8076 22.0208 76.3934 22.6066C76.9792 23.1924 77.9289 23.1924 78.5147 22.6066L88.0607 13.0607ZM0 13.5H87V10.5H0V13.5Z"
                              fill="#1C1C1C"
                            />
                          </svg>
                          <span> Read Now</span>{" "}
                        </a>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Style>

          <GetInTouchButton />
          <ExperienceFooter />
        </>
      )}
    </>
  );
};
const Style = styled.div`
  scroll-behavior: smooth;
  width: 98.5vw;
  overflow: hidden;
  .maindiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: ${moveUp} 0.7s ease forwards;
    justify-content: center;
    .imgdiv {
      width: 95%;
      animation: ${zoomIn} 3s;
      height: 80vh;
      .overlay {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .vlog {
        position: absolute;
        top: 50%;
        left: 25%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;
        border-radius: 10px;
        z-index: 2;
      }

      .vlog h2 {
        width: 72%;
        margin-bottom: 10px;
        font-size: 4.3rem;
        text-transform: uppercase;
        font-weight: 400;
      
        font-family: "Bebas Neue", sans-serif;
      }

      .vlog a {
        text-decoration: none;
        color: black;
        display: flex;
        margin-top: 14%;
        font-weight: 550;
        align-items: center;
        font-family: "Poppins", sans-serif;
      }

      .vlog svg {
        margin-right: 5px;
      }

      .overlay img {
        position: relative;
        max-width: 100%;

        height: auto;
        display: block;
      }
    }
    .searchdiv {
      display: flex;

      flex-direction: column;
      .serch {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .heed {
          margin-left: 7%;

          h2 {
            font-family: "Poppins", sans-serif;
            font-weight: 490;
          }
        }
        input {
          width: 26vw;
          height: 6vh;
          border-radius: 0.5rem;
        }
      }
    }
    .blog {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      div {
        width: calc(28.33% - 1rem); /* 3 items per row */

        transition: box-shadow 0.3s ease;
        justify-content: center;
        border-radius: 1rem;
        box-shadow: 10px 0 15px rgba(106, 89, 255, 0.25),
          /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.35),
          /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */

        align-items: center;
        img {
          width: 100%;
          height: 42vh;
        }
        h2 {
          padding-left: 1rem;
          margin-top: 0;
          font-weight: 550;
          font-family: "Poppins", sans-serif;
        }
        p {
          padding-left: 1rem;
          font-weight: 450;
          font-family: "Poppins", sans-serif;
        }
      }
      .datat {
        transition: transform 0.3s ease;
      }
      .datat:hover {
        transform: translateY(
          -7px
        ); /* Move the img-container upwards on hover */
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5vw;
    overflow: hidden;
    .maindiv {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .imgdiv {
        width: 95%;
        height: 50vh;
        .overlay {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .vlog {
          position: absolute;
          top: 47%;
          left: 31%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 20px;
          border-radius: 10px;
          z-index: 2;
        }

        .vlog h2 {
          width: 52%;
          margin-bottom: 10px;
          font-size: 3.5rem;
          text-transform: uppercase;
        }

        .vlog a {
          text-decoration: none;
          color: black;
          display: flex;
          margin-top: 5%;
          align-items: center;
        }

        .vlog svg {
          margin-right: 5px;
        }

        .overlay img {
          position: relative;
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
      .searchdiv {
        display: flex;

        flex-direction: column;
        .serch {
          width: 95%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .heed {
            margin-left: 7%;
          }
          input {
            width: 26vw;
            height: 6vh;
            border-radius: 0.5rem;
          }
        }
      }
      .blog {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        div {
          width: calc(40.33% - 1rem); /* 3 items per row */
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
          transition: box-shadow 0.3s ease;
          justify-content: center;
          border-radius: 1rem;
          align-items: center;
          img {
            width: 100%;
            height: 42vh;
          }
          h2 {
            padding-left: 1rem;
          }
          p {
            padding-left: 1rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 140vw;
    margin-top: 4%;
    margin-left: 0%;
    overflow: hidden;
    .maindiv {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .imgdiv {
        width: 100%;
        height: 30vh;
        margin-left: 2%;
        .overlay {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .vlog {
          position: absolute;
          top: 49%;
          left: 34%;
          transform: translate(-50%, -50%);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 20px;
          border-radius: 10px;
          z-index: 2;
        }

        .vlog h2 {
          width: 52%;
          margin-bottom: 5px;
        
          font-size: 2.5rem;
          text-transform: uppercase;
        }

        .vlog a {
          text-decoration: none;
          color: black;
          display: flex;
          margin-top: 2%;
          align-items: center;
        }

        .vlog svg {
          margin-right: 5px;
        }

        .overlay img {
          position: relative;
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
      .searchdiv {
        display: flex;
        margin-top: 2%;
        margin-right: 16%;
        flex-direction: column;
        .serch {
          width: 125%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .heed {
            margin-left: 7%;
          }
          input {
            width: 26vw;
            height: 4vh;
            border-radius: 0.5rem;
          }
        }
      }
      .blog {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        div {
          width: calc(125.33% - 0.5rem); /* 3 items per row */
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
          transition: box-shadow 0.3s ease;
          justify-content: center;
          border-radius: 1rem;
          align-items: center;
          img {
            width: 100%;
            height: 42vh;
          }
          h2 {
            padding-left: 1rem;
          }
          p {
            padding-left: 1rem;
          }
        }
      }
    }
  }
`;

export default Resource;
