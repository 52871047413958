import React from "react";
import ExperienceFooter from "../ExperienceFooter";
// import Navbar from "../homePage/Navbar";
// import Footer from "../homePage/Footer";

const TermsOfUse = () => {
  return (
    <div
      className={`w-full min-h-screen p-1 md:p-5 overflow-x-hidden flex flex-col justify-between`}
    >
      {/* <Navbar /> */}
      <div className="w-4/5 m-auto pt-10 pb-10 flex flex-col gap-6">
        <div>
          <h1 className="text-[32px] font-bold text-[#6A59FF]">Terms Of Use</h1>
        </div>
        <div>
          Welcome to Convrse-Spaces. By accessing or using our website,
          platform, or services , you agree to comply with and be bound by these
          Terms of Use. Please read them carefully.
        </div>
        <div>
          <h3 className="text-[24px] font-semibold"> Acceptance of Terms</h3>
          <div>
            {` By accessing or using our Services, you agree to these Terms and our Privacy Policy. If you do not agree to these Terms, do not use our Services. ​`}
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold">Description of Services</h3>
          <div>
            {`Convrse-Spaces provides virtual reality tours, 3D renders, video walkthroughs, and technology interfaces to facilitate the sales and marketing efforts of real estate builders. Our Services are aimed at offering an enhanced digital experience to potential buyers, agents, and real estate professionals.
`}
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold">Eligibility</h3>
          <div>
            {` You must be at least 18 years old to use our Services. By using our Services, you represent and warrant that you meet this eligibility requirement.
`}
            ​
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold">User Responsibilities </h3>
          <div>
            <ul>
              <li>
                You agree to use our Services only for lawful purposes and in
                accordance with these Terms.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of any
                login credentials and account information.
              </li>
              <li>
                You may not use our Services in a manner that could damage,
                disable, or impair our website or interfere with any other
                party's use of the Services.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold"> Intellectual Property</h3>
          <div>
            All content, including but not limited to VR tours, 3D renders,
            images, videos, and software, is the intellectual property of
            Convrse-Spaces or its licensors. You may not reproduce, distribute,
            or create derivative works from our content without prior written
            permission.
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold">
            {" "}
            Payment and Subscription
          </h3>
          <div>
            Certain features of our Services may require payment or
            subscription. By subscribing or purchasing, you agree to provide
            accurate payment information and comply with the terms applicable to
            those purchases. All payments are non-refundable unless otherwise
            stated.
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold"> Termination</h3>
          <div>
            We reserve the right to terminate or suspend your access to the
            Services at our sole discretion, without notice, for conduct that we
            believe violates these Terms or is harmful to other users or third
            parties.
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold">Limitation of Liability</h3>
          <div>
            To the maximum extent permitted by law, Convrse-Spaces shall not be
            liable for any indirect, incidental, special, or consequential
            damages, including loss of profits, data, or use, arising out of or
            in connection with your use of our Services.
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold"> Governing Law</h3>
          <div>
            These Terms shall be governed by and construed in accordance with
            the laws of India. Any disputes arising under these Terms will be
            subject to the exclusive jurisdiction of the courts in India.
          </div>
        </div>
        <div>
          <h3 className="text-[24px] font-semibold"> Contact Information</h3>
          <div>
            If you have any questions about these Terms, please contact us at +
            91 8473994822
          </div>
        </div>
      </div>
      <ExperienceFooter />

      {/* <Footer /> */}
    </div>
  );
};

export default TermsOfUse;
