const AldarPage = () => {
  return (
    <iframe
      src="https://world.aldar.com/uae/rak/almarjan"
      className="h-screen w-screen overflow-hidden"
    ></iframe>
  );
};

export default AldarPage;
