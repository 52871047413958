import React from "react";
import styled, { keyframes } from "styled-components";

const moveRightToLeft = keyframes`
0% {
  transform: translateX(100%);
}
100% {
  transform: translateX(-500%);
}
`;
const moveLeftToRight = keyframes`
0% {
    transform: translateX(-500%);
  }
  100% {
    transform: translateX(100%);
  }
`;

// Styled component for the image with animation applied
const AnimatedImage = styled.div`
  width: 12rem;
  text-align: center;
  align-item: center;
  animation: ${moveRightToLeft} 20s linear infinite;
`;
const AnimatedImg = styled.div`

  width: 12rem;
  text-align: center;
  align-item: center;

  animation: ${moveLeftToRight} 20s linear infinite;
`;
const Industry = () => {
  
  const images = [];
  for (let i = 1; i <= 20; i++) {
    images.push(`/icon/icon${i}.webp`);
  }
  const infiniteImages = [...images, ...images];

  return (
    <Style>
      <div className="main" id="section2">
        <div className="heading">
          <h2>Trusted by industry leaders</h2>
          <p>Unmatched Quality, Service and Trust</p>
        </div>

        <div className="firstrow">
          {images.map((image, index) => (
            <AnimatedImage>
              <img
                key={index}
                src={images[index % images.length]}
                alt={`Image ${index + 1}`}
                className="moveLeft"
              />
            </AnimatedImage>
          ))}
        </div>

        <div className="secondrow">
          {images.map((image, index) => (
            <AnimatedImg>
              <img
                key={index + 13}
                src={images[index % images.length]}
                alt={`Image ${index + 14}`}
                className="moveRight"
              />
            </AnimatedImg>
          ))}
        </div>
      </div>
    </Style>
  );
};

const Style = styled.div`
  width: 98.5vw;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
margin-top: 2rem;
  .main {
    margin-top: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .heading {
      h2 {
        text-transform: uppercase;
        font-size: 2.8rem;
        font-weight: 580;
        letter-spacing: 2%;
        margin-bottom: 0;
        font-family: "Bebas Neue", sans-serif;
      }
      p {
        font-weight: 400;
        text-align: center;
        margin-top: 0;
        letter-spacing: 2%;
        font-family: "Poppins", sans-serif;
        font-size: 1.3rem;
      }
    }
  }

  .firstrow {
    margin-top: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation: moveLeft 5s linear infinite;
    img {
      margin-left: 2rem;
      max-width: 11.5rem;
      max-height: 10vh;
    }
  }

  .secondrow {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    animation: moveRight 7s linear infinite;
    img {
      margin-left: 2rem;
      max-width: 11.5rem;
      max-height: 10vh;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5vw;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .main {
      margin-top: 3rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .heading {
        h2 {
          text-transform: uppercase;
          font-size: 2.8rem;
          font-weight: 580;
          letter-spacing: 2%;
          margin-bottom: 0;
          font-family: "Bebas Neue", sans-serif;
        }
        p {
          font-weight: 400;
          text-align: center;
          margin-top: 0;
          letter-spacing: 2%;
          font-family: "Poppins", sans-serif;
          font-size: 1.3rem;
        }
      }
    }
  
    .firstrow {
      margin-top: 5%;
      display: flex;
      justify-content: flex-start;
      animation: moveLeft 5s linear infinite;
      img {
        margin-left: 2rem;
        max-width: 11.5rem;
        height: 6.6vh;
      }
    }
  
    .secondrow {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-start;
      animation: moveRight 7s linear infinite;
      img {
        margin-left: 2rem;
        max-width: 11.5rem;
        height: 6.6vh;
      }
    }
   }
  @media only screen and (max-width: 767px) {
    width: 140vw;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .main {
      margin-top: 2rem;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .heading {
        h2 {
          text-transform: uppercase;
          font-size: 2.5rem;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }

    .firstrow {
      margin-top: 5%;
      display: flex;
      justify-content: flex-start;
      animation: moveLeft 5s linear infinite;
      img {
        margin-left: 3rem;
        max-width: 7rem;
        height: 6vh;
      }
    }

    .secondrow {
      margin-top: 1rem;
      display: flex;
      justify-content: flex-start;
      animation: moveRight 7s linear infinite;
      img {
        margin-left: 3rem;
        max-width: 7rem;
        height: 6vh;
      }
    }
  }
`;

export default Industry;
