import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelectedTitleContext } from "./SelectedTitleContext";

const HomeVideo = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { hostName } = useSelectedTitleContext();
  console.log("hostName: ", hostName);

  useEffect(() => {
    const video = document.getElementById("videoPlayer");

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    video.addEventListener("play", handlePlay);
    video.addEventListener("pause", handlePause);

    return () => {
      video.removeEventListener("play", handlePlay);
      video.removeEventListener("pause", handlePause);
    };
  }, []);

  const handlePlay = () => {
    const video = document.getElementById("videoPlayer");
    video.play();
    setIsPlaying(true);
  };

  return (
    <Stylee>
      <div className="main">
        <div className="video">
          <video
            id="videoPlayer"
            controls
            poster="/poster/sc/imgface.webp"
            className="img"
          >
            <source
              src="https://d6z54qbwyw8j.cloudfront.net/convrse-spaces/video-walkthroughs.mp4"
              type="video/mp4"
            />
          </video>
          <div className="overlayy">
            {!isPlaying && (
              <>
                <h2>
                  Transforming property showings and changing customer
                  experiences
                </h2>
                <svg
                  className="play-btn"
                  onClick={handlePlay}
                  width="67"
                  height="67"
                  viewBox="0 0 87 87"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.4977 75.4596C61.309 75.4596 75.7477 61.0209 75.7477 43.2096C75.7477 25.3984 61.309 10.9596 43.4977 10.9596C25.6865 10.9596 11.2477 25.3984 11.2477 43.2096C11.2477 61.0209 25.6865 75.4596 43.4977 75.4596ZM43.4977 82.6263C65.2668 82.6263 82.9144 64.9787 82.9144 43.2096C82.9144 21.4404 65.2668 3.79292 43.4977 3.79292C21.7285 3.79292 4.08105 21.4404 4.08105 43.2096C4.08105 64.9787 21.7285 82.6263 43.4977 82.6263Z"
                    fill="white"
                  />
                  <path
                    d="M57.8311 43.2096L36.3311 58.7258V27.6933L57.8311 43.2096Z"
                    fill="white"
                  />
                </svg>
              </>
            )}
          </div>
        </div>
        <div className="paragraphy">
          <p>
            At {hostName}, we empower Real Estate Developers to showcase their
            vision to potential buyers, whether for properties under
            construction or completed, enabling them to fully envision their
            future homes
          </p>
        </div>
      </div>
    </Stylee>
  );
};

const Stylee = styled.div`
  width: 98.5;
  scroll-behavior: smooth;
  overflow: hidden;
  max-height: 120vh;
  margin-top: 7%;
  padding: 2rem;
  display: flex;

  align-items: center;
  justify-content: center;
  .main {
    width: 95%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 12px 0 5px rgba(106, 89, 255, 0.04),
      /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.15),
      /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */
    border-radius: 0.5rem;

    .video {
      width: 95%;

      position: relative;
      object-fit: cover;
      .img {
        margin-top: 1rem;
        width: 100%;
        border-radius: 1rem;
      }
    }

    .overlayy {
      width: 70%;
      position: absolute;
      top: 22.5%;
      left: 60%;
      transform: translate(-50%, -50%);
      font-size: 1.6rem;
      padding: 0px;
      color: white;
      text-align: left;
      h2 {
        text-transform: uppercase;
        color: black;
        font-weight: 400;
        text-align: right;
        font-size: 3rem;
        font-family: "Bebas Neue", sans-serif;
      }
      svg {
        position: absolute;
        top: 90%;
        left: 35%;
        text-align: center;
        cursor: pointer;
      }
    }

    .paragraphy {
      width: 93%;
      padding: 0.5rem;
      line-height: 1.7rem;
      font-size: 1.2rem;
      color: rgba(28, 28, 28, 1);
      font-family: "Poppins", sans-serif;
      font-weight: 400;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    width: 98.5;
    scroll-behavior: smooth;
    overflow: hidden;
    max-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .main {
      width: 94%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.09);
      border-radius: 0.5rem;
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.2),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.35),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */

      .video {
        width: 95%;

        position: relative;
        object-fit: cover;
        .img {
          margin-top: 1rem;
          width: 100%;
          border-radius: 1rem;
        }
      }

      .overlayy {
        width: 90%;
        position: absolute;
        top: 23%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1.4rem;
        padding: 0px;
        color: white;
        text-align: left;
        h2 {
          text-transform: uppercase;
          color: black;
          font-size: 2rem;
        }
        svg {
          position: absolute;
          top: 50%;
          left: 31%;
          text-align: center;
        }
      }

      .paragraphy {
        width: 90%;
        font-weight: 350;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    width: 125vw;
    scroll-behavior: smooth;
    overflow: hidden;
    max-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .main {
      width: 95%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.5rem;
      box-shadow: 10px 0 15px rgba(106, 89, 255, 0.1),
        /* Right shadow */ 0 10px 15px rgba(106, 89, 255, 0.15),
        /* Bottom shadow */ 0 -10px 15px rgba(106, 89, 255, 0); /* Top shadow */

      .video {
        width: 95%;

        position: relative;
        object-fit: cover;
        .img {
          margin-top: 1rem;
          width: 100%;
          border-radius: 1rem;
        }
      }

      .overlayy {
        width: 90%;
        position: absolute;
        top: 29%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 1rem;
        padding: 0px;
        color: white;
        text-align: left;
        h2 {
          font-size: 1.2rem;
          text-transform: uppercase;
          color: black;
        }
        svg {
          position: absolute;
          top: 50%;
          left: 31%;
          text-align: center;
        }
      }

      .paragraphy {
        width: 90%;
        font-weight: 350;
      }
    }
  }
`;

export default HomeVideo;
