// ScrollDirection.js
import { useState, useEffect } from "react";

export const ScrollDirection = {
  UP: "UP",
  DOWN: "DOWN",
};

export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollTop =
      window.pageYOffset || document.documentElement.scrollTop;
    const scrollHandler = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setScrollDirection(ScrollDirection.DOWN);
      } else {
        setScrollDirection(ScrollDirection.UP);
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return scrollDirection;
};
