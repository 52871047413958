import React from "react";
import "./runwal.css"; // Create this file if not already present

function Runwal({ circleData }) {
  // Pass in the data as a prop

  const handleCircleClick = (url) => {
    window.location.href = url;
  };

  return (
    <div
      className="circle-background-container"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/runwal-bg.jpg)`,
      }}
    >
      {circleData.map((circle, index) => (
        <div
          key={index}
          className="circle"
          onClick={() => handleCircleClick(circle.url)}
        >
          {circle.text}
        </div>
      ))}
    </div>
  );
}

export default Runwal;
