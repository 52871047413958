import React from "react";
import { Link } from "react-router-dom";
import { useSelectedTitleContext } from "./SelectedTitleContext";
import "./NavbarList.css"; // Import CSS file

const NavbarList = ({ firstItems, secondItems, color }) => {
  const { isNavOpen, toggleNav, activeNavItem, handleNavItemChange } =
    useSelectedTitleContext();

  const handleClick = () => {
    if (isNavOpen) {
      toggleNav();
    }
  };

  return (
    <ul>
      <div className="first">
        {firstItems.map((item, index) => (
          <li key={index} >
            <Link
              style={{ color: color, textDecorationLine: "none" }}
              to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
              onClick={handleClick}
              className="nav-item"
            >
              <span>{item}</span>
            </Link>
          </li>
        ))}
      </div>
      <div className="sec">
        {secondItems.map((item, index) => (
          <li key={index} >
            <Link
              style={{ color: color, textDecorationLine: "none" }}
              to={`/${item.toLowerCase().replace(/\s+/g, "-")}`}
              onClick={handleClick}
              className="nav-item"
            >
              <span>{item}</span>
            </Link>
          </li>
        ))}
      </div>
    </ul>
  );
};

export default NavbarList;
