import React from "react";

import HomePage from "../component/HomePage";
import HomeVideo from "../component/HomeVideo";
import Industry from "../component/Industry";
import Awards from "../component/Awards";
import Legacy from "../component/Legacy";
import Solutions from "../component/Solutions";
import Offerings from "../component/Offerings";
import Value from "../component/Value";
import Testimonials from "../component/Testimonials";
import Interactive from "../component/Interactive";
import Phygital from "../component/Phygital";
import ExperienceFooter from "../component/ExperienceFooter";
import LogoToggle from "../component/LogoToggle";
import NavbarList from "../component/NavbarList";
import { useSelectedTitleContext } from "../component/SelectedTitleContext";
import JourneyHome from "../component/JourneyHome";
import { fadeOutUp } from "../component/Animations";
import GetInTouchButton from "../component/GetInTouchButton";
import styled, { keyframes } from "styled-components";

const moveUp = keyframes`
from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const movenow = keyframes`
from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: translateX(0%);
  }
`;

const Home = () => {
  const { isNavOpen, toggleNav, activeNavItem, handleNavItemChange } =
    useSelectedTitleContext();
  return (
    <div>
      <LogoToggle isNavOpen={isNavOpen} toggleNav={toggleNav} />
      {isNavOpen && (
        <>
          <Style>
            <div className={`navbar ${isNavOpen ? "open" : ""}`}>
              <YourComponentWrapper>
                <NavbarList
                  firstItems={[
                    "home",
                    "About",
                    "our solutions",
                  ]}
                  secondItems={[
                    "The Value-Added Suite",
                    "The Journey",
                    "Contact us",
                    
                  ]}
                  activeItem={activeNavItem}
                  onItemClick={handleNavItemChange}
                  color="white"
                />
              </YourComponentWrapper>
            </div>
            <div className="hand-images">
              <img src="/mask group.png" alt="img" className="img" />
            </div>
          </Style>
        </>
      )}
      {!isNavOpen && (
        <>
            <HomePage />
            <Industry />
            <Awards />
            <Legacy />
            <Solutions />
            <JourneyHome />
            <Interactive />
            <Phygital />
            <Offerings />
            <Value />
            <Testimonials />
            <GetInTouchButton />
          
        </>
      )}
      <ExperienceFooter />
    </div>
  );
};

const Anim = styled.div`
  
`;
const YourComponentWrapper = styled.div`
  animation: ${fadeOutUp} 0.7s ease forwards;
  
`;

const Style = styled.div`
  overflow: hidden;
  
  .navbar {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: trans;
    color: white;
    padding: 10px;
    z-index: 1; /* Ensure the navbar appears above other content */
    ul {
      display: flex;
      flex-direction: row;
      width: 60%;
      gap: 70%;
      .first {
        width: 50%;
        li {
          min-width: 22vw;
          max-width: max-content;
          font-size: 1.7rem;
          margin-top: 1.9rem;
          list-style-type: none;
          text-transform: uppercase;
        }
      }
      .sec {
        width: 30vw;
        li {
          margin-top: 1rem;
          min-width: 17vw;
          max-width: max-content;
          text-transform: uppercase;
          font-size: 1.7rem;
          list-style-type: none;
        }
      }
    }
  }

  .navbar.open {
    display: block;
    top: 72%; /* Position the navbar vertically centered */
    transform: translate(-50%, -50%);
  }

  .hand-images {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    align-items: center;
    justify-content: center;
  }

  .hand-left,
  .hand-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    height: 45vh;
  }

  .hand-left {
    left: 2rem;
  }
  .img {
    width: 95%;
    margin-top: -5%;
  }
  .hand-right {
    right: 2rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar {
      display: none;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      background-color: trans;
      color: white;
      padding: 10px;
      z-index: 1; /* Ensure the navbar appears above other content */
      ul {
        display: flex;
        flex-direction: row;
        width: 60%;
        gap: 60%;
        .first {
          width: 50%;
          li {
            width: 23vw;
            font-size: 1.7rem;
            margin-top: 1rem;
            list-style-type: none;
            text-transform: uppercase;
          }
        }
        .sec {
          width: 30vw;
          li {
            margin-top: 1rem;
            width: 20vw;
            text-transform: uppercase;
            font-size: 1.7rem;
            list-style-type: none;
          }
        }
      }
    }

    .navbar.open {
      display: block;
      top: 50%; /* Position the navbar vertically centered */
      transform: translate(-50%, -50%);
    }

    .hand-images {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
      align-items: center;
      justify-content: center;
    }

    .hand-left,
    .hand-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10%;
      height: 45vh;
    }

    .hand-left {
      left: 2rem;
    }
    .img {
      width: 80%;
      margin-top: -5%;
      height: 70vh;
    }
    .hand-right {
      right: 2rem;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 140vw;
    
    .navbar {
      display: none;

      position: absolute;
      left: 65%;
      transform: translateX(-50%);
      background-color: trans;
      color: white;
      padding: 10px;
      z-index: 1; /* Ensure the navbar appears above other content */
      ul {
        display: flex;
        flex-direction: row;
        width: 60%;
        gap: 60%;
        .first {
          width: 50%;
          li {
            width: 29vw;
            font-size: 1.2rem;
            margin-top: 0.5rem;
            list-style-type: none;
            text-transform: uppercase;
          }
        }
        .sec {
          width: 30vw;
          li {
            margin-top: 0.5rem;
            width: 25vw;
            text-transform: uppercase;
            font-size: 1.2rem;
            list-style-type: none;
          }
        }
      }
    }

    .navbar.open {
      display: block;
      top: 42%; /* Position the navbar vertically centered */
      transform: translate(-60%, -60%);
    }

    .hand-images {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-top: 5rem;
      align-items: center;
      justify-content: center;
    }

    .hand-left,
    .hand-right {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 10%;
      height: 45vh;
    }

    .hand-left {
      left: 2rem;
    }
    .img {
      width: 95%;
      margin-right: 0%;
      margin-top: -5%;
      height: 50%;
    }
    .hand-right {
      right: 2rem;
    }
  }
`;
export default Home;
