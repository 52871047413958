import { useContext, useEffect } from "react";
import "./App.css";
import AppRou from "./Nav/AppRou";
import {
  SelectedTitleProvider,
  useSelectedTitleContext,
} from "./component/SelectedTitleContext";

function App() {
  const { setHostName, hostName } = useSelectedTitleContext();
  console.log("hostname: ", hostName);
  useEffect(() => {
    const url = window.location.hostname;
    let title = "";
    let faviconPath = "";
    let favicon512Path = "";
    let favicon192Path = "";

    switch (url) {
      case "www.bluetickvr.com":
      case "bluetickvr.com":
        setHostName("Bluetick-VR");
        title = "Bluetick-VR";
        faviconPath = "/favicon-bluetick.ico";
        favicon512Path = "/bluetick-512.png";
        favicon192Path = "/bluetick-192.png";
        break;
      case "www.convrse.in":
      case "convrse.in":
        setHostName("Convrse Spaces");
        title = "Convrse Spaces";
        faviconPath = "/favicon.ico";
        favicon512Path = "/logo512.png";
        favicon192Path = "/logo192.png";
        break;
      case "localhost":
        setHostName("Bluetick-VR");
        title = "Convrse Spaces";
        faviconPath = "/favicon-bluetick.ico";
        favicon512Path = "/bluetick-512.png";
        favicon192Path = "/bluetick-192.png";
        break;
      // Add more cases for other URLs if needed

      default:
        setHostName("Convrse Spaces");
        faviconPath = "/favicon.ico";
        favicon512Path = "/logo512.png";
        favicon192Path = "/logo192.png";
        title = "Convrse Spaces"; // Default title if URL doesn't match any cases
    }

    document.title = title;

    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.rel = "icon";
    link.href = faviconPath;
    document.head.appendChild(link);

    const favicon512 =
      document.querySelector("link[rel*='icon'][sizes='512x512']") ||
      document.createElement("link");
    favicon512.rel = "icon";
    favicon512.type = "image/png";
    favicon512.href = favicon512Path;
    document.head.appendChild(favicon512);

    const favicon192 =
      document.querySelector("link[rel*='icon'][sizes='192x192']") ||
      document.createElement("link");
    favicon192.rel = "icon";
    favicon192.type = "image/png";
    favicon192.href = favicon192Path;
    document.head.appendChild(favicon192);
  }, []);
  return (
    // <SelectedTitleProvider>
    <AppRou />
    // </SelectedTitleProvider>
  );
}

export default App;
