const AthlonPage = () => {
  return (
    <iframe
      src="https://world.aldar.com/uae/dubai/athlon"
      className="h-screen w-screen overflow-hidden"
    ></iframe>
  );
};

export default AthlonPage;
