import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const GetInTouchButton = () => {
  return (
    <Style>
      <div className="maindiv">
        <div className="heading">
          <h2> GET IN TOUCH!</h2>
          <p>
            Digitise the way you sell ASAP! This is what your customers need to
            see{" "}
          </p>
        </div>
        <div>
          <Link to="https://forms.gle/KYepi6Ujq3FP3aWZ8" target="_blank">
            <button className="get-in-touch-button">
              <svg
                width="39"
                height="29"
                viewBox="0 0 39 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.8027 14.5017L2.34679 14.5017"
                  stroke="white"
                  stroke-width="3.51993"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22.6326 26.6743L36.4308 15.3323C36.5569 15.2285 36.6579 15.1007 36.7271 14.9576C36.7963 14.8145 36.832 14.6593 36.832 14.5023C36.832 14.3453 36.7963 14.1901 36.7271 14.047C36.6579 13.9039 36.5569 13.7762 36.4308 13.6723L22.6326 2.32812"
                  stroke="white"
                  stroke-width="3.51993"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </Link>
        </div>
      </div>
    </Style>
  );
};

const Style = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  margin-top: 0%;
  min-height: 18.5rem;
  .maindiv {
    width: 70vw;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 14rem;
    .heading {
      align-items: center;
      width: 50%;
      h2 {
        font-size: 2.7rem;
        text-align: center;
        font-family: "Bebas Neue", sans-serif;
        font-weight: 400;
        margin-bottom: 0;
      }
      p {
        width: 100%;
        font-size: 1.1rem;
        margin-top: 0;
        text-align: center;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  button.get-in-touch-button {
    background-color: rgba(151, 71, 255, 1);
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
  }

  button.get-in-touch-button svg {
    color: white;
    width: 90%;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 26rem;
    .maindiv {
      width: 70vw;
      display: flex;
      justify-content: center;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height: 30vh;
      .heading {
        h2 {
          font-size: 2.2rem;
        }
        p {
          font-size: 1.4rem;
        }
      }
    }

    button.get-in-touch-button {
      background-color: rgba(151, 71, 255, 1);
      border: none;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
    }

    button.get-in-touch-button svg {
      color: white;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 130vw;
    margin-top: 0%;
    display: flex;
    padding: 0.5rem;
    min-height: 16rem;
    .maindiv {
      width: 80vw;
      border-radius: 0.5rem;
      display: flex;
      height: 10vh;
      justify-content: start;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .heading {
        h2 {
          font-size: 1.7rem;
        }
        p {
          font-size: 0.6rem;
        }
      }
    }

    button.get-in-touch-button {
      background-color: rgba(151, 71, 255, 1);
      border: none;
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      padding: 10px;
      cursor: pointer;
    }

    button.get-in-touch-button svg {
      color: white;
    }
  }
`;

export default GetInTouchButton;
